import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { isValidUrl } from "../utils/commonUtils";
import { useDispatch } from "react-redux";
import { openNotification } from "../slice/notificationSlice";
import ReactGA from "react-ga4";

const CompetitorWebsiteInput = ({
  competitorsWebsite,
  setCompetitorsWebsite,
  setUnsavedChanges,
}) => {
  const [currentWebsite, setCurrentWebsite] = useState("");
  const dispatch = useDispatch();
  const handleAddWebsite = () => {
    ReactGA.event({
      category: "Click",
      action: "Business setup - add competitor",
      label: "User clicks on + to add competitor",
    });
    let currentUrl = currentWebsite;
    if (!currentUrl.startsWith("https://")) {
      currentUrl = "https://" + currentUrl;
    }
    if (isValidUrl(currentUrl, true)) {
      setCompetitorsWebsite([...competitorsWebsite, currentUrl]);
      setCurrentWebsite("");
    } else {
      dispatch(openNotification({ message: "Invalid URL", severity: "error" }));
    }
    setUnsavedChanges(true);
  };

  const handleRemoveWebsite = (item) => {
    ReactGA.event({
      category: "Click",
      action: "Business setup - remove competitor",
      label: "User clicks on - to add competitor",
    });
    const updated = competitorsWebsite.filter((p) => p !== item);
    setCompetitorsWebsite(updated);
    setUnsavedChanges(true);
  };

  return (
    <div className="flex flex-col justify-start items-start relative w-full gap-2">
      <div className="font-medium">Add Competitor</div>
      <div className="text-light text-sm text-slate-400">
        {`Tell us about your competitor, we’ll help you outrank them by targeting
        their best performing keywords.`}
      </div>
      {/* <div className="text-xs text-gray-600">
        (Add full valid website URL with protocols like https)
      </div> */}
      <div className="flex justify-start items-center gap-2 w-full relative">
        <input
          type="text"
          placeholder="hyperbrand.com"
          value={currentWebsite}
          onChange={(event) => setCurrentWebsite(event.target.value)}
          className="p-2 border border-gray-300 text-sm rounded-lg w-full md:w-1/2 lg:w-1/3 relative"
        />
        <button
          onClick={handleAddWebsite}
          className="p-2 bg-thbBlue text-white rounded-lg shadow-lg hover:bg-blue-500"
        >
          <AddIcon />
        </button>
      </div>
      {competitorsWebsite !== null &&
        competitorsWebsite !== undefined &&
        competitorsWebsite.length > 0 && (
          <>
            <div className="text-light text-sm">All competitors</div>
            {competitorsWebsite.map((website, ind) => {
              return (
                <div
                  key={ind}
                  className="flex justify-start items-center gap-2 w-full relative"
                >
                  <div className="p-2 bg-slate-100 border text-sm border-gray-300 rounded-lg w-full md:w-1/2 lg:w-1/3 relative mt-1 shadow-inner">
                    <div>{website.substring(8)}</div>
                  </div>
                  <button
                    onClick={() => handleRemoveWebsite(website)}
                    className="p-2 bg-black text-white text-xs rounded-lg shadow-lg hover:bg-gray-900"
                  >
                    <RemoveRoundedIcon />
                  </button>
                </div>
              );
            })}
          </>
        )}
    </div>
  );
};

export default CompetitorWebsiteInput;
