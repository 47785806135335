import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  msg: "",
  source: null,
  open: false,
};

export const messageDialogSlice = createSlice({
  name: "messageDialog",
  initialState: initialState,
  reducers: {
    openMessageDialog: (state, action) => {
      return { ...state, ...action.payload, open: true };
    },
    closeMessageDialog: (state) => {
      return { ...state, ...initialState };
    },
  },
});

export const { openMessageDialog, closeMessageDialog } =
  messageDialogSlice.actions;

export default messageDialogSlice.reducer;
