import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newTopics: undefined,
  draftTopics: undefined,
  publishedTopics: undefined,
  projectId: undefined,
};

export const topicSelectionSlice = createSlice({
  name: "topicSelection",
  initialState: initialState,
  reducers: {
    setTopicProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setStoreNewTopics: (state, action) => {
      state.newTopics = action.payload;
    },
    setStoreDraftTopics: (state, action) => {
      state.draftTopics = action.payload;
    },
    setStorePublishedTopics: (state, action) => {
      state.publishedTopics = action.payload;
    },
    addStoreNewTopics: (state, action) => {
      state.newTopics = [...state.newTopics, ...action.payload];
    },
    addStoreDraftTopics: (state, action) => {
      state.draftTopics = [...state.draftTopics, ...action.payload];
    },
    addStorePublishedTopics: (state, action) => {
      state.publishedTopics = [...state.publishedTopics, ...action.payload];
    },
  },
});

export const {
  setTopicProjectId,
  setStoreNewTopics,
  setStoreDraftTopics,
  setStorePublishedTopics,
  addStoreNewTopics,
  addStoreDraftTopics,
  addStorePublishedTopics,
} = topicSelectionSlice.actions;

export default topicSelectionSlice.reducer;
