import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { openNotification } from "../../slice/notificationSlice";
import { auth } from "../../auth/firebaseConfig";
import { fetchBackendApiWrapper } from "../../utils/apiWrapper";
import {
  setCurrentProject,
  setProjectsData,
} from "../../slice/projectsDataSlice";
import { countries, industries } from "../../utils/constants";
import { openMessageDialog } from "../../slice/messageDialogSlice";

const Onboarding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const [projectWebsiteUrl, setProjectWebsiteUrl] = useState(
    searchParam.get("website") !== null &&
      searchParam.get("website") !== undefined
      ? searchParam.get("website")
      : ""
  );
  const [isValidProjectUrl, setIsValidProjectUrl] = useState(false);
  const [industry, setIndustry] = useState(
    searchParam.get("industry") !== null &&
      searchParam.get("industry") !== undefined
      ? searchParam.get("industry")
      : ""
  );
  const [country, setCountry] = useState(
    searchParam.get("country") !== null &&
      searchParam.get("country") !== undefined
      ? searchParam.get("country")
      : ""
  );
  const [processing, setProcessing] = useState(false);

  const handleGenerateSampleBlog = async () => {
    if (!isFormFilled()) return;
    setProcessing(true);
    const savedData = await saveProjectDetails();
    if (savedData !== null && savedData.ok) {
      console.log("Project data saved");
      await fetchUserProjects();
      navigate("/dashboard/getting-started", { replace: "true" });
      dispatch(
        openNotification({
          message: "Onboarding form submitted.",
          severity: "success",
        })
      );
      const msg = {
        msg: `Welcome, you have completed the minimum details to get started. We are processing your website details and in 1-2 minutes we will give you the most optimal keywords and topics for your website. While we are processing it, you can fill out this form completely to get best suitable results and can explore our other features.`,
        source: null,
        open: true,
      };
      dispatch(openMessageDialog(msg));
    } else {
      console.error("Error in saving Project data");
      dispatch(
        openNotification({
          message: "Error in submitting form. Please try again.",
          severity: "error",
        })
      );
    }
    setProcessing(false);
  };

  const saveProjectDetails = async () => {
    try {
      const payload = {
        projectWebsite: projectWebsiteUrl.startsWith("https://")
          ? projectWebsiteUrl
          : "https://" + projectWebsiteUrl,
        industry: industry,
        country: country,
        isActive: true,
      };
      const authToken = await auth.currentUser.getIdToken(false);
      const saveData = await fetchBackendApiWrapper(
        "/api/v1/project/onboard",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        authToken
      );
      return saveData;
    } catch (err) {
      console.error("Error in project onboarding " + JSON.stringify(err));
      return null;
    }
  };

  const fetchUserProjects = async () => {
    const authToken = await auth.currentUser.getIdToken(false);
    console.log("Fetching projects with token: " + authToken);
    const res = await fetchBackendApiWrapper(
      "/api/v1/project/userProjects",
      {
        method: "GET",
      },
      authToken
    );
    if (res !== null && res !== undefined && res.ok) {
      const projectsDataJson = await res.json();
      console.log("Projects Data: " + projectsDataJson);
      dispatch(setProjectsData(projectsDataJson));
      if (projectsDataJson.length > 0) {
        localStorage.setItem("thbCurrentProject", projectsDataJson[0].id);
        const curProjectTmp = projectsDataJson[0];
        dispatch(setCurrentProject(curProjectTmp));
        localStorage.setItem("thbCurrentProject", curProjectTmp.id);
      } else {
        dispatch(setCurrentProject(null));
      }
    } else {
      console.error("Error in fetching project details: ");
      dispatch(setCurrentProject(null));
      dispatch(setProjectsData(null));
    }
  };

  // const updateProjectWebisteUrl = (event) => {
  //   setProjectWebsiteUrl(event.target.value);
  //   try {
  //     if (
  //       event.target.value.startsWith("http://") ||
  //       event.target.value.startsWith("https://")
  //     ) {
  //       throw Error();
  //     }
  //     new URL("https://" + event.target.value);
  //     setIsValidProjectUrl(true);
  //   } catch (error) {
  //     setIsValidProjectUrl(false);
  //   }
  //   console.log("Valid: " + isValidProjectUrl);
  // };
  const updateProjectWebisteUrl = (event) => {
    setProjectWebsiteUrl(event.target.value);
    try {
      let urlTmp = event.target.value;
      if (!urlTmp.startsWith("https://")) {
        urlTmp = "https://" + urlTmp;
      }
      new URL(urlTmp);
      setIsValidProjectUrl(true);
    } catch (error) {
      setIsValidProjectUrl(false);
    }
    console.log("Valid: " + isValidProjectUrl);
  };

  const isFormFilled = () => {
    return isValidProjectUrl && industry.length > 0 && country.length > 0;
  };

  return (
    <>
      {
        <main className="bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
          <div className="flex relative flex-row justify-center items-center w-full">
            <div className="flex relative flex-col justify-center items-center w-full gap-8">
              <div className="mt-8">
                <a href="https://thehyperbrand.com/">
                  <img
                    src="/images/THB.svg"
                    alt="TheHyperbrand Logo"
                    width={140}
                    height={32}
                  />
                </a>
              </div>
              <div className="bg-white flex flex-col  justify-evenly items-center relative rounded-xl shadow-md gap-8">
                <div className="font-montserrat text-lg font-medium mt-8">
                  Get Started
                </div>
                <div className="flex flex-col md:flex-row justify-evenly items-start p-12 gap-10 lg:gap-32">
                  <div className="flex flex-col justify-start items-start gap-4">
                    <label className="text-sm font-normal font-montserrat">
                      <div>Your Website URL</div>
                      {/* <div className="text-xs text-gray-600">
                        (Add full valid website URL with protocols like https)
                      </div> */}
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder=""
                        className={`bg-gray-50 border ${
                          isValidProjectUrl
                            ? "border-gray-300"
                            : "border-red-400"
                        } text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                        required
                        value={projectWebsiteUrl}
                        onChange={updateProjectWebisteUrl}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-start items-start gap-4">
                    <label className="text-sm font-normal font-montserrat">
                      Industry
                    </label>
                    <div className="relative">
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
                        value={industry}
                        onChange={(event) => setIndustry(event.target.value)}
                      >
                        <option value=""></option>
                        {industries.map((c) => (
                          <option key={c} value={c}>
                            {c}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col justify-start items-start gap-4">
                    <label className="text-sm font-normal font-montserrat">
                      Target Country
                    </label>
                    <div className="relative">
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        value={country}
                        onChange={(event) => setCountry(event.target.value)}
                      >
                        <option value=""></option>
                        {countries.map((c) => (
                          <option key={c} value={c}>
                            {c}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleGenerateSampleBlog}
                  className={`${
                    isFormFilled()
                      ? "bg-thbBlue cursor-pointer"
                      : "bg-gray-400 cursor-not-allowed"
                  } px-4 py-2 rounded-md text-base text-white font-normal font-montserrat mb-6`}
                >
                  {processing ? (
                    <CircularProgress
                      size="1rem"
                      variant="indeterminate"
                      sx={{ color: "white" }}
                    />
                  ) : (
                    "Get Started"
                  )}
                </button>
                {/* <div
                  onClick={handleGenerateSampleBlog}
                  className={`pb-4 text-sm text-thbBlue font-regular font-montserrat ${
                    isFormFilled()
                      ? "cursor-pointer text-thbBlue"
                      : "cursor-not-allowed text-gray-400"
                  }`}
                >
                  {processing ? (
                    <CircularProgress
                      size="1rem"
                      variant="indeterminate"
                      sx={{ color: "gray" }}
                    />
                  ) : (
                    "Complete your profile"
                  )}
                </div> */}
              </div>
              <div className="text-sm font-montserrat font-normal text-center mb-10 text-gray-700">
                Tip: Tell us about your competitor, we’ll help you outrank them
                by targeting their best performing keywords.
              </div>
            </div>
          </div>
        </main>
      }
    </>
  );
};

export default Onboarding;
