import { Drawer } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const KeywordSelectionGuide = ({ openGuide, setOpenGuide }) => {
  return (
    <Drawer
      anchor="right"
      open={openGuide}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: { xs: 200, sm: 300, lg: 420 },
        },
      }}
      onClose={() => setOpenGuide(false)}
    >
      <div className="flex flex-col justify-start items-start relative">
        <div
          className="flex justify-between items-center w-full relative px-6 py-8 bg-blue-50"
          style={{
            backgroundImage: `url("/images/guideBg.svg")`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="text-blue-500 font-semibold text-xl">Guide</div>
          <div
            onClick={() => setOpenGuide(false)}
            className="cursor-pointer rounded-full p-2 bg-gray-100"
          >
            <ClearIcon />
          </div>
        </div>
        <div className="flex flex-col justify-start items-start p-6 gap-2">
          <div className="text-sm">
            This is the Keywords Selection page, where you can view the most
            optimal keywords selected for your business website. This page
            displays each keyword's Search Volume, Keyword Difficulty, Intent,
            and related keywords.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Keyword</div>
          <div className="text-sm">
            A keyword, or a focus keyword as some call it, is a word that
            describes the content on your page or post best. It’s the search
            term that you want to rank for with a certain page. So when people
            search for that keyword or phrase in Google or other search engines,
            they should find that page on your website.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Intent</div>
          <div className="text-sm">
            The purpose of a search in a search engine. Intents can be read by
            the search engine algorithms to show the proper results and SERP
            features.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Volume</div>
          <div className="text-sm">
            The average number of monthly searches for the analyzed keyword over
            the last 12 months.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Difficulty</div>
          <div className="text-sm">
            Keyword Difficulty shows you how hard it would be for a website to
            rank organically in the Google top 10 for the analyzed keyword. The
            higher the percentage, the harder it will be to achieve high
            rankings.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Status</div>
          <div className="text-sm">
            To view topics related to specific keywords on the Blogs page,
            toggle on the keywords. Note that you can enable a maximum of five
            keywords at a time.
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default KeywordSelectionGuide;
