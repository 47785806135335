import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  msg: "",
  source: null,
  open: false,
};

export const blogNotificationSlice = createSlice({
  name: "blogNotification",
  initialState: initialState,
  reducers: {
    openBlogNotification: (state, action) => {
      return { ...state, ...action.payload, open: true };
    },
    closeBlogNotification: (state) => {
      return { ...state, ...initialState };
    },
  },
});

export const { openBlogNotification, closeBlogNotification } =
  blogNotificationSlice.actions;

export default blogNotificationSlice.reducer;
