import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectsData: undefined,
  currentProject: undefined,
  projectCredits: undefined,
};

export const projectsDataSlice = createSlice({
  name: "projects",
  initialState: initialState,
  reducers: {
    setProjectsData: (state, action) => {
      state.projectsData = action.payload;
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
    setProjectCredits: (state, action) => {
      state.projectCredits = action.payload;
    },
  },
});

export const { setProjectsData, setCurrentProject, setProjectCredits } =
  projectsDataSlice.actions;

export default projectsDataSlice.reducer;
