import { useEffect, useState } from "react";
import SideBar from "../../../components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Dialog,
  IconButton,
  Pagination,
  PaginationItem,
  Stack,
  TableSortLabel,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { fetchBackendApiWrapper } from "../../../utils/apiWrapper";
import ClearIcon from "@mui/icons-material/Clear";
import { openNotification } from "../../../slice/notificationSlice";
import moment from "moment";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import dayjs from "dayjs";
import {
  descendingComparatorCTR,
  descendingComparatorClicks,
  descendingComparatorImpressions,
  descendingComparatorPosition,
} from "../../../utils/sortUtil";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../auth/firebaseConfig";
import ReactGA from "react-ga4";

const Analytics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const projects = useSelector((state) => state.projects);
  // const authData = useSelector((state) => state.authStore);
  const [curView, setCurView] = useState(0);
  const [error, setError] = useState(false);
  const [sites, setSites] = useState([]);
  const [integrationData, setIntegrationData] = useState(null);
  const [indexUrl, setIndexUrl] = useState("");
  const [indexLoader, setIndexLoader] = useState(false);
  const pageSize = 20;
  // Loading Modal ///
  const [openInitLoader, setOpenInitLoader] = useState(true);
  const [openSwitchLoader, setOpenSwitchLoader] = useState(false);
  const [openTableLoader, setOpenTableLoader] = useState(false);
  // Table data
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(() => null);
  const [endDate, setEndDate] = useState(() => null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  // Page Analytics Data
  const [analyticsData, setAnalyticsData] = useState(null);
  const [filterAnalyticsData, setFilterAnalyticsData] = useState(null);
  const [viewAnalyticsData, setViewAnalyticsData] = useState(null);
  const [curPage, setCurPage] = useState(1);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    ReactGA.event({
      category: "Page Load",
      action: "Analytics - Load",
      location: window.location.pathname,
      label: "Analytics Page Load",
    });
    initPage();
  }, []);

  const initPage = async () => {
    Promise.all([fetchSitesApi(), fetchIntegrationsApi()])
      .then((res) => {
        if (
          res[1] &&
          res[1].searchConsoleAuthData &&
          res[1].searchConsoleAuthData.site &&
          res[1].searchConsoleAuthData.site.length > 0
        ) {
          const sd = moment().subtract(30, "days").format("YYYY-MM-DD");
          const ed = moment().format("YYYY-MM-DD");
          fetchAnalyticsApi(
            sd,
            ed,
            res[1].searchConsoleAuthData.site,
            false
          ).then(() => setOpenInitLoader(false));
        } else {
          setOpenInitLoader(false);
        }
      })
      .catch((err) => {
        setError(true);
        setOpenInitLoader(false);
        console.error("Error in init page: " + JSON.stringify(err));
      });
  };

  const fetchSitesApi = async () => {
    try {
      const responseData = await fetchBackendApiWrapper(
        `/api/v1/analytics/search-console/sites?projectId=${projects.currentProject.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (responseData.ok) {
        const jsonData = await responseData.json();
        setSites(jsonData);
        return jsonData;
      }
    } catch (err) {
      console.error("Error in fetching sites data: " + JSON.stringify(err));
    }
    return null;
  };

  const fetchIntegrationsApi = async () => {
    try {
      const responseData = await fetchBackendApiWrapper(
        `/api/v1/integration?projectId=${projects.currentProject.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (responseData.ok) {
        const jsonData = await responseData.json();
        setIntegrationData(jsonData);
        return jsonData;
      }
    } catch (err) {
      console.error(
        "Error in fetching integration data: " + JSON.stringify(err)
      );
    }
    return null;
  };

  const fetchAnalyticsApi = async (startDate, endDate, site, isQuery) => {
    try {
      const payload = {
        site: site,
        projectId: projects.currentProject.id,
        startDate: startDate,
        endDate: endDate,
        isKeywordQuery: isQuery,
      };
      setStartDate(dayjs(payload.startDate));
      setEndDate(dayjs(payload.endDate));
      const responseData = await fetchBackendApiWrapper(
        `/api/v1/analytics/search-console/page`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        await auth.currentUser.getIdToken(false)
      );
      if (responseData.ok) {
        const jsonData = await responseData.json();
        setAnalyticsData(jsonData);
        setFilterAnalyticsData(jsonData.pageAnalytics);
      }
    } catch (err) {
      console.error(
        "Error in fetching page analytics data: " + JSON.stringify(err)
      );
    }
  };

  useEffect(() => {
    if (filterAnalyticsData) {
      setViewAnalyticsData(
        filterAnalyticsData.slice((curPage - 1) * pageSize, curPage * pageSize)
      );
    }
  }, [curPage, filterAnalyticsData]);

  // SITE DIALOG
  const [openSelectSiteDialog, setOpenSelectSiteDialog] = useState(false);
  const SiteSelectionDialog = () => {
    const [currentSite, setCurrentSite] = useState(sites[0]);

    const handleSelectSite = async () => {
      setOpenInitLoader(true);
      selectSiteApi()
        .then(async () => {
          setOpenSelectSiteDialog(false);
          const sd = moment().subtract(30, "days").format("YYYY-MM-DD");
          const ed = moment().format("YYYY-MM-DD");
          await fetchAnalyticsApi(sd, ed, currentSite, false);
          setOpenInitLoader(false);
        })
        .catch((err) => {
          console.error("Error in selecting site: " + JSON.stringify(err));
          setOpenInitLoader(false);
          dispatch(
            openNotification({
              message:
                "Something went wrong in selecting site. Please try again.",
              severity: "error",
            })
          );
        });
    };

    const selectSiteApi = async () => {
      try {
        const responseData = await fetchBackendApiWrapper(
          `/api/v1/analytics/search-console/site?projectId=${projects.currentProject.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ site: currentSite }),
          },
          await auth.currentUser.getIdToken(false)
        );
        if (responseData.ok) {
          const jsonData = await responseData.json();
          setIntegrationData(jsonData);
        }
      } catch (err) {
        console.error("Error in fetching sites data: " + JSON.stringify(err));
      }
    };

    return (
      <Dialog
        open={openSelectSiteDialog}
        onClose={() => setOpenSelectSiteDialog(false)}
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-start items-start relative w-full gap-4 p-8">
          <div className="flex justify-between items-center w-full relative">
            <div className="text-xl font-semibold">Select Site</div>
            <div
              onClick={() => setOpenSelectSiteDialog(false)}
              className="cursor-pointer rounded-full p-2 bg-gray-100"
            >
              <ClearIcon />
            </div>
          </div>
          <div>
            <div>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
                value={currentSite}
                onChange={(event) => setCurrentSite(event.target.value)}
              >
                {sites.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={handleSelectSite}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2 text-sm relative w-full"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  const handleChangePage = (event, value) => {
    setCurPage(value);
  };

  const handleQueryAnalytics = (value) => {
    let tmp = [...analyticsData.pageAnalytics];
    tmp = tmp.filter((t) => t.keys.includes(value));
    setFilterAnalyticsData(tmp);
  };

  const handleSortAnalytics = (field, order) => {
    function getComparator(field, orderBy) {
      if (field === "clicks") {
        return orderBy === "desc"
          ? (a, b) => descendingComparatorClicks(a, b)
          : (a, b) => -descendingComparatorClicks(a, b);
      } else if (field === "impressions") {
        return orderBy === "desc"
          ? (a, b) => descendingComparatorImpressions(a, b)
          : (a, b) => -descendingComparatorImpressions(a, b);
      } else if (field === "ctr") {
        return orderBy === "desc"
          ? (a, b) => descendingComparatorCTR(a, b)
          : (a, b) => -descendingComparatorCTR(a, b);
      } else if (field === "position") {
        return orderBy === "desc"
          ? (a, b) => descendingComparatorPosition(a, b)
          : (a, b) => -descendingComparatorPosition(a, b);
      }
    }

    let tmp = [...analyticsData.pageAnalytics];
    tmp.sort(getComparator(field, order));
    setFilterAnalyticsData(tmp);
  };

  const handleDateRangeChangeAnalyticSearch = async () => {
    setOpenTableLoader(true);
    await fetchAnalyticsApi(
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      integrationData.searchConsoleAuthData.site,
      curView === 0 ? false : true
    );
    setOpenTableLoader(false);
  };

  const handleViewSwitch = async (view) => {
    setOpenSwitchLoader(true);
    setCurView(view);
    setCurPage(1);
    setSearchQuery("");
    const sd = moment().subtract(30, "days").format("YYYY-MM-DD");
    const ed = moment().format("YYYY-MM-DD");
    await fetchAnalyticsApi(
      sd,
      ed,
      integrationData.searchConsoleAuthData.site,
      view === 0 ? false : true
    );
    setOpenSwitchLoader(false);
  };

  const indexPageApi = async () => {
    try {
      const payload = {
        url: indexUrl,
        projectId: projects.currentProject.id,
      };
      const responseData = await fetchBackendApiWrapper(
        `/api/v1/analytics/search-console/index`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        await auth.currentUser.getIdToken(false)
      );
      if (responseData.ok) {
        dispatch(
          openNotification({
            message: "Page successfully submitted for indexing",
            severity: "success",
          })
        );
      } else if (responseData.status === 403) {
        dispatch(
          openNotification({
            message: "You do not have access to index this page",
            severity: "error",
          })
        );
      } else if (responseData.status >= 400 && responseData.status < 500) {
        dispatch(
          openNotification({ message: "URL is invalid", severity: "error" })
        );
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(
        "Error in fetching page analytics data: " + JSON.stringify(err)
      );
      dispatch(
        openNotification({ message: "Something went wrong", severity: "error" })
      );
    }
  };

  function handleIndexPage() {
    try {
      if (indexLoader) return;
      setIndexLoader(true);
      indexPageApi().then(() => setIndexLoader(false));
    } catch (err) {
      console.error("Error in indexing page: " + JSON.stringify(err));
    }
  }

  return (
    <div
      className="flex justify-start items-start w-full min-h-screen relative"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className="py-8 px-4 flex flex-col justify-start items-center w-full sm:ml-64">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-4">
            <div className="sm:hidden">
              <IconButton sx={{ color: "gray" }} onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className="flex flex-col justify-start items-start w-full relative">
              <div className="text-lg font-semibold">Analytics</div>
              <div className="text-xs text-gray-600">
                Check how your website is performing
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-stretch w-full bg-white shadow-lg rounded-lg mt-4 relative px-10 py-10 min-h-screen overflow-x-auto">
          {error && (
            <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
              <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
                <div className="font-medium">
                  Something went wrong please try again
                </div>
              </div>
            </div>
          )}
          {openInitLoader && !error && (
            <div className="flex flex-col justify-start items-center w-full relative min-h-screen">
              <CircularProgress
                size="2rem"
                variant="indeterminate"
                sx={{ color: "gray", mt: "4rem" }}
              />
            </div>
          )}
          {!openInitLoader &&
            (!integrationData || !integrationData.searchConsoleAuthData) &&
            !error && (
              <div className="flex flex-col justify-start items-center w-full relative gap-4">
                <div className="text-gray-700 text-sm text-center">
                  {`Please connect your Google Search Console from integrations tab
              `}
                </div>
                <div
                  onClick={() => {
                    ReactGA.event({
                      category: "Click",
                      action: "GSC - Connect",
                      label: "User click GSC - Connect",
                    });
                    navigate("/dashboard/integration");}}
                  className="p-2 bg-black text-white rounded-md shadow-md font-medium text-sm cursor-pointer"
                >
                  Connect Search Console
                </div>
              </div>
            )}
          {!openInitLoader &&
            integrationData &&
            integrationData.searchConsoleAuthData &&
            !error &&
            sites &&
            sites.length === 0 && (
              <div className="flex flex-col justify-start items-center w-full relative gap-4">
                <div className="text-gray-700 text-sm text-center">
                  {`No connected sites found from your Google Search Console. Please first connect a site in search console and then come back here.
              `}
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://search.google.com/search-console/about",
                      "_blank"
                    )
                  }
                  className="p-2 bg-black text-white rounded-md shadow-md font-medium text-sm cursor-pointer"
                >
                  Open Search Console
                </div>
              </div>
            )}
          {!openInitLoader &&
            integrationData &&
            integrationData.searchConsoleAuthData &&
            integrationData.searchConsoleAuthData.active &&
            (!integrationData.searchConsoleAuthData.site ||
              integrationData.searchConsoleAuthData.site.length === 0) &&
            integrationData.searchConsoleAuthData.accessToken &&
            !error &&
            sites &&
            sites.length > 0 && (
              <div className="flex flex-col justify-start items-center w-full relative gap-4">
                <div
                  onClick={() => setOpenSelectSiteDialog(true)}
                  className="p-2 bg-black text-white rounded-md shadow-md font-medium text-sm cursor-pointer"
                >
                  Select Site
                </div>
              </div>
            )}
          {!openInitLoader &&
            integrationData &&
            integrationData.searchConsoleAuthData &&
            integrationData.searchConsoleAuthData.active &&
            integrationData.searchConsoleAuthData.site &&
            integrationData.searchConsoleAuthData.site.length > 0 &&
            integrationData.searchConsoleAuthData.accessToken &&
            !error &&
            sites &&
            sites.length > 0 && (
              <div className="flex flex-col justify-start items-start w-full relative gap-6">
                <div className="flex justify-between items-center w-full relative">
                  <div className="flex justify-start items-center gap-8">
                    <div
                      onClick={() => handleViewSwitch(0)}
                      className={`block cursor-pointer text-sm p-2 ${
                        curView === 0
                          ? "border-b-4 border-thbBlue font-semibold"
                          : "font-normal"
                      }`}
                    >
                      Webpages
                    </div>
                    <div
                      onClick={() => handleViewSwitch(1)}
                      className={`block p-2 cursor-pointer text-sm ${
                        curView === 1
                          ? "border-b-4 border-thbBlue font-semibold"
                          : "font-normal"
                      }`}
                    >
                      Queries
                    </div>
                    <div
                      onClick={() => setCurView(2)}
                      className={`block p-2 cursor-pointer text-sm ${
                        curView === 2
                          ? "border-b-4 border-thbBlue font-semibold"
                          : "font-normal"
                      }`}
                    >
                      Indexing
                    </div>
                  </div>
                  <div
                    onClick={() => setOpenSelectSiteDialog(true)}
                    className="border bg-gray-100 border-gray-300 px-2 py-1 text-sm font-medium rounded-md cursor-pointer"
                  >
                    {integrationData.searchConsoleAuthData.site}
                  </div>
                </div>
                {openSwitchLoader && (
                  <div className="flex flex-col justify-start items-center w-full relative min-h-screen">
                    <CircularProgress
                      size="2rem"
                      variant="indeterminate"
                      sx={{ color: "gray", mt: "4rem" }}
                    />
                  </div>
                )}
                {curView === 0 && !openSwitchLoader && analyticsData && (
                  <div className="flex flex-col justify-start items-start w-full relative gap-4">
                    <div className="text-xs text-gray-500">
                      Check how your webpages are performing
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 justify-between items-center w-full relative gap-2">
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="text-3xl text-center font-medium text-gray-800">
                          {analyticsData.summary.total}
                        </div>
                        <div className="text-xs text-center">Total Pages</div>
                      </div>
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="flex justify-center items-center gap-2 w-full relative">
                          <div className="text-3xl text-center font-medium text-gray-800 ">
                            {analyticsData.summary.currentTotalClicks}
                          </div>
                          {analyticsData.summary.clicksDifference !== 0 && (
                            <div
                              className={`${
                                analyticsData.summary.clicksDifference >= 0
                                  ? "bg-green-200"
                                  : "bg-red-200"
                              } px-1 rounded-md flex justify-center items-center`}
                            >
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.clicksDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.clicksDifference >= 0
                                  ? analyticsData.summary.clicksDifference
                                  : -analyticsData.summary.clicksDifference}
                              </div>
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.clicksDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.clicksDifference >= 0 ? (
                                  <ArrowUpwardRoundedIcon fontSize="0.4rem" />
                                ) : (
                                  <ArrowDownwardRoundedIcon fontSize="0.4rem" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="text-xs text-center">Total Clicks</div>
                      </div>
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="flex justify-center items-center gap-2 w-full relative">
                          <div className="text-3xl text-center font-medium text-gray-800 ">
                            {analyticsData.summary.currentTotalImpressions}
                          </div>
                          {analyticsData.summary.impressionsDifference !==
                            0 && (
                            <div
                              className={`${
                                analyticsData.summary.impressionsDifference >= 0
                                  ? "bg-green-200"
                                  : "bg-red-200"
                              } px-1 rounded-md flex justify-center items-center`}
                            >
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.impressionsDifference >=
                                  0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.impressionsDifference >=
                                0
                                  ? analyticsData.summary.impressionsDifference
                                  : -analyticsData.summary
                                      .impressionsDifference}
                              </div>
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.impressionsDifference >=
                                  0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.impressionsDifference >=
                                0 ? (
                                  <ArrowUpwardRoundedIcon fontSize="0.4rem" />
                                ) : (
                                  <ArrowDownwardRoundedIcon fontSize="0.4rem" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="text-xs text-center">
                          Total Impressions
                        </div>
                      </div>
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="flex justify-center items-center gap-2 w-full relative">
                          <div className="text-3xl text-center font-medium text-gray-800 ">
                            {parseFloat(
                              analyticsData.summary.currentAverageCtr
                            ).toFixed(2)}
                          </div>
                          {analyticsData.summary.ctrDifference !== 0 && (
                            <div
                              className={`${
                                analyticsData.summary.ctrDifference >= 0
                                  ? "bg-green-200"
                                  : "bg-red-200"
                              } px-1 rounded-md flex justify-center items-center`}
                            >
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.ctrDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.ctrDifference >= 0
                                  ? parseFloat(
                                      analyticsData.summary.ctrDifference
                                    ).toFixed(2)
                                  : parseFloat(
                                      -analyticsData.summary.ctrDifference
                                    ).toFixed(2)}
                              </div>
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.ctrDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.ctrDifference >= 0 ? (
                                  <ArrowUpwardRoundedIcon fontSize="0.4rem" />
                                ) : (
                                  <ArrowDownwardRoundedIcon fontSize="0.4rem" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="text-xs text-center">Average CTR</div>
                      </div>
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="flex justify-center items-center gap-2 w-full relative">
                          <div className="text-3xl text-center font-medium text-gray-800 ">
                            {parseFloat(
                              analyticsData.summary.currentAveragePosition
                            ).toFixed(2)}
                          </div>
                          {analyticsData.summary.averagePositionDifference !==
                            0 && (
                            <div
                              className={`${
                                analyticsData.summary
                                  .averagePositionDifference >= 0
                                  ? "bg-green-200"
                                  : "bg-red-200"
                              } px-1 rounded-md flex justify-center items-center`}
                            >
                              <div
                                className={`text-xs ${
                                  analyticsData.summary
                                    .averagePositionDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary
                                  .averagePositionDifference >= 0
                                  ? parseFloat(
                                      analyticsData.summary
                                        .averagePositionDifference
                                    ).toFixed(2)
                                  : parseFloat(
                                      -analyticsData.summary
                                        .averagePositionDifference
                                    ).toFixed(2)}
                              </div>
                              <div
                                className={`text-xs ${
                                  analyticsData.summary
                                    .averagePositionDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary
                                  .averagePositionDifference >= 0 ? (
                                  <ArrowUpwardRoundedIcon fontSize="0.4rem" />
                                ) : (
                                  <ArrowDownwardRoundedIcon fontSize="0.4rem" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="text-xs text-center">
                          Average Position
                        </div>
                      </div>
                    </div>
                    <div className="rounded-md border border-text-300 pt-4 flex flex-col justify-start items-start relative overflow-x-auto">
                      <div className="flex mt-4 justify-between gap-4 items-center w-full relative">
                        <div className="relative pl-4">
                          <input
                            type="text"
                            placeholder="Search URL"
                            className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2`}
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                              handleQueryAnalytics(e.target.value);
                            }}
                          />
                        </div>
                        <div className="flex justify-end items-center relative gap-1 w-2/5 pr-4">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                ".MuiInputBase-input": {
                                  padding: "10px",
                                },
                              }}
                              label="Start Date"
                              value={startDate}
                              onChange={(newValue) => {
                                setStartDate(newValue);
                              }}
                            />
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                ".MuiInputBase-input": {
                                  padding: "10px",
                                },
                              }}
                              slotProps={{ textField: { size: "small" } }}
                              label="End Date"
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                            />
                          </LocalizationProvider>
                          <button
                            onClick={handleDateRangeChangeAnalyticSearch}
                            className="bg-black rounded-md text-white px-4 py-2 font-medium"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      {viewAnalyticsData && viewAnalyticsData.length > 0 && (
                        <div className="w-full relative">
                          <table className="mt-8 table-fixed relative lg:w-full">
                            <thead>
                              <tr>
                                {/* <th className="bg-orange-50 py-2 text-sm font-medium w-12"></th> */}
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium w-2/5">
                                  URL
                                </th>
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium text-left">
                                  <TableSortLabel
                                    active={sortField === "clicks"}
                                    direction={
                                      sortField === "clicks" ? sortOrder : "asc"
                                    }
                                    onClick={() => {
                                      setSortField("clicks");
                                      const ord =
                                        sortOrder === "asc" ? "desc" : "asc";
                                      setSortOrder(ord);
                                      handleSortAnalytics("clicks", ord);
                                    }}
                                  >
                                    Clicks
                                  </TableSortLabel>
                                </th>
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium text-left">
                                  <TableSortLabel
                                    active={sortField === "impressions"}
                                    direction={
                                      sortField === "impressions"
                                        ? sortOrder
                                        : "asc"
                                    }
                                    onClick={() => {
                                      setSortField("impressions");
                                      const ord =
                                        sortOrder === "asc" ? "desc" : "asc";
                                      setSortOrder(ord);
                                      handleSortAnalytics("impressions", ord);
                                    }}
                                  >
                                    Impressions
                                  </TableSortLabel>
                                </th>
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium text-left">
                                  <TableSortLabel
                                    active={sortField === "ctr"}
                                    direction={
                                      sortField === "ctr" ? sortOrder : "asc"
                                    }
                                    onClick={() => {
                                      setSortField("ctr");
                                      const ord =
                                        sortOrder === "asc" ? "desc" : "asc";
                                      setSortOrder(ord);
                                      handleSortAnalytics("ctr", ord);
                                    }}
                                  >
                                    CTR
                                  </TableSortLabel>
                                </th>
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium text-left">
                                  <TableSortLabel
                                    active={sortField === "position"}
                                    direction={
                                      sortField === "position"
                                        ? sortOrder
                                        : "asc"
                                    }
                                    onClick={() => {
                                      setSortField("position");
                                      const ord =
                                        sortOrder === "asc" ? "desc" : "asc";
                                      setSortOrder(ord);
                                      handleSortAnalytics("position", ord);
                                    }}
                                  >
                                    Position
                                  </TableSortLabel>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {!openTableLoader &&
                                viewAnalyticsData.map((row, ind) => {
                                  return (
                                    <tr key={ind} className="hover:bg-gray-50">
                                      {/* <td className="py-6 text-xs text-center text-black">
                                        {ind + 1}
                                      </td> */}
                                      <td className="py-6 pl-6 pr-6 whitespace-nowrap text-ellipsis overflow-hidden text-xs text-blue-600">
                                        <a
                                          href={row.keys}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {row.keys}
                                        </a>
                                      </td>
                                      <td className="py-4 text-xs">
                                        <div className="flex justify-start items-center gap-2 w-full relative">
                                          <div className="text-xs font-medium text-center text-gray-800 ">
                                            {row.clicks}
                                          </div>
                                          {row.clicksDifference !== 0 && (
                                            <div
                                              className={`${
                                                row.clicksDifference >= 0
                                                  ? "bg-green-100"
                                                  : "bg-red-200"
                                              } px-1 rounded-md flex justify-center items-center`}
                                            >
                                              <div
                                                className={`text-xs ${
                                                  row.clicksDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.clicksDifference >= 0
                                                  ? row.clicksDifference
                                                  : -row.clicksDifference}
                                              </div>
                                              <div
                                                className={`text-xs ${
                                                  row.clicksDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.clicksDifference >= 0 ? (
                                                  <ArrowUpwardRoundedIcon fontSize="0.2rem" />
                                                ) : (
                                                  <ArrowDownwardRoundedIcon fontSize="0.2rem" />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="py-4 text-xs">
                                        <div className="flex justify-start items-center gap-2 w-full relative">
                                          <div className="text-xs font-medium text-center text-gray-800 ">
                                            {row.impressions}
                                          </div>
                                          {row.impressionsDifference !== 0 && (
                                            <div
                                              className={`${
                                                row.impressionsDifference >= 0
                                                  ? "bg-green-200"
                                                  : "bg-red-200"
                                              } px-1 rounded-md flex justify-center items-center`}
                                            >
                                              <div
                                                className={`text-xs ${
                                                  row.impressionsDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.impressionsDifference >= 0
                                                  ? row.impressionsDifference
                                                  : -row.impressionsDifference}
                                              </div>
                                              <div
                                                className={`text-xs ${
                                                  row.impressionsDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.impressionsDifference >=
                                                0 ? (
                                                  <ArrowUpwardRoundedIcon fontSize="0.2rem" />
                                                ) : (
                                                  <ArrowDownwardRoundedIcon fontSize="0.2rem" />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="py-4 text-xs">
                                        <div className="flex justify-start items-center gap-2 w-full relative">
                                          <div className="text-xs font-medium text-center text-gray-800 ">
                                            {parseFloat(row.ctr).toFixed(2)}
                                          </div>
                                          {row.ctrDifference !== 0 && (
                                            <div
                                              className={`${
                                                row.ctrDifference >= 0
                                                  ? "bg-green-200"
                                                  : "bg-red-200"
                                              } px-1 rounded-md flex justify-center items-center`}
                                            >
                                              <div
                                                className={`text-xs ${
                                                  row.ctrDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.ctrDifference >= 0
                                                  ? parseFloat(
                                                      row.ctrDifference
                                                    ).toFixed(2)
                                                  : -parseFloat(
                                                      row.ctrDifference
                                                    ).toFixed(2)}
                                              </div>
                                              <div
                                                className={`text-xs ${
                                                  row.ctrDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.ctrDifference >= 0 ? (
                                                  <ArrowUpwardRoundedIcon fontSize="0.2rem" />
                                                ) : (
                                                  <ArrowDownwardRoundedIcon fontSize="0.2rem" />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="py-4 text-xs">
                                        <div className="flex justify-start items-center gap-2 w-full relative">
                                          <div className="text-xs font-medium text-center text-gray-800 ">
                                            {parseFloat(row.position).toFixed(
                                              2
                                            )}
                                          </div>
                                          {row.ctrDifference !== 0 && (
                                            <div
                                              className={`${
                                                row.positionDifference >= 0
                                                  ? "bg-green-200"
                                                  : "bg-red-200"
                                              } px-1 rounded-md flex justify-center items-center`}
                                            >
                                              <div
                                                className={`text-xs ${
                                                  row.positionDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.positionDifference >= 0
                                                  ? parseFloat(
                                                      row.positionDifference
                                                    ).toFixed(2)
                                                  : -parseFloat(
                                                      row.positionDifference
                                                    ).toFixed(2)}
                                              </div>
                                              <div
                                                className={`text-xs ${
                                                  row.positionDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.positionDifference >= 0 ? (
                                                  <ArrowUpwardRoundedIcon fontSize="0.2rem" />
                                                ) : (
                                                  <ArrowDownwardRoundedIcon fontSize="0.2rem" />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          {openTableLoader && (
                            <div className="flex flex-col justify-start items-center w-full relative">
                              <CircularProgress
                                size="2rem"
                                variant="indeterminate"
                                sx={{ color: "gray", my: "4rem" }}
                              />
                            </div>
                          )}
                          <div className="flex justify-center items-center mt-4 mb-8">
                            <Stack spacing={2}>
                              <Pagination
                                count={Math.ceil(
                                  filterAnalyticsData.length / pageSize
                                )}
                                page={curPage}
                                onChange={handleChangePage}
                                renderItem={(item) => (
                                  <PaginationItem
                                    slots={{
                                      previous: ArrowBackIcon,
                                      next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {curView === 1 && !openSwitchLoader && analyticsData && (
                  <div className="flex flex-col justify-start items-start w-full relative gap-4">
                    <div className="text-xs text-gray-500">
                      Check what search queries bring people to your site.
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 justify-between items-center w-full relative gap-2">
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="text-3xl text-center font-medium text-gray-800">
                          {analyticsData.summary.total}
                        </div>
                        <div className="text-xs text-center">
                          Total Keywords
                        </div>
                      </div>
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="flex justify-center items-center gap-2 w-full relative">
                          <div className="text-3xl text-center font-medium text-gray-800 ">
                            {analyticsData.summary.currentTotalClicks}
                          </div>
                          {analyticsData.summary.clicksDifference !== 0 && (
                            <div
                              className={`${
                                analyticsData.summary.clicksDifference >= 0
                                  ? "bg-green-200"
                                  : "bg-red-200"
                              } px-1 rounded-md flex justify-center items-center`}
                            >
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.clicksDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.clicksDifference >= 0
                                  ? analyticsData.summary.clicksDifference
                                  : -analyticsData.summary.clicksDifference}
                              </div>
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.clicksDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.clicksDifference >= 0 ? (
                                  <ArrowUpwardRoundedIcon fontSize="0.4rem" />
                                ) : (
                                  <ArrowDownwardRoundedIcon fontSize="0.4rem" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="text-xs text-center">Total Clicks</div>
                      </div>
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="flex justify-center items-center gap-2 w-full relative">
                          <div className="text-3xl text-center font-medium text-gray-800 ">
                            {analyticsData.summary.currentTotalImpressions}
                          </div>
                          {analyticsData.summary.impressionsDifference !==
                            0 && (
                            <div
                              className={`${
                                analyticsData.summary.impressionsDifference >= 0
                                  ? "bg-green-200"
                                  : "bg-red-200"
                              } px-1 rounded-md flex justify-center items-center`}
                            >
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.impressionsDifference >=
                                  0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.impressionsDifference >=
                                0
                                  ? analyticsData.summary.impressionsDifference
                                  : -analyticsData.summary
                                      .impressionsDifference}
                              </div>
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.impressionsDifference >=
                                  0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.impressionsDifference >=
                                0 ? (
                                  <ArrowUpwardRoundedIcon fontSize="0.4rem" />
                                ) : (
                                  <ArrowDownwardRoundedIcon fontSize="0.4rem" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="text-xs text-center">
                          Total Impressions
                        </div>
                      </div>
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="flex justify-center items-center gap-2 w-full relative">
                          <div className="text-3xl text-center font-medium text-gray-800 ">
                            {parseFloat(
                              analyticsData.summary.currentAverageCtr
                            ).toFixed(2)}
                          </div>
                          {analyticsData.summary.ctrDifference !== 0 && (
                            <div
                              className={`${
                                analyticsData.summary.ctrDifference >= 0
                                  ? "bg-green-200"
                                  : "bg-red-200"
                              } px-1 rounded-md flex justify-center items-center`}
                            >
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.ctrDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.ctrDifference >= 0
                                  ? parseFloat(
                                      analyticsData.summary.ctrDifference
                                    ).toFixed(2)
                                  : parseFloat(
                                      -analyticsData.summary.ctrDifference
                                    ).toFixed(2)}
                              </div>
                              <div
                                className={`text-xs ${
                                  analyticsData.summary.ctrDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary.ctrDifference >= 0 ? (
                                  <ArrowUpwardRoundedIcon fontSize="0.4rem" />
                                ) : (
                                  <ArrowDownwardRoundedIcon fontSize="0.4rem" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="text-xs text-center">Average CTR</div>
                      </div>
                      <div className="col-span-1 border border-gray-200 py-8 px-6 rounded-md flex flex-col justify-center items-center w-full relative gap-2">
                        <div className="flex justify-center items-center gap-2 w-full relative">
                          <div className="text-3xl text-center font-medium text-gray-800 ">
                            {parseFloat(
                              analyticsData.summary.currentAveragePosition
                            ).toFixed(2)}
                          </div>
                          {analyticsData.summary.averagePositionDifference !==
                            0 && (
                            <div
                              className={`${
                                analyticsData.summary
                                  .averagePositionDifference >= 0
                                  ? "bg-green-200"
                                  : "bg-red-200"
                              } px-1 rounded-md flex justify-center items-center`}
                            >
                              <div
                                className={`text-xs ${
                                  analyticsData.summary
                                    .averagePositionDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary
                                  .averagePositionDifference >= 0
                                  ? parseFloat(
                                      analyticsData.summary
                                        .averagePositionDifference
                                    ).toFixed(2)
                                  : parseFloat(
                                      -analyticsData.summary
                                        .averagePositionDifference
                                    ).toFixed(2)}
                              </div>
                              <div
                                className={`text-xs ${
                                  analyticsData.summary
                                    .averagePositionDifference >= 0
                                    ? "text-green-500"
                                    : "text-red-400"
                                } `}
                              >
                                {analyticsData.summary
                                  .averagePositionDifference >= 0 ? (
                                  <ArrowUpwardRoundedIcon fontSize="0.4rem" />
                                ) : (
                                  <ArrowDownwardRoundedIcon fontSize="0.4rem" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="text-xs text-center">
                          Average Position
                        </div>
                      </div>
                    </div>
                    <div className="rounded-md border border-text-300 pt-4 flex flex-col justify-start items-start relative">
                      <div className="flex mt-4 justify-between gap-4 items-center w-full relative">
                        <div className="relative pl-4">
                          <input
                            type="text"
                            placeholder="Search Keyword"
                            className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2`}
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                              handleQueryAnalytics(e.target.value);
                            }}
                          />
                        </div>
                        <div className="flex justify-end items-center relative gap-1 w-2/5 pr-4">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                ".MuiInputBase-input": {
                                  padding: "10px",
                                },
                              }}
                              label="Start Date"
                              value={startDate}
                              onChange={(newValue) => {
                                setStartDate(newValue);
                              }}
                            />
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                ".MuiInputBase-input": {
                                  padding: "10px",
                                },
                              }}
                              slotProps={{ textField: { size: "small" } }}
                              label="End Date"
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                            />
                          </LocalizationProvider>
                          <button
                            onClick={handleDateRangeChangeAnalyticSearch}
                            className="bg-black rounded-md text-white px-4 py-2 font-medium"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      {viewAnalyticsData && viewAnalyticsData.length > 0 && (
                        <div className="w-full relative">
                          <table className="mt-8 table-fixed relative lg:w-full">
                            <thead>
                              <tr>
                                {/* <th className="bg-orange-50 py-2 text-sm font-medium w-12"></th> */}
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium w-2/5">
                                  Keyword
                                </th>
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium text-left">
                                  <TableSortLabel
                                    active={sortField === "clicks"}
                                    direction={
                                      sortField === "clicks" ? sortOrder : "asc"
                                    }
                                    onClick={() => {
                                      setSortField("clicks");
                                      const ord =
                                        sortOrder === "asc" ? "desc" : "asc";
                                      setSortOrder(ord);
                                      handleSortAnalytics("clicks", ord);
                                    }}
                                  >
                                    Clicks
                                  </TableSortLabel>
                                </th>
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium text-left">
                                  <TableSortLabel
                                    active={sortField === "impressions"}
                                    direction={
                                      sortField === "impressions"
                                        ? sortOrder
                                        : "asc"
                                    }
                                    onClick={() => {
                                      setSortField("impressions");
                                      const ord =
                                        sortOrder === "asc" ? "desc" : "asc";
                                      setSortOrder(ord);
                                      handleSortAnalytics("impressions", ord);
                                    }}
                                  >
                                    Impressions
                                  </TableSortLabel>
                                </th>
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium text-left">
                                  <TableSortLabel
                                    active={sortField === "ctr"}
                                    direction={
                                      sortField === "ctr" ? sortOrder : "asc"
                                    }
                                    onClick={() => {
                                      setSortField("ctr");
                                      const ord =
                                        sortOrder === "asc" ? "desc" : "asc";
                                      setSortOrder(ord);
                                      handleSortAnalytics("ctr", ord);
                                    }}
                                  >
                                    CTR
                                  </TableSortLabel>
                                </th>
                                <th className="bg-orange-50 py-2 mr-4 text-sm font-medium text-left">
                                  <TableSortLabel
                                    active={sortField === "position"}
                                    direction={
                                      sortField === "position"
                                        ? sortOrder
                                        : "asc"
                                    }
                                    onClick={() => {
                                      setSortField("position");
                                      const ord =
                                        sortOrder === "asc" ? "desc" : "asc";
                                      setSortOrder(ord);
                                      handleSortAnalytics("position", ord);
                                    }}
                                  >
                                    Position
                                  </TableSortLabel>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {!openTableLoader &&
                                viewAnalyticsData.map((row, ind) => {
                                  return (
                                    <tr key={ind} className="hover:bg-gray-50">
                                      {/* <td className="py-6 text-xs text-center text-black">
                                        {ind + 1}
                                      </td> */}
                                      <td className="py-6 pl-6 pr-6 whitespace-nowrap text-ellipsis overflow-hidden text-xs text-blue-600">
                                        {row.keys}
                                      </td>
                                      <td className="py-4 text-xs">
                                        <div className="flex justify-start items-center gap-2 w-full relative">
                                          <div className="text-xs font-medium text-center text-gray-800 ">
                                            {row.clicks}
                                          </div>
                                          {row.clicksDifference !== 0 && (
                                            <div
                                              className={`${
                                                row.clicksDifference >= 0
                                                  ? "bg-green-100"
                                                  : "bg-red-200"
                                              } px-1 rounded-md flex justify-center items-center`}
                                            >
                                              <div
                                                className={`text-xs ${
                                                  row.clicksDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.clicksDifference >= 0
                                                  ? row.clicksDifference
                                                  : -row.clicksDifference}
                                              </div>
                                              <div
                                                className={`text-xs ${
                                                  row.clicksDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.clicksDifference >= 0 ? (
                                                  <ArrowUpwardRoundedIcon fontSize="0.2rem" />
                                                ) : (
                                                  <ArrowDownwardRoundedIcon fontSize="0.2rem" />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="py-4 text-xs">
                                        <div className="flex justify-start items-center gap-2 w-full relative">
                                          <div className="text-xs font-medium text-center text-gray-800 ">
                                            {row.impressions}
                                          </div>
                                          {row.impressionsDifference !== 0 && (
                                            <div
                                              className={`${
                                                row.impressionsDifference >= 0
                                                  ? "bg-green-200"
                                                  : "bg-red-200"
                                              } px-1 rounded-md flex justify-center items-center`}
                                            >
                                              <div
                                                className={`text-xs ${
                                                  row.impressionsDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.impressionsDifference >= 0
                                                  ? row.impressionsDifference
                                                  : -row.impressionsDifference}
                                              </div>
                                              <div
                                                className={`text-xs ${
                                                  row.impressionsDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.impressionsDifference >=
                                                0 ? (
                                                  <ArrowUpwardRoundedIcon fontSize="0.2rem" />
                                                ) : (
                                                  <ArrowDownwardRoundedIcon fontSize="0.2rem" />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="py-4 text-xs">
                                        <div className="flex justify-start items-center gap-2 w-full relative">
                                          <div className="text-xs font-medium text-center text-gray-800 ">
                                            {parseFloat(row.ctr).toFixed(2)}
                                          </div>
                                          {row.ctrDifference !== 0 && (
                                            <div
                                              className={`${
                                                row.ctrDifference >= 0
                                                  ? "bg-green-200"
                                                  : "bg-red-200"
                                              } px-1 rounded-md flex justify-center items-center`}
                                            >
                                              <div
                                                className={`text-xs ${
                                                  row.ctrDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.ctrDifference >= 0
                                                  ? parseFloat(
                                                      row.ctrDifference
                                                    ).toFixed(2)
                                                  : -parseFloat(
                                                      row.ctrDifference
                                                    ).toFixed(2)}
                                              </div>
                                              <div
                                                className={`text-xs ${
                                                  row.ctrDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.ctrDifference >= 0 ? (
                                                  <ArrowUpwardRoundedIcon fontSize="0.2rem" />
                                                ) : (
                                                  <ArrowDownwardRoundedIcon fontSize="0.2rem" />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="py-4 text-xs">
                                        <div className="flex justify-start items-center gap-2 w-full relative">
                                          <div className="text-xs font-medium text-center text-gray-800 ">
                                            {parseFloat(row.position).toFixed(
                                              2
                                            )}
                                          </div>
                                          {row.ctrDifference !== 0 && (
                                            <div
                                              className={`${
                                                row.positionDifference >= 0
                                                  ? "bg-green-200"
                                                  : "bg-red-200"
                                              } px-1 rounded-md flex justify-center items-center`}
                                            >
                                              <div
                                                className={`text-xs ${
                                                  row.positionDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.positionDifference >= 0
                                                  ? parseFloat(
                                                      row.positionDifference
                                                    ).toFixed(2)
                                                  : -parseFloat(
                                                      row.positionDifference
                                                    ).toFixed(2)}
                                              </div>
                                              <div
                                                className={`text-xs ${
                                                  row.positionDifference >= 0
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                } `}
                                              >
                                                {row.positionDifference >= 0 ? (
                                                  <ArrowUpwardRoundedIcon fontSize="0.2rem" />
                                                ) : (
                                                  <ArrowDownwardRoundedIcon fontSize="0.2rem" />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          {openTableLoader && (
                            <div className="flex flex-col justify-start items-center w-full relative">
                              <CircularProgress
                                size="2rem"
                                variant="indeterminate"
                                sx={{ color: "gray", my: "4rem" }}
                              />
                            </div>
                          )}
                          <div className="flex justify-center items-center mt-4 mb-8">
                            <Stack spacing={2}>
                              <Pagination
                                count={Math.ceil(
                                  filterAnalyticsData.length / pageSize
                                )}
                                page={curPage}
                                onChange={handleChangePage}
                                renderItem={(item) => (
                                  <PaginationItem
                                    slots={{
                                      previous: ArrowBackIcon,
                                      next: ArrowForwardIcon,
                                    }}
                                    {...item}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {curView === 2 && !openSwitchLoader && (
                  <div className="flex flex-col justify-start items-start w-full relative gap-4">
                    <div className="text-gray-600 text-sm">
                      Check if Google knows about your site page.
                    </div>
                    <div className="border border-gray-200 rounded-md p-2 flex flex-col justify-start items-start gap-4 w-full md:w-1/4">
                      <div className="text-xs font-med">Add URL</div>
                      <input
                        type="text"
                        placeholder=""
                        className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                        required
                        value={indexUrl}
                        onChange={(e) => setIndexUrl(e.target.value)}
                      />
                      <button
                        onClick={() => handleIndexPage()}
                        className="bg-thbBlue px-4 py-2 text-white rounded-lg text-sm font-medium"
                      >
                        {indexLoader ? (
                          <CircularProgress
                            size="1rem"
                            variant="indeterminate"
                            sx={{ color: "white" }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>
        <SiteSelectionDialog />
      </div>
    </div>
  );
};

export default Analytics;
