import { IconButton } from "@mui/material";
import SideBar from "../../../components/SideBar";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pricing from "../../../components/Pricing";
import { fetchBackendApiWrapper } from "../../../utils/apiWrapper";
import ReactGA from "react-ga4";

const PlanInfoSettings = () => {
  const navigate = useNavigate();
  const [monthly, setMonthly] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    ReactGA.event({
      category: "Page Load",
      action: "Settings Plans & Packages - Load",
      location: window.location.pathname,
      label: "Settings Plans & Packages - Load",
    });
    initPricing();
  }, []);

  const initPricing = async () => {
    try {
      const res = await fetchBackendApiWrapper(
        "/api/v1/plans",
        {
          method: "GET",
        },
        null
      );
      if (res && res.ok) {
        const data = await res.json();
        // console.log("Successfully got plans: " + JSON.stringify(data));
        const tmp = data.filter(pl => pl.active);
        setPlans(tmp);
      }
    } catch (err) {
      console.error("Error in fetching plans: " + JSON.stringify(err));
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div
      className="flex justify-start items-start w-full min-h-screen relative"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className="py-8 px-4 flex flex-col justify-start items-center w-full sm:ml-64">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-4">
            <div className="sm:hidden">
              <IconButton sx={{ color: "gray" }} onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className="text-lg font-semibold">Settings</div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-stretch w-full bg-white shadow-lg rounded-lg mt-4 relative">
          <div className="flex justify-start items-center w-full relative border-b border-gray-200 overflow-x-auto">
            <div className="flex justify-start items-center gap-4 pl-10">
              <div
                onClick={() => navigate("/dashboard/settings/account")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Account
              </div>
              {/* <div
                onClick={() => navigate("/dashboard/settings/brand")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Brands
              </div> */}
              <div
                onClick={() => navigate("/dashboard/settings/subscription")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Subscription
              </div>
              <div
                className={`px-6 py-2 text-sm font-medium cursor-pointer border-b-4 border-blue-400`}
              >
                Plans and Packages
              </div>
              <div
                onClick={() => navigate("/dashboard/settings/support")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Support
              </div>
            </div>
          </div>
          {/* <div className="mt-4 flex justify-center items-center gap-0">
            <button
              onClick={() => setMonthly(true)}
              className={`text-gray-800 font-bold py-2 px-4 rounded-l-full ${
                monthly ? "bg-thbBlue text-white" : "bg-gray-200 text-black"
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => setMonthly(false)}
              className={`font-bold py-2 px-4 rounded-r-full ${
                monthly === false
                  ? "bg-thbBlue text-white"
                  : "bg-gray-200 text-black"
              }`}
            >
              Annually
            </button>
          </div> */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-start items-center w-full relative gap-10 px-4 py-4">
            {plans &&
              plans.map((p) => {
                return (
                  <div
                    key={p.id}
                    className="flex justify-start items-center w-full relative"
                  >
                    <Pricing
                      pricing={p}
                      monthly={monthly}
                      setMonthly={setMonthly}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanInfoSettings;
