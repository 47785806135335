import { IconButton } from "@mui/material";
import SideBar from "../../../components/SideBar";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Pricing from "../../../components/Pricing";
import { fetchBackendApiWrapper } from "../../../utils/apiWrapper";
import moment from "moment";
import { auth } from "../../../auth/firebaseConfig";
import ReactGA from "react-ga4";

const SubscriptionSettings = () => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const projects = useSelector((state) => state.projects);
  const [plans, setPlans] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [monthly, setMonthly] = useState(true);

  useEffect(() => {
    ReactGA.event({
      category: "Page Load",
      action: "Settings Subscriptions - Load",
      location: window.location.pathname,
      label: "Settings Subscriptions - Load",
    });
    if (!projects.currentProject.isActiveSubscription) {
      initPricing();
    }
    fetchPaymentDetails();
  }, []);

  const initPricing = async () => {
    try {
      const res = await fetchBackendApiWrapper(
        "/api/v1/plans",
        {
          method: "GET",
        },
        null
      );
      if (res && res.ok) {
        const data = await res.json();
        // console.log("Successfully got plans: " + JSON.stringify(data));
        const tmp = data.filter(pl => pl.active);
        setPlans(tmp);
      }
    } catch (err) {
      console.error("Error in fetching plans: " + JSON.stringify(err));
    }
  };

  const fetchPaymentDetails = async () => {
    try {
      const res = await fetchBackendApiWrapper(
        `/api/v1/payment/project?projectId=${projects.currentProject.id}&paymentStatus=2`,
        {
          method: "GET",
        },
        await auth.currentUser.getIdToken(false)
      );
      if (res && res.ok) {
        const data = await res.json();
        console.log(
          "Successfully got payment details: " + JSON.stringify(data)
        );
        setPaymentDetails(data);
      }
    } catch (err) {
      console.error(
        "Error in fetching payment details: " + JSON.stringify(err)
      );
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div
      className="flex justify-start items-start w-full min-h-screen relative"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className="py-8 px-4 flex flex-col justify-start items-center w-full sm:ml-64">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-4">
            <div className="sm:hidden">
              <IconButton sx={{ color: "gray" }} onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className="text-lg font-semibold">Settings</div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-stretch w-full bg-white shadow-lg rounded-lg mt-4 relative">
          <div className="flex justify-start items-center w-full relative border-b border-gray-200 overflow-x-auto">
            <div className="flex justify-start items-center gap-4 pl-10">
              <div
                onClick={() => navigate("/dashboard/settings/account")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Account
              </div>
              {/* <div
                onClick={() => navigate("/dashboard/settings/brand")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Brands
              </div> */}
              <div
                className={`px-6 py-2 text-sm font-medium cursor-pointer border-b-4 border-blue-400`}
              >
                Subscription
              </div>
              <div
                onClick={() => navigate("/dashboard/settings/plans")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Plans and Packages
              </div>
              <div
                onClick={() => navigate("/dashboard/settings/support")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Support
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start w-full relative gap-8 px-10 py-8 overflow-x-auto">
            {projects.currentProject &&
              !projects.currentProject.isActiveSubscription && (
                <>
                  <div className="flex justify-start items-center w-full relative text-sm text-center">
                    {`You don’t have any active subscription for this brand, you can buy the following plan`}
                  </div>
                  {/* <div className="mt-4 flex justify-center items-center gap-0 w-full relative">
                    <button
                      onClick={() => setMonthly(true)}
                      className={`text-gray-800 font-bold py-2 px-4 rounded-l-full ${
                        monthly
                          ? "bg-thbBlue text-white"
                          : "bg-gray-200 text-black"
                      }`}
                    >
                      Monthly
                    </button>
                    <button
                      onClick={() => setMonthly(false)}
                      className={`font-bold py-2 px-4 rounded-r-full ${
                        monthly === false
                          ? "bg-thbBlue text-white"
                          : "bg-gray-200 text-black"
                      }`}
                    >
                      Annually
                    </button>
                  </div> */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-start items-center w-full relative px-0 gap-10 py-4">
                    {plans &&
                      plans.map((p) => {
                        return (
                          <div
                            key={p.id}
                            className="flex justify-start items-center w-full relative"
                          >
                            <Pricing
                              pricing={p}
                              monthly={monthly}
                              setMonthly={setMonthly}
                            />
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            {projects.currentProject &&
              projects.currentProject.isActiveSubscription &&
              paymentDetails &&
              paymentDetails.length > 0 && (
                <div className="flex flex-col justify-start items-start gap-6">
                  <div className="font-medium">Active Subscription</div>
                  <div className="border  rounded-lg  p-6 flex flex-col w-full relative gap-4">
                    <div className="font-semibold text-sm">{`${paymentDetails[0].planName} Subscription`}</div>
                    <div className="text-sm font-light text-gray-600">
                      {`Valid till ${moment(
                        paymentDetails[0].subscriptionEndDate
                      ).format("DD MMM YYYY")}`}
                    </div>
                  </div>
                </div>
              )}
            {paymentDetails && paymentDetails.length > 0 && (
              <div className="flex flex-col justify-start items-start w-full relative gap-6 font-medium">
                <div>Past Subscriptions</div>
                <table className="table-auto border-collapse border border-slate-200 relative w-full">
                  <thead>
                    <tr className="bg-slate-100">
                      <th className="border-y border-slate-200 p-4 text-sm font-medium">
                        No.
                      </th>
                      <th className="border-y border-slate-200 p-4 text-sm font-medium">
                        Subscription Name
                      </th>
                      <th className="border-y border-slate-200 p-4 text-sm font-medium">
                        Subscription Tenure
                      </th>
                      <th className="border-y border-slate-200 p-4 text-sm font-medium">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentDetails.map((pd, rId) => {
                      return (
                        <tr key={pd.id}>
                          <td className="border-y border-slate-200 p-2 text-center font-light text-sm">
                            {rId + 1}
                          </td>
                          <td className="border-y border-slate-200 p-2 text-center font-light text-sm">
                            {pd.planName}
                          </td>
                          <td className="border-y border-slate-200 p-2 text-center font-light text-sm">
                            {moment(pd.subscriptionStartDate).format(
                              "DD MMM YYYY"
                            ) +
                              " - " +
                              moment(pd.subscriptionEndDate).format(
                                "DD MMM YYYY"
                              )}
                          </td>
                          <td className="border-y border-slate-200 p-2 text-center font-light text-sm">
                            ₹ {pd.amount / 100}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSettings;
