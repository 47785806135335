export function descendingComparatorClicks(a, b) {
  if (b.clicks < a.clicks) {
    return -1;
  }
  if (b.clicks > a.clicks) {
    return 1;
  }
  return 0;
}

export function descendingComparatorImpressions(a, b) {
  if (b.impressions < a.impressions) {
    return -1;
  }
  if (b.impressions > a.impressions) {
    return 1;
  }
  return 0;
}

export function descendingComparatorCTR(a, b) {
  if (b.ctr < a.ctr) {
    return -1;
  }
  if (b.ctr > a.ctr) {
    return 1;
  }
  return 0;
}

export function descendingComparatorPosition(a, b) {
  if (b.position < a.position) {
    return -1;
  }
  if (b.position > a.position) {
    return 1;
  }
  return 0;
}
