import { Dialog } from "@mui/material";
import { useState } from "react";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import SearchIcon from "@mui/icons-material/Search";
import { fetchBackendApiWrapper } from "../utils/apiWrapper";
import { openNotification } from "../slice/notificationSlice";
import { useDispatch } from "react-redux";
import RegenerationSubscribeDialog from "./subscribeDialogs/RegenerationSubscribeDialog";
import { auth } from "../auth/firebaseConfig";

const ImageRegenerateComponent = ({
  openImageRegenerateModal,
  setOpenImageRegenerateModal,
  heroImage,
  setHeroImage,
  setSaveChange,
  topicId,
  setOpenLoadingModal,
  quill,
  projectId,
  setLoadingModalMessage,
}) => {
  const dispatch = useDispatch();
  const handleModalClose = () => {
    setOpenImageRegenerateModal(false);
    setCurrentView(0);
    setImagePrompt("");
    setUnsplashSearchResults([]);
    setUnsplashSearchQuery("");
  };
  // regen image - 3
  // unsplash - 2
  // upload - 1
  const [currentView, setCurrentView] = useState(0);
  const [openRegenerationSubscribeModal, setOpenRegenerationSubscribeModal] =
    useState(false);
  const [
    messageRegenerationSubscribeModal,
    setMessageRegenerationSubscribeModal,
  ] = useState("Subscribe to regenerate");

  // upload image
  const handleImageUploadAction = () => {
    const input = document.createElement(`input`);
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          if (quill === null) {
            setHeroImage({ ...heroImage, url: base64String });
          } else {
            if (quill.current) {
              const quillRef = quill.current.getEditor();
              const range = quillRef.getSelection(true);
              quillRef.insertEmbed(range.index, "image", base64String, "user");
            }
          }
          handleModalClose();
          setSaveChange(true);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  // AI Image
  const [imagePrompt, setImagePrompt] = useState("");

  const handleAIImageAction = async () => {
    if (
      imagePrompt !== null &&
      imagePrompt !== undefined &&
      imagePrompt.length > 0
    ) {
      try {
        setLoadingModalMessage("Generating Image. Please wait...");
        setOpenLoadingModal(true);
        const aiImageRes = await generateAIImageApi();
        if (aiImageRes.ok) {
          const aiImageJson = await aiImageRes.json();
          if (quill === null) {
            setHeroImage({ ...heroImage, url: aiImageJson.url });
          } else {
            if (quill.current) {
              const quillRef = quill.current.getEditor();
              const range = quillRef.getSelection(true);
              quillRef.insertEmbed(
                range.index,
                "image",
                aiImageJson.url,
                "user"
              );
            }
          }
          handleModalClose();
          setSaveChange(true);
          dispatch(
            openNotification({
              message: "Image generated successfully",
              severity: "success",
            })
          );
        } else if (aiImageRes.status === 423) {
          const errorData = await aiImageRes.json();
          if (errorData.message.includes("FREE USER")) {
            setMessageRegenerationSubscribeModal(
              "Image generation using AI is only available for paid users. Please subscribe generate AI images."
            );
          } else {
            setMessageRegenerationSubscribeModal(
              "You have used up all your Image generation credits. Please wait till next month to renew subscription or contact support."
            );
          }
          setOpenRegenerationSubscribeModal(true);
        }
      } catch (err) {
        console.error("Error in generating AI image: " + JSON.stringify(err));
      }
      setOpenLoadingModal(false);
    }
  };

  const generateAIImageApi = async () => {
    try {
      const payload = {
        prompt: imagePrompt,
        topicId: topicId,
        projectId: projectId,
      };
      const response = await fetchBackendApiWrapper(
        "/api/v1/generateImage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        await auth.currentUser.getIdToken(false)
      );
      return response;
    } catch (err) {
      console.error("Error in generating AI image " + JSON.stringify(err));
    }
    return null;
  };

  // unsplash
  const [unsplashSearchQuery, setUnsplashSearchQuery] = useState("");
  const [unsplashSearchResults, setUnsplashSearchResults] = useState([]);
  const [page, setPage] = useState(1);

  const handleUnsplashSearch = async (pg) => {
    if (unsplashSearchQuery) {
      try {
        setPage(pg);
        setLoadingModalMessage("Searching Image. Please wait...");
        setOpenLoadingModal(true);
        const results = await searchUnsplashImageApi(unsplashSearchQuery, pg);
        if (results.ok) {
          const resultsJson = await results.json();
          setUnsplashSearchResults((prev) => [...prev, ...resultsJson]);
        }
      } catch (error) {
        console.error(error);
      }
      setOpenLoadingModal(false);
    }
  };

  const searchUnsplashImageApi = async (query, pg) => {
    try {
      const response = await fetchBackendApiWrapper(
        `/api/v1/unsplash/searchPhotos?search=${query}&page=${pg}`,
        {
          method: "GET",
        },
        await auth.currentUser.getIdToken(false)
      );
      return response;
    } catch (err) {
      console.error(
        "Error in fetching searchUnsplashImageApi " + JSON.stringify(err)
      );
    }
    return null;
  };

  const handleUnsplashSelectImageAction = (item) => {
    if (quill === null) {
      setHeroImage({ ...heroImage, url: item });
    } else {
      if (quill.current) {
        const quillRef = quill.current.getEditor();
        const range = quillRef.getSelection(true);
        quillRef.insertEmbed(range.index, "image", item, "user");
      }
    }
    handleModalClose();
    setSaveChange(true);
  };

  return (
    <>
      <Dialog
        open={openImageRegenerateModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth={"xl"}
        className="backdrop-blur-sm"
      >
        {currentView === 0 && (
          <div className="flex flex-col justify-start items-start w-full relative p-12">
            <div className="font-semibold">Choose Image</div>
            <div className="font-light text-slate-500">
              Choose from Unsplash, Upload or Create using AI
            </div>
            <div className="flex justify-between items-center gap-4 w-full relative mt-6">
              <div
                onClick={handleImageUploadAction}
                className="bg-orange-500 text-white gap-2 rounded-lg p-4 flex justify-center items-center w-full relative cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>

                <div>Upload Image</div>
              </div>
              <div
                onClick={() => setCurrentView(2)}
                className="bg-black text-white gap-2 rounded-lg p-4 flex justify-center items-center w-full relative cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>

                <div>Search Stock Images</div>
              </div>
              <div
                onClick={() => setCurrentView(3)}
                className="bg-green-500 text-white gap-2 rounded-lg p-4 flex justify-center items-center w-full relative cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>

                <div className="text-center">Create using AI</div>
              </div>
            </div>
          </div>
        )}
        {currentView === 3 && (
          <div className="flex flex-col justify-start items-start w-full relative p-12">
            <div className="font-semibold">Create with AI</div>
            <div className="font-light text-slate-500">
              Describe what image you want to generate
            </div>
            <div className="mt-4 flex justify-between items-center gap-4 w-full relative">
              <input
                value={imagePrompt}
                onChange={(event) => setImagePrompt(event.target.value)}
                placeholder="Write your imagination"
                className=" text-gray-900 text-sm rounded-lg border border-gray-300 block w-full p-2.5"
              />
              <button
                onClick={handleAIImageAction}
                className="bg-purple-300 p-2 rounded-lg flex justify-center items-center gap-1"
              >
                <AutoFixHighIcon />
                <div>Create</div>
              </button>
              <RegenerationSubscribeDialog
                openRegenerationSubscribeModal={openRegenerationSubscribeModal}
                setOpenRegenerationSubscribeModal={
                  setOpenRegenerationSubscribeModal
                }
                message={messageRegenerationSubscribeModal}
              />
            </div>
          </div>
        )}
        {currentView === 2 && (
          <div className="flex flex-col justify-start items-start w-full relative p-12">
            <div className="font-semibold">Select from Unsplash</div>
            <div className="mt-4 flex justify-between items-center gap-4 w-full relative">
              <input
                value={unsplashSearchQuery}
                onChange={(event) => setUnsplashSearchQuery(event.target.value)}
                placeholder="Search your image"
                className=" text-gray-900 text-sm rounded-lg border border-gray-300 block w-full p-2.5"
              />
              <button
                onClick={() => handleUnsplashSearch(1)}
                className="bg-purple-300 p-2 rounded-lg flex justify-center items-center gap-1"
              >
                <SearchIcon />
                <div>Search</div>
              </button>
            </div>
            {unsplashSearchResults !== null &&
              unsplashSearchResults.length > 0 && (
                <div className="flex flex-col justify-start items-center w-full">
                  <div
                    className={`grid xs:grid-cols-2 md:grid-cols-3 lg:grid-col-4 gap-4 w-full relative p-6`}
                  >
                    {unsplashSearchResults.map((item, id) => (
                      <div
                        className="cursor-pointer"
                        key={id}
                        onClick={() => handleUnsplashSelectImageAction(item)}
                      >
                        <img src={item} alt={"item.title"} loading="lazy" />
                      </div>
                    ))}
                  </div>
                  {page > 0 && (
                    <div
                      onClick={() => {
                        handleUnsplashSearch(page + 1);
                      }}
                      className="p-2 rounded-md font-medium bg-gray-200 border-gray-200 flex justify-center items-center text-center relative cursor-pointer"
                    >
                      Load More
                    </div>
                  )}
                </div>
              )}
            {unsplashSearchResults !== null &&
              unsplashSearchResults.length === 0 && (
                <div className="flex justify-center items-center pt-6 text-slate-300 relative w-full">
                  No images found
                </div>
              )}
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ImageRegenerateComponent;
