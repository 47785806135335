import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchBackendApiWrapper } from "../../../utils/apiWrapper";
import { CircularProgress } from "@mui/material";

const PurchaseComplete = () => {
  const [searchParam] = useSearchParams();
  const checkState = useRef(0); // 0 - start , 1 - inprogress, 2 - Complete, 3 - Payment Failed, 4 - Refresh something went wrong
  const navigate = useNavigate();
  const [status, setStatus] = useState(0); // 0 - start , 1 - inprogress, 2 - Complete, 3 - Payment Failed, 4 - Refresh something went wrong

  const handleOpenBrand = () => {
    localStorage.setItem("thbCurrentProject", searchParam.get("projectId"));
    navigate("/dashboard/keyword-selection", { replace: true });
  };

  useEffect(() => {
    if (JSON.parse(searchParam.get("success"))) {
      checkpayment();
      const timer = setInterval(async () => {
        console.log("Checking status " + checkState.current);
        checkpayment();
      }, 5000);
      return () => clearInterval(timer);
    }
  }, []);

  const checkpayment = async () => {
    if (checkState.current > 1) return;
    try {
      checkState.current = 1;
      setStatus(1);
      const responseData = await fetchBackendApiWrapper(
        `/api/v1/payment/checkPayment?projectId=${searchParam.get(
          "projectId"
        )}&referenceId=${searchParam.get("referenceId")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        null
      );
      if (responseData.ok) {
        const jsonData = await responseData.json();
        console.log("Purchase Status: " + JSON.stringify(jsonData));
        if (jsonData.value === "1") {
          checkState.current = 3; //fail
          setStatus(3);
        } else if (jsonData.value === "2") {
          checkState.current = 2; // done
          setStatus(2);
        } else if (jsonData.value === "3") {
          checkState.current = 4; //not found
          setStatus(4);
        }
      }
    } catch (err) {
      console.error(
        "Error in checking purchase state : " + JSON.stringify(err)
      );
      checkState.current = 5; //error
    }
  };

  return (
    <div className="flex flex-col justify-start items-center gap-10 w-full relative bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
      <div className="mt-10">
        <img
          src="/images/THB.svg"
          alt="TheHyperbrand Logo"
          width={140}
          height={32}
        />
      </div>
      <div className="bg-white flex flex-col  justify-evenly items-center relative rounded-xl shadow-md gap-8 p-12">
        {status === 1 && (
          <>
            <div>Please wait. Checking Payment Status</div>
            <div>
              <CircularProgress
                size="2rem"
                variant="indeterminate"
                sx={{ color: "gray" }}
              />
            </div>
          </>
        )}
        {status === 2 && (
          <>
            <div>Purchase completed successfully.</div>
            <div>
              <button
                onClick={handleOpenBrand}
                className="bg-blue-500 text-white px-6 py-2 rounded-md text-sm font-medium"
              >
                Open
              </button>
            </div>
          </>
        )}
        {status === 3 && (
          <>
            <div>Purchase was unsuccessful.</div>
            <div>
              <button
                onClick={() =>
                  navigate("/dashboard/settings/brand", { replace: true })
                }
                className="bg-blue-500 text-white px-6 py-2 rounded-md text-sm font-medium"
              >
                Home
              </button>
            </div>
          </>
        )}
        {status === 4 && (
          <>
            <div>Purchase Id is invalid.</div>
            <div>
              <button
                onClick={() =>
                  navigate("/dashboard/settings/brand", { replace: true })
                }
                className="bg-blue-500 text-white px-6 py-2 rounded-md text-sm font-medium"
              >
                Home
              </button>
            </div>
          </>
        )}
        {status === 5 && (
          <>
            <div>Something went wrong. Please try refreshing the page.</div>
            <div>
              <button
                onClick={() => window.location.reload(true)}
                className="bg-blue-500 text-white px-6 py-2 rounded-md text-sm font-medium"
              >
                Refresh
              </button>
            </div>
          </>
        )}
        {!JSON.parse(searchParam.get("success")) && (
          <>
            <div>Purchase was unsuccessful.</div>
            <div>
              <button
                onClick={() =>
                  navigate("/dashboard/settings/brand", { replace: true })
                }
                className="bg-blue-500 text-white px-6 py-2 rounded-md text-sm font-medium"
              >
                Home
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PurchaseComplete;
