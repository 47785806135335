import { auth } from "../auth/firebaseConfig";
import { fetchBackendApiWrapper } from "./apiWrapper";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getHTMLBlog = (blog) => {
  if (blog !== null && blog !== undefined) {
    let tableOfContents = "<h2>Table Of Contents</h2><br><ol>";
    if (blog.tldr.length > 0) {
      tableOfContents += "<li>Summary</li>";
    }
    if (blog.introduction.length > 0) {
      tableOfContents += "<li>Introduction</li>";
    }
    if (blog.body.length > 0) {
      const tmp = blog.body[0].section.substring(
        blog.body[0].section.indexOf("<h2>") + 4,
        blog.body[0].section.indexOf("</h2>")
      );
      tableOfContents += `<li>${tmp}</li>`;
    }
    if (blog.body.length > 1) {
      const tmp = blog.body[1].section.substring(
        blog.body[1].section.indexOf("<h2>") + 4,
        blog.body[1].section.indexOf("</h2>")
      );
      tableOfContents += `<li>${tmp}</li>`;
    }
    if (blog.body.length > 2) {
      const tmp = blog.body[2].section.substring(
        blog.body[2].section.indexOf("<h2>") + 4,
        blog.body[2].section.indexOf("</h2>")
      );
      tableOfContents += `<li>${tmp}</li>`;
    }
    if (blog.body.length > 3) {
      const tmp = blog.body[3].section.substring(
        blog.body[3].section.indexOf("<h2>") + 4,
        blog.body[3].section.indexOf("</h2>")
      );
      tableOfContents += `<li>${tmp}</li>`;
    }
    if (blog.body.length > 4) {
      const tmp = blog.body[4].section.substring(
        blog.body[4].section.indexOf("<h2>") + 4,
        blog.body[4].section.indexOf("</h2>")
      );
      tableOfContents += `<li>${tmp}</li>`;
    }
    if (blog.body.length > 5) {
      const tmp = blog.body[5].section.substring(
        blog.body[5].section.indexOf("<h2>") + 4,
        blog.body[5].section.indexOf("</h2>")
      );
      tableOfContents += `<li>${tmp}</li>`;
    }
    if (blog.conclusion.length > 0) {
      tableOfContents += `<li>Conclusion</li>`;
    }
    if (blog.faq.length > 0) {
      tableOfContents += `<li>FAQ's</li>`;
    }
    tableOfContents += "</ol>";

    let full = "";
    full += blog.topic + "<br><br>";
    full += tableOfContents + "<br><br>";
    if (blog.heroImage !== null && blog.heroImage !== undefined) {
      full += `<img src=${blog.heroImage.url} altText=${blog.heroImage.altText} /><br><br>`;
    }
    full += blog.tldr + "<br><br>";
    full += blog.introduction + "<br><br>";
    if (blog.body.length > 0) {
      full += blog.body[0].section + "<br><br>";
    }
    if (blog.body.length > 1) {
      full += blog.body[1].section + "<br><br>";
    }
    if (blog.body.length > 2) {
      full += blog.body[2].section + "<br><br>";
    }
    if (blog.body.length > 3) {
      full += blog.body[3].section + "<br><br>";
    }
    if (blog.body.length > 4) {
      full += blog.body[4].section + "<br><br>";
    }
    if (blog.body.length > 5) {
      full += blog.body[5].section + "<br><br>";
    }
    full += blog.conclusion + "<br><br>";
    full += blog.faq + "<br><br>";
    full = full.replace(new RegExp("<strong>", "g"), "");
    full = full.replace(new RegExp("</strong>", "g"), "");
    return full;
  }
  return "";
};

export const getTextLength = (htmlString) => {
  // Remove all HTML tags using a regular expression
  const textOnly = htmlString.replace(/<[^>]*>/g, "");

  // Return the length of the text-only string
  return textOnly.length;
};

export const getWordCount = (str) => {
  return str.trim().split(/\s+/).length;
}

export const calculateReadingTime = (wordCount) => {
  const wordsPerMinute = 200; // Average reading speed
  const minutes = wordCount / wordsPerMinute;
  // Round up to the nearest minute
  return Math.ceil(minutes);
};

export const removeAllImageTags = (htmlString) => {
  const imgTagRegex = /<img[^>]*>/gi;

  // Find all <img> tags and store them in an array
  const imageTags = htmlString.match(imgTagRegex) || [];

  // Remove all <img> tags from the original string
  const modifiedHtmlString = htmlString.replace(imgTagRegex, "");
  return {
    updatedSection: modifiedHtmlString,
    imageData: imageTags,
  };
};

export const replaceBase64WithUploadedUrl = async (
  htmlString,
  topicId,
  authToken
) => {
  // Create a temporary DOM element
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  
  // Find all image tags
  const images = Array.from(tempDiv.querySelectorAll("img"));

  // eslint-disable-next-line array-callback-return
  const uploadPromises = images.map((img) => {
    const src = img.getAttribute("src");
    if (src.startsWith("data:image")) {
      // console.log("Images: " + src);
      return uploadBase64Image(src, topicId, authToken).then(async (res) => {
        img.setAttribute("src", res);
      });
    }
  });
  await Promise.all(uploadPromises);
  return tempDiv.innerHTML;
};

export const uploadBase64Image = async (base64Image, topicId) => {
  try {
  const payload = {
  base64Image: base64Image,
  topicId: topicId,
  };
  const response = await fetchBackendApiWrapper(
  "/api/v1/uploadBase64Image",
  {
        method: "POST",
  headers: {
  "Content-Type": "application/json",
  },
  body: JSON.stringify(payload),
  },
  await auth.currentUser.getIdToken(false)
  );
  if (response.ok) {
  const tmp = await response.json();
  return tmp.value;
  }
    return response;
  } catch (err) {
  console.error("Error in uploading image " + JSON.stringify(err));
  throw err;
  }
};

export const isValidUrl = (link, checkProtocol) => {
  try {
    new URL(checkProtocol ? link : "https://" + link);
    return true;
  } catch (_) {
    return false;
  }
};

export const formatDifficulty = (num) => {
  if (num === null) return;
  return (
    <div
      className={`text-sm px-2 py-1 rounded-md ${
        num < 20 ? "bg-green-100 text-green-600" : num < 50 ? "bg-yellow-100 text-yellow-600" : "bg-orange-100 text-orange-600"
      } text-center font-medium`}
    >
      {num}
    </div>
  );
};

export const formatNumber = (number) => {
  if (number === null) return;
  if (number < 1000) {
    return number.toString(); // Return the number as is if it's less than 1000
  } else if (number < 1000000) {
    return (number / 1000).toFixed(0) + "K"; // Convert to thousands
  } else if (number < 1000000000) {
    return (number / 1000000).toFixed(0) + "M"; // Convert to millions
  } else {
    return (number / 1000000000).toFixed(0) + "B"; // Convert to billions
  }
};

export function htmlToPlainTextWithLineBreaks(htmlString) {
  const doc = new DOMParser().parseFromString(htmlString, "text/html");

  const getTextContentWithLineBreaks = (element, depth = 0) => {
    let text = "";

    for (let i = 0; i < element.childNodes.length; i++) {
      const child = element.childNodes[i];

      if (child.nodeType === 3) {
        // Text node
        text += child.nodeValue;
      } else if (child.nodeType === 1) {
        // Element node
        text += getTextContentWithLineBreaks(child, depth + 1);
      }

      if (i < element.childNodes.length - 1 && text.trim() !== "") {
        text += "\n";
      }
    }

    return text;
  };

  return getTextContentWithLineBreaks(doc.body);
}
