import { Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";

const RegenerationSubscribeDialog = ({
  openRegenerationSubscribeModal,
  setOpenRegenerationSubscribeModal,
  message,
}) => {
  const navigate = useNavigate();
  const handleModalClose = () => {
    setOpenRegenerationSubscribeModal(false);
  };
  return (
    <Dialog
      open={openRegenerationSubscribeModal}
      onClose={handleModalClose}
      fullWidth
      className="backdrop-blur-sm"
    >
      <div className="flex flex-col justify-start items-center w-full relative p-16 gap-10">
        <div className="text-center">{message}</div>
        <div>
          {message.includes("Subscribe") ? (
            <button
              onClick={() => navigate("/dashboard/settings/subscription")}
              className="bg-orange-400 text-white p-4 rounded-md font-semibold"
            >
              Subscribe
            </button>
          ) : (
            <button
              onClick={() => setOpenRegenerationSubscribeModal(false)}
              className="bg-blue-500 text-white p-4 rounded-md font-semibold"
            >
              Ok, I will wait
            </button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default RegenerationSubscribeDialog;
