import BoltIcon from "@mui/icons-material/Bolt";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Drawer } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TagRoundedIcon from "@mui/icons-material/TagRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";

const SideBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mobileOpen, handleDrawerToggle } = props;
  const projects = useSelector((state) => state.projects);

  const drawer = (
    <div className="flex flex-col justify-between items-start bg-white min-h-screen w-full relative">
      <div
        className={`flex flex-col justify-start items-start gap-4 w-full relative`}
      >
        <div className="pt-4 pl-4 pb-6">
          <a href={`${window.location.origin}/dashboard/keyword-selection`}>
            <img
              src="/images/THB.svg"
              alt="TheHyperbrand Logo"
              width={140}
              height={32}
            />
          </a>
        </div>
        {/* <div
          className={` w-full ${
            location.pathname === "/dashboard/getting-started"
              ? "bg-blue-100 border-l-4 border-thbBlue"
              : "bg-white"
          }`}
        >
          <Link
            to={"/dashboard/getting-started"}
            className="flex justify-start items-center gap-2 px-4 py-3 cursor-pointer"
          >
            <BoltIcon
              className={`${
                location.pathname === "/dashboard/getting-started"
                  ? "text-thbBlue"
                  : "text-gray-500"
              }`}
            />
            <div
              className={`${
                location.pathname === "/dashboard/getting-started"
                  ? "text-thbBlue"
                  : "text-gray-500"
              } font-medium text-sm`}
            >
              Business Setup
            </div>
          </Link>
        </div> */}
        <div
          className={`w-full ${
            location.pathname === "/dashboard/keyword-selection"
              ? "bg-blue-100 border-l-4 border-thbBlue"
              : "bg-white"
          }`}
        >
          <Link
            to={"/dashboard/keyword-selection"}
            className="flex justify-start items-center gap-2 px-4 py-3 cursor-pointer"
          >
            <FormatListBulletedOutlinedIcon
              className={`${
                location.pathname === "/dashboard/keyword-selection"
                  ? "text-thbBlue"
                  : "text-gray-500"
              }`}
            />
            <div
              className={`${
                location.pathname === "/dashboard/keyword-selection"
                  ? "text-thbBlue"
                  : "text-gray-500"
              } font-medium text-sm`}
            >
              Keyword Selection
            </div>
          </Link>
        </div>
        <div
          className={`w-full ${
            location.pathname === "/dashboard/topic-selection"
              ? "bg-blue-100 border-l-4 border-thbBlue"
              : "bg-white"
          }`}
        >
          <Link
            to={"/dashboard/topic-selection"}
            className="flex justify-start items-center gap-2 px-4 py-3 cursor-pointer"
          >
            <HistoryEduOutlinedIcon
              className={`${
                location.pathname === "/dashboard/topic-selection"
                  ? "text-thbBlue"
                  : "text-gray-500"
              }`}
            />
            <div
              className={`${
                location.pathname === "/dashboard/topic-selection"
                  ? "text-thbBlue"
                  : "text-gray-500"
              } font-medium text-sm`}
            >
              Blogs
            </div>
          </Link>
        </div>
        <div
          className={`w-full ${
            location.pathname.includes("/dashboard/analytics")
              ? "bg-blue-100 border-l-4 border-thbBlue"
              : "bg-white"
          }`}
        >
          <Link
            to={"/dashboard/analytics"}
            className="flex justify-start items-center gap-2 px-4 py-3 cursor-pointer "
          >
            <InsightsRoundedIcon
              className={`${
                location.pathname.includes("/dashboard/analytics")
                  ? "text-thbBlue"
                  : "text-gray-500"
              }`}
            />
            <div
              className={`${
                location.pathname.includes("/dashboard/analytics")
                  ? "text-thbBlue"
                  : "text-gray-500"
              } font-medium text-sm`}
            >
              Analytics
            </div>
          </Link>
        </div>
        <div
          className={`w-full ${
            location.pathname.includes("/dashboard/integration")
              ? "bg-blue-100 border-l-4 border-thbBlue"
              : "bg-white"
          }`}
        >
          <Link
            to={"/dashboard/integration"}
            className="flex justify-start items-center gap-2 px-4 py-3 cursor-pointer "
          >
            <TagRoundedIcon
              className={`${
                location.pathname.includes("/dashboard/integration")
                  ? "text-thbBlue"
                  : "text-gray-500"
              }`}
            />
            <div
              className={`${
                location.pathname.includes("/dashboard/integration")
                  ? "text-thbBlue"
                  : "text-gray-500"
              } font-medium text-sm`}
            >
              Integrations
            </div>
          </Link>
        </div>
        <div
          className={`w-full ${
            location.pathname.includes("/dashboard/settings")
              ? "bg-blue-100 border-l-4 border-thbBlue"
              : "bg-white"
          }`}
        >
          <Link
            to={"/dashboard/settings/account"}
            className="flex justify-start items-center gap-2 px-4 py-3 cursor-pointer"
          >
            <SettingsIcon
              className={`${
                location.pathname.includes("/dashboard/settings")
                  ? "text-thbBlue"
                  : "text-gray-500"
              }`}
            />
            <div
              className={`${
                location.pathname.includes("/dashboard/settings")
                  ? "text-thbBlue"
                  : "text-gray-500"
              } font-medium text-sm`}
            >
              Settings
            </div>
          </Link>
        </div>
      </div>
      {projects.currentProject &&
        !projects.currentProject.isActiveSubscription && (
          <div className="flex flex-col justify-start items-start gap-2 relative bg-gray-100 mx-4 mb-2 p-4 rounded-md">
            <div className="font-semibold">Free Trial</div>
            <div className="text-xs font-medium">
              Subscribe for your complete SEO strategy.
            </div>
            <div className="flex justify-stretch items-center w-full relative">
              <Link to={"/dashboard/settings/subscription"}>
                <div
                  className="bg-thbBlue text-white rounded-md p-2 w-full text-sm font-medium text-center"
                >
                  Subscribe
                </div>
              </Link>
            </div>
          </div>
        )}
    </div>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        <div
          className={`flex flex-col justify-start items-start bg-white h-screen gap-4`}
        >
          {drawer}
        </div>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
        open
      >
        {/* <div
          className={`flex flex-col justify-start items-start bg-white h-screen gap-4`}
        >
          {drawer}
        </div> */}
        {drawer}
      </Drawer>
    </>
  );
};

SideBar.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default SideBar;
