import { IconButton } from "@mui/material";
import SideBar from "../../../components/SideBar";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/Language";
import AddIcon from "@mui/icons-material/Add";
import { setCurrentProject } from "../../../slice/projectsDataSlice";
import { openNotification } from "../../../slice/notificationSlice";
import ReactGA from "react-ga4";

const BrandSettings = () => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const projects = useSelector((state) => state.projects);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAddBrand = () => {
    ReactGA.event({
      category: "Click",
      action: "Brands - Add",
      label: "User clicks on Brands - Add",
    });
    navigate("/dashboard/addBrand");
  };

  useEffect(() => {
    ReactGA.event({
      category: "Page Load",
      action: "Settings Brands - Load",
      location: window.location.pathname,
      label: "Settings Brands - Load",
    });
  }, []);

  const handleSwitchBrand = (project) => {
    localStorage.setItem("thbCurrentProject", project.id);
    dispatch(setCurrentProject(project));
    dispatch(openNotification({message: 'Brand switched successfully', severity: 'success'}));
  };

  return (
    <div
      className="flex justify-start items-start w-full min-h-screen relative"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className="py-8 px-4 flex flex-col justify-start items-center w-full sm:ml-64">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-4">
            <div className="sm:hidden">
              <IconButton sx={{ color: "gray" }} onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className="text-lg font-semibold">Settings</div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-stretch w-full bg-white shadow-lg rounded-lg mt-4 relative">
          <div className="flex justify-start items-center w-full relative border-b border-gray-200 overflow-x-auto">
            <div className="flex justify-start items-center gap-4 pl-10">
              <div
                onClick={() => navigate("/dashboard/settings/account")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Account
              </div>
              <div
                className={`px-6 py-2 text-sm font-medium cursor-pointer  border-b-4 border-blue-400`}
              >
                Brands
              </div>
              <div
                onClick={() => navigate("/dashboard/settings/subscription")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Subscription
              </div>
              <div
                onClick={() => navigate("/dashboard/settings/plans")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Plans and Packages
              </div>
              <div
                onClick={() => navigate("/dashboard/settings/support")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Support
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start w-full relative gap-8 px-10 py-8">
            <div className="flex justify-between items-center w-full relative">
              <div>Your Brands</div>
              <button
                onClick={handleAddBrand}
                className="bg-blue-500 text-white rounded-md p-2"
              >
                <div className="flex justify-center items-center gap-1">
                  <div className="text-sm font-medium">Add Brand</div>
                  <AddIcon className="text-white" sx={{ fontSize: "1rem" }} />
                </div>
              </button>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-8 justify-start items-start w-full relative">
              {projects.projectsData &&
                projects.projectsData.map((prj) => {
                  return (
                    <div
                      key={prj.id}
                      className="col-span-1 px-6 py-4 rounded-md shadow-lg flex flex-col justify-start items-start w-full relative gap-4"
                      style={{
                        background:
                          "linear-gradient(142.74deg, #ACE0F9 -39.63%, #FFF1EB 89.64%)",
                      }}
                    >
                      <div className="text-sm font-medium">
                        <LanguageIcon /> {prj.projectWebsite}
                      </div>
                      <div className="text-sm">
                        {"Industry: " + prj.industry}
                      </div>
                      <div className="text-sm">
                        {prj.isActiveSubscription
                          ? "Subscription Active: Yes"
                          : "Subscription Active: No"}
                      </div>
                      <div className="text-sm">
                        {"Target Country: " + prj.country}
                      </div>
                      <div className="text-sm font-medium">
                        {"Subscription Type: " +
                          (prj.subscriptionType === 0
                            ? "FREE"
                            : "Professional")}
                      </div>
                      {prj.id === projects.currentProject.id && (
                        <div className="bg-green-500 text-white p-1 rounded-md text-sm font-medium">
                          Current
                        </div>
                      )}
                      {prj.id !== projects.currentProject.id && (
                        <div
                          onClick={() => handleSwitchBrand(prj)}
                          className="bg-black text-white p-1 rounded-md text-sm font-medium cursor-pointer"
                        >
                          Switch
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandSettings;
