import { CircularProgress, Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../../auth/firebaseConfig";
import { fetchBackendApiWrapper } from "../../../utils/apiWrapper";
import { countries, industries } from "../../../utils/constants";
import AddBrandPricing from "./AddBrandPricing";
import { openNotification } from "../../../slice/notificationSlice";

const AddBrandPage = () => {
  const dispatch = useDispatch();
  const [projectWebsiteUrl, setProjectWebsiteUrl] = useState("");
  const [isValidProjectUrl, setIsValidProjectUrl] = useState(false);
  const [industry, setIndustry] = useState("");
  const [country, setCountry] = useState("");
  const [processing, setProcessing] = useState(false);
  const [curView, setCurView] = useState(0);
  const [plans, setPlans] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isSelectedPlanMonthly, setIsSelectedPlanMonthly] = useState(false);
  const [purchaseLoader, setPurchaseLoader] = useState(false);
  // const authData = useSelector((state) => state.authStore);
  const [monthly, setMonthly] = useState(true);

  const handleSubscriptionView = () => {
    if (!isFormFilled()) return;
    if (!plans || plans.length === 0) {
      initPricing();
    }
    setCurView(1);
  };

  const handleAddBrand = () => {
    if (purchaseLoader) return;
    setPurchaseLoader(true);
    saveProjectDetails().then(async (project) => {
      if (project) {
        await handleProceedPurchase(project);
      } else {
        dispatch(
          openNotification({
            message:
              "Something went wrong. Please refresh the page and try again",
            severity: "error",
          })
        );
      }
      setPurchaseLoader(false);
    });
  };

  const handleProceedPurchase = async (project) => {
    try {
      const payload = {
        projectId: project.id,
        isAnnual: !isSelectedPlanMonthly,
        subscriptionPlanId: selectedPlan.id,
        isAddProjectRequest: true,
        projectWebsite: projectWebsiteUrl.startsWith("https://")
          ? projectWebsiteUrl
          : "https://" + projectWebsiteUrl,
      };
      const checkoutSessionRes = await fetchBackendApiWrapper(
        "/api/v1/payment/razorpayCheckout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        await auth.currentUser.getIdToken(false)
      );
      if (checkoutSessionRes && checkoutSessionRes.ok) {
        const sessionUrl = await checkoutSessionRes.json();
        console.log("Session URL: " + sessionUrl.value);
        window.open(sessionUrl.value, "_self");
      } else {
        console.error(
          "Error in creating checkout session: " +
            JSON.stringify(checkoutSessionRes.status)
        );
        dispatch(
          openNotification({
            message:
              "Error in purchasing plan. Please refresh the page and try again.",
            severity: "error",
          })
        );
      }
    } catch (err) {
      console.error(
        "Error in creating checkout session: " + JSON.stringify(err)
      );
      dispatch(
        openNotification({
          message:
            "Error in purchasing plan. Please refresh the page and try again.",
          severity: "error",
        })
      );
    }
  };

  const initPricing = async () => {
    try {
      const res = await fetchBackendApiWrapper(
        "/api/v1/plans",
        {
          method: "GET",
        },
        null
      );
      if (res && res.ok) {
        const data = await res.json();
        // console.log("Successfully got plans: " + JSON.stringify(data));
        const tmp = data.filter(pl => pl.active);
        setPlans(tmp);
      }
    } catch (err) {
      console.error("Error in fetching plans: " + JSON.stringify(err));
    }
  };

  const saveProjectDetails = async () => {
    try {
      const payload = {
        projectWebsite: projectWebsiteUrl.startsWith("https://")
          ? projectWebsiteUrl
          : "https://" + projectWebsiteUrl,
        industry: industry,
        country: country,
        isActive: false,
      };
      const authToken = await auth.currentUser.getIdToken(false);
      const saveData = await fetchBackendApiWrapper(
        "/api/v1/project/addAdditionalProject",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        authToken
      );
      if (saveData.ok) {
        const savedProject = await saveData.json();
        return savedProject;
      }
      return null;
    } catch (err) {
      console.error("Error in adding new project " + JSON.stringify(err));
      return null;
    }
  };

  // const updateProjectWebisteUrl = (event) => {
  //   setProjectWebsiteUrl(event.target.value);
  //   try {
  //     if (
  //       event.target.value.startsWith("http://") ||
  //       event.target.value.startsWith("https://")
  //     ) {
  //       throw Error();
  //     }
  //     new URL("https://" + event.target.value);
  //     setIsValidProjectUrl(true);
  //   } catch (error) {
  //     setIsValidProjectUrl(false);
  //   }
  //   console.log("Valid: " + isValidProjectUrl);
  // };
  const updateProjectWebisteUrl = (event) => {
    setProjectWebsiteUrl(event.target.value);
    try {
      let urlTmp = event.target.value;
      if (!urlTmp.startsWith("https://")) {
        urlTmp = "https://" + urlTmp;
      }
      new URL(urlTmp);
      setIsValidProjectUrl(true);
    } catch (error) {
      setIsValidProjectUrl(false);
    }
    console.log("Valid: " + isValidProjectUrl);
  };

  const isFormFilled = () => {
    return isValidProjectUrl && industry.length > 0 && country.length > 0;
  };

  const steps = ["Add Brand Detail", "Select Plan", "Review and Submit"];

  return (
    <>
      {
        <main className="bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
          <div className="text-center w-full relative font-montserrat text-lg font-semibold m-10">
            Add New Brand
          </div>
          <div className="flex relative flex-row justify-center items-center w-full">
            <div className="flex relative flex-col justify-center items-center w-full gap-8">
              <div className="bg-white flex flex-col  justify-evenly items-center relative rounded-xl shadow-md gap-8">
                <div className="font-montserrat text-lg font-medium pt-10 w-full relative">
                  <Stepper activeStep={curView} alternativeLabel>
                    {steps.map((label, ind) => (
                      <Step
                        onClick={() => {
                          if (ind > curView) {
                            return;
                          }
                          setCurView(ind);
                        }}
                        key={label}
                        sx={{ cursor: "pointer" }}
                      >
                        <StepLabel>
                          <div className="font-montserrat">{label}</div>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                {curView === 0 && (
                  <>
                    <div className="flex flex-row justify-evenly items-start p-12 gap-32">
                      <div className="flex flex-col justify-start items-start gap-4">
                        <label className="text-sm font-light font-montserrat">
                          <div>Your Website URL</div>
                          {/* <div className="text-xs text-gray-600">
                            (Add full valid website URL with protocols like
                            https)
                          </div> */}
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            placeholder=""
                            className={`bg-gray-50 border ${
                              isValidProjectUrl
                                ? "border-gray-300"
                                : "border-red-400"
                            } text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                            required
                            value={projectWebsiteUrl}
                            onChange={updateProjectWebisteUrl}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col justify-start items-start gap-4">
                        <label className="text-sm font-light font-montserrat">
                          Industry
                        </label>
                        <div className="relative">
                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
                            value={industry}
                            onChange={(event) =>
                              setIndustry(event.target.value)
                            }
                          >
                            <option value=""></option>
                            {industries.map((c) => (
                              <option key={c} value={c}>
                                {c}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="flex flex-col justify-start items-start gap-4">
                        <label className="text-sm font-light font-montserrat">
                          Target Country
                        </label>
                        <div className="relative">
                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            value={country}
                            onChange={(event) => setCountry(event.target.value)}
                          >
                            <option value=""></option>
                            {countries.map((c) => (
                              <option key={c} value={c}>
                                {c}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="text-sm text-gray-500 text-center">
                      *Please review your brand details, your website won’t be
                      editable afterwards.
                    </div>
                    <button
                      onClick={handleSubscriptionView}
                      className={`${
                        isFormFilled()
                          ? "bg-blue-500 cursor-pointer"
                          : "bg-gray-400 cursor-not-allowed"
                      } px-4 py-2 rounded-md text-base text-white font-light font-montserrat`}
                    >
                      {processing ? (
                        <CircularProgress
                          size="1rem"
                          variant="indeterminate"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                    <div
                      className={`pb-4 text-sm text-blue-500 font-regular font-montserrat ${
                        isFormFilled()
                          ? "cursor-pointer text-blue-500"
                          : "cursor-not-allowed text-gray-400"
                      }`}
                    ></div>
                  </>
                )}
                {curView === 1 && (
                  <>
                    {/* <div className="mt-4 flex justify-center items-center gap-0">
                      <button
                        onClick={() => setMonthly(true)}
                        className={`text-gray-800 font-bold py-2 px-4 rounded-l-full ${
                          monthly
                            ? "bg-thbBlue text-white"
                            : "bg-gray-200 text-black"
                        }`}
                      >
                        Monthly
                      </button>
                      <button
                        onClick={() => setMonthly(false)}
                        className={`font-bold py-2 px-4 rounded-r-full ${
                          monthly === false
                            ? "bg-thbBlue text-white"
                            : "bg-gray-200 text-black"
                        }`}
                      >
                        Annually
                      </button>
                    </div> */}
                    <div className="flex flex-row justify-evenly items-start px-12 gap-10">
                      {plans &&
                        plans.length > 0 &&
                        plans.map((p) => {
                          return (
                            <div
                              key={p.id}
                              className="flex justify-start items-center w-full relative"
                            >
                              <AddBrandPricing
                                pricing={p}
                                setCurView={setCurView}
                                setIsSelectedPlanMonthly={
                                  setIsSelectedPlanMonthly
                                }
                                setSelectedPlan={setSelectedPlan}
                                monthly={monthly}
                                setMonthly={setMonthly}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
                {curView === 2 && (
                  <div className="flex flex-col justify-start items-start w-full relative p-12 gap-6">
                    <div className="flex flex-col justify-start items-start gap-6">
                      <div className="font-semibold">Review Details</div>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-4">
                      <div className="px-4 py-2 bg-gray-200 rounded-md">
                        Basic Details
                      </div>
                      <div className="flex flex-col justify-start items-start gap-4">
                        <div className="flex flex-row justify-start items-start gap-32">
                          <div className="flex flex-col justify-start items-start gap-4">
                            <label className="text-sm font-light font-montserrat">
                              <div>Your Website URL</div>
                            </label>
                            <div className="relative">
                              <input
                                type="text"
                                placeholder=""
                                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-not-allowed`}
                                disabled
                                value={projectWebsiteUrl}
                                onChange={updateProjectWebisteUrl}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col justify-start items-start gap-4">
                            <label className="text-sm font-light font-montserrat">
                              <div>Industry</div>
                            </label>
                            <div className="relative">
                              <input
                                type="text"
                                placeholder=""
                                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-not-allowed`}
                                disabled
                                value={industry}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col justify-start items-start gap-4">
                            <label className="text-sm font-light font-montserrat">
                              <div>Target Country</div>
                            </label>
                            <div className="relative">
                              <input
                                type="text"
                                placeholder=""
                                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-not-allowed`}
                                disabled
                                value={country}
                              />
                            </div>
                          </div>
                        </div>
                        <hr className="bg-gray-200 w-full relative my-6" />
                        <div className="flex flex-col justify-start items-start gap-4">
                          <div className="px-4 py-2 bg-gray-200 rounded-md mt-4">
                            Subscription Plan Details
                          </div>
                          <div className="text-sm font-medium">
                            {isSelectedPlanMonthly
                              ? `${selectedPlan.planName} Monthly Plan`
                              : `${selectedPlan.planName} Annual Plan`}
                          </div>
                          <div className="text-sm font-medium">
                            {isSelectedPlanMonthly
                              ? "Total Amount: ₹ " + selectedPlan.monthlyPrice
                              : "Total Amount: ₹ " + selectedPlan.annualPrice}
                          </div>
                        </div>

                        <div className="mt-4 w-full relative">
                          <button
                            onClick={handleAddBrand}
                            className="bg-blue-500 font-medium text-white px-6 py-2 rounded-md"
                          >
                            {purchaseLoader ? (
                              <div className="flex justify-center items-center gap-2">
                                <CircularProgress
                                  size="1.2rem"
                                  variant="indeterminate"
                                  sx={{ color: "white" }}
                                />
                                <div>Submit and Add Brand</div>
                              </div>
                            ) : (
                              "Submit and Add Brand"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      }
    </>
  );
};

export default AddBrandPage;
