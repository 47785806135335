import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { auth } from "../../auth/firebaseConfig";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [searchParam] = useSearchParams();
  const [error, setError] = useState();
  useEffect(() => {
    // const email = localStorage.getItem("emailForSignIn");
    const email = searchParam.get("eId");
    if (isSignInWithEmailLink(auth, window.location.href)) {
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          setVerified(true);
          localStorage.removeItem("emailForSignIn");
        })
        .catch(() => setError(true));
    }
  }, []);

  return (
    <>
      {!error && verified && (
        <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
          <div className="mt-8">
            <a href="https://thehyperbrand.com/">
              <img
                src="/images/THB.svg"
                alt="TheHyperbrand Logo"
                width={140}
                height={32}
              />
            </a>
          </div>
          <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20 gap-20">
            <div className="font-semibold">Email Verified</div>
            <button
              onClick={() =>
                navigate("/dashboard/keyword-selection", { replace: "true" })
              }
              className="bg-thbBlue px-6 py-2 font-semibold rounded-md text-white"
            >
              Go Home
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
          <div className="mt-8">
            <a href="https://thehyperbrand.com/">
              <img
                src="/images/THB.svg"
                alt="TheHyperbrand Logo"
                width={140}
                height={32}
              />
            </a>
          </div>
          <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
            <div className="font-medium">
              Something went wrong please try again
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmailPage;
