import { useEffect, useState } from "react";
import SideBar from "../../../components/SideBar";
import { CircularProgress, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBackendApiWrapper } from "../../../utils/apiWrapper";
import { openNotification } from "../../../slice/notificationSlice";
import { setUserDetail } from "../../../slice/userDataSlice";
import { signOut } from "firebase/auth";
import { auth } from "../../../auth/firebaseConfig";
import ReactGA from "react-ga4";

const AccountSettings = () => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const userData = useSelector((state) => state.userDetailStore);
  // const authData = useSelector((state) => state.authStore);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (userData.userDetail) {
      ReactGA.event({
        category: "Page Load",
        action: "Settings Account - Load",
        location: window.location.pathname,
        label: "Settings Account - Load",
      });
      setName(userData.userDetail.name);
      setEmail(userData.userDetail.emailId);
      setMobileNumber(
        userData.userDetail.mobileNumber ? userData.userDetail.mobileNumber : ""
      );
    }
  }, [userData]);

  const handleUpdateUserDetail = async () => {
    if (saveLoader) return;
    ReactGA.event({
      category: "Click",
      action: "Account - Save",
      label: "User clicks on Account - Save",
    });
    setSaveLoader(true);
    try {
      const payload = {
        name: name,
        mobileNumber: mobileNumber,
      };
      const res = await fetchBackendApiWrapper(
        "/api/v1/updateUserDetail",
        {
          method: "PUT",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (res && res.ok) {
        console.log("Updated user details successfully.");
        let tmp = { ...userData.userDetail };
        tmp.name = name;
        tmp.mobileNumber = mobileNumber;
        dispatch(setUserDetail(tmp));
        dispatch(
          openNotification({
            message: "Account details updated successfully.",
            severity: "success",
          })
        );
      }
    } catch (err) {
      console.error("Error in updating user details.");
      dispatch(
        openNotification({
          message: "Error in updating account details.",
          severity: "error",
        })
      );
    }
    setSaveLoader(false);
  };

  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful");
        dispatch({ type: "USER_LOGOUT" });
        dispatch(
          openNotification({
            message: "Successfully logged out",
            severity: "success",
          })
        );
        navigate("/login", { replace: "true" });
      })
      .catch((error) => {
        console.error("Sign-out error: " + JSON.stringify(error));
      });
  };

  return (
    <div
      className="flex justify-start items-start w-full min-h-screen relative"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className="py-8 px-4 flex flex-col justify-start items-center w-full sm:ml-64">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-4">
            <div className="sm:hidden">
              <IconButton sx={{ color: "gray" }} onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className="text-lg font-semibold">Settings</div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-stretch w-full bg-white shadow-lg rounded-lg mt-4 relative">
          <div className="flex justify-start items-center w-full relative border-b border-gray-200 overflow-x-auto">
            <div className="flex justify-start items-center gap-4 pl-10">
              <div
                className={`px-6 py-2 text-sm font-medium cursor-pointer border-b-4 border-blue-400`}
              >
                Account
              </div>
              {/* <div
                onClick={() => navigate("/dashboard/settings/brand")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Brands
              </div> */}
              <div
                onClick={() => navigate("/dashboard/settings/subscription")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Subscription
              </div>
              <div
                onClick={() => navigate("/dashboard/settings/plans")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Plans and Packages
              </div>
              <div
                onClick={() => navigate("/dashboard/settings/support")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Support
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start w-full relative gap-8 px-10 py-8">
            <div className="flex flex-col justify-start items-start relative gap-2">
              <div className="text-sm">Full Name</div>
              <input
                type="text"
                placeholder=""
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                required
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className="flex flex-col justify-start items-start relative gap-2">
              <div className="text-sm">Email Address</div>
              <input
                type="text"
                placeholder=""
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-not-allowed`}
                required
                value={email}
                disabled
              />
            </div>
            <div className="flex flex-col justify-start items-start relative gap-2">
              <div className="text-sm">Mobile Number</div>
              <input
                type="text"
                placeholder=""
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                required
                value={mobileNumber}
                onChange={(event) => setMobileNumber(event.target.value)}
              />
            </div>
            <div>
              <button
                onClick={handleUpdateUserDetail}
                className="px-4 py-2 rounded-md text-white font-medium bg-blue-500"
              >
                {saveLoader ? (
                  <CircularProgress
                    size="1rem"
                    variant="indeterminate"
                    sx={{ color: "white" }}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
            <button
              onClick={handleSignout}
              className="bg-black text-white p-2 rounded-lg font-medium"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
