import { sendSignInLinkToEmail, signOut } from "firebase/auth";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { openNotification } from "../slice/notificationSlice";
import { auth } from "../auth/firebaseConfig";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useDispatch();
  const handleSendVerificationEmail = () => {
    if (emailSent) return;
    const actionCodeSettings = {
      url: `${window.location.origin}/verifyEmail?eId=${auth.currentUser.email}`,
      handleCodeInApp: true,
    };
    const emailId = auth.currentUser.email;
    sendSignInLinkToEmail(auth, emailId, actionCodeSettings)
      .then(() => {
        localStorage.setItem("emailForSignIn", emailId);
        console.log("Verification email sent to : " + emailId);
        dispatch(
          openNotification({
            message: "Verification Email Sent",
            severity: "success",
          })
        );
        setEmailSent(true);
      })
      .catch((error) => {
        console.error(
          "Error in sending verification email : " + JSON.stringify(error)
        );
        console.error(error);
        dispatch(
          openNotification({
            message: "Error in Sending Verification Email. Please try again.",
            severity: "error",
          })
        );
      });
  };

  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful");
        dispatch({ type: "USER_LOGOUT" });
        dispatch(
          openNotification({
            message: "Successfully logged out",
            severity: "success",
          })
        );
        navigate("/login", { replace: "true" });
      })
      .catch((error) => {
        console.error("Sign-out error: " + JSON.stringify(error));
      });
  };

  return (
    <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
      <div className="mt-8">
        <a href="https://thehyperbrand.com/">
          <img
            src="/images/THB.svg"
            alt="TheHyperbrand Logo"
            width={140}
            height={32}
          />
        </a>
      </div>
      {auth.currentUser && (
        <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
          <div className="font-semibold">Verify Email Address</div>
          <div className="mt-6 text-sm">
            We’ve mailed you a verification link on
          </div>
          <div className="mt-4 font-semibold text-thbBlue text-sm">
            {auth.currentUser.email}
          </div>
          <div className="mt-4 text-sm">Please click that link to verify</div>
          <div className="mt-8 relative">
            {!emailSent && (
              <div className="flex relative justify-center items-center gap-2">
                <div className="text-black text-sm">Did&apos;t received?</div>
                <div
                  onClick={handleSendVerificationEmail}
                  className="text-sm text-thbBlue font-semibold cursor-pointer"
                >
                  Resend
                </div>
              </div>
            )}
            {emailSent && (
              <div className="p-8 relative justify-center items-center py-2 flex w-full rounded-lg text-green-500">
                Verification email sent
              </div>
            )}
          </div>
          <button
            onClick={handleSignout}
            className="bg-black text-white p-2 rounded-lg mt-4 font-medium"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
