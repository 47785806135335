import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedKeywords: undefined,
  keywords: undefined,
  projectId: undefined,
};

export const keywordSelectionSlice = createSlice({
  name: "keywordSelection",
  initialState: initialState,
  reducers: {
    setStoreProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setStoreKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    setStoreSelectedKeywords: (state, action) => {
      state.selectedKeywords = action.payload;
    },
    addKeywords: (state, action) => {
      state.keywords = [...state.keywords, ...action.payload];
    },
    removeKeyword: (state, action) => {
      const data = state.keywords.filter(
        (kw) => kw.tripletId !== action.payload.tripletId
      );
      setStoreKeywords(data);
    },
  },
});

export const {
  setStoreProjectId,
  setStoreKeywords,
  addKeywords,
  setStoreSelectedKeywords,
  removeKeyword,
} = keywordSelectionSlice.actions;

export default keywordSelectionSlice.reducer;
