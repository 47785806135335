import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../../../components/SideBar";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import EmailIcon from "@mui/icons-material/Email";
import ReactGA from "react-ga4";

const SupportSettings = () => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    ReactGA.event({
      category: "Page Load",
      action: "Settings Support - Page Load",
      location: window.location.pathname,
      label: "Settings Support - Page Load",
    });
  }, []);

  return (
    <div
      className="flex justify-start items-start w-full min-h-screen relative"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className="py-8 px-4 flex flex-col justify-start items-center w-full sm:ml-64">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-4">
            <div className="sm:hidden">
              <IconButton sx={{ color: "gray" }} onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className="text-lg font-semibold">Settings</div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-stretch w-full bg-white shadow-lg rounded-lg mt-4 relative">
          <div className="flex justify-start items-center w-full relative border-b border-gray-200 overflow-x-auto">
            <div className="flex justify-start items-center gap-4 pl-10">
              <div
                onClick={() => navigate("/dashboard/settings/account")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Account
              </div>
              {/* <div
                onClick={() => navigate("/dashboard/settings/brand")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Brands
              </div> */}
              <div
                onClick={() => navigate("/dashboard/settings/subscription")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Subscription
              </div>
              <div
                onClick={() => navigate("/dashboard/settings/plans")}
                className={`px-6 py-2 text-sm font-medium cursor-pointer`}
              >
                Plans and Packages
              </div>
              <div
                className={`px-6 py-2 text-sm font-medium cursor-pointer border-b-4 border-blue-400`}
              >
                Support
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-center w-full relative gap-8 px-10 py-8">
            <div className="text-sm">
              Send a mail to us if you are facing any issues or need any help.
            </div>
            <div className="p-2 font-medium text-sm bg-thbBlue rounded-md text-white flex justify-center items-center gap-2">
              <EmailIcon />
              <div>contact.toptime@gmail.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportSettings;
