import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../slice/authSlice";
import notificationReducer from "../slice/notificationSlice";
import projectsDataReducer from "../slice/projectsDataSlice";
import keywordSelectionReducer from "../slice/keywordSelectionSlice";
import topicSelectionReducer from "../slice/topicSelectionSlice";
import userDetailReducer from "../slice/userDataSlice";
import blogNotificationReducer from "../slice/blogNotificationSlice";
import messageDialogReducer from "../slice/messageDialogSlice";

const appReducers = combineReducers({
  authStore: authReducer,
  notif: notificationReducer,
  projects: projectsDataReducer,
  keywordSelection: keywordSelectionReducer,
  topicSelection: topicSelectionReducer,
  userDetailStore: userDetailReducer,
  blogNotification: blogNotificationReducer,
  commonMessageDialog: messageDialogReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    localStorage.removeItem("thbCurrentProject");
    return appReducers(undefined, action);
  }
  return appReducers(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
