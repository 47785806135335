import { Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoadMoreSubscribeDialog = ({
  openLoadMoreSubscribeModal,
  setOpenLoadMoreSubscribeModal,
  message,
}) => {
  const navigate = useNavigate();
  const handleModalClose = () => {
    setOpenLoadMoreSubscribeModal(false);
  };
  return (
    <Dialog
      open={openLoadMoreSubscribeModal}
      onClose={handleModalClose}
      fullWidth
      className="backdrop-blur-sm"
    >
      <div className="flex flex-col justify-start items-center w-full relative p-16 gap-10">
        <div className="text-center">{message}</div>
        <div>
          {
            <button
              onClick={() => navigate("/dashboard/settings/subscription")}
              className="bg-orange-400 text-white p-4 rounded-md font-semibold"
            >
              Subscribe
            </button>
          }
        </div>
      </div>
    </Dialog>
  );
};

export default LoadMoreSubscribeDialog;
