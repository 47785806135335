import { CircularProgress, Dialog, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import SideBar from "../../../components/SideBar";
import AddIcon from "@mui/icons-material/Add";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetchBackendApiWrapper } from "../../../utils/apiWrapper";
import LoadingPageSkeleton from "../../../components/LoadingPageSkeleton";
import AddTopicModal from "../../../components/AddTopicModal";
import { useNavigate } from "react-router-dom";
import { openNotification } from "../../../slice/notificationSlice";
import CachedIcon from "@mui/icons-material/Cached";
import BoltIcon from "@mui/icons-material/Bolt";
import {
  addStoreDraftTopics,
  addStoreNewTopics,
  addStorePublishedTopics,
  setStoreDraftTopics,
  setStoreNewTopics,
  setStorePublishedTopics,
} from "../../../slice/topicSelectionSlice";
import { formatDifficulty, formatNumber } from "../../../utils/commonUtils";
import LoadMoreSubscribeDialog from "../../../components/subscribeDialogs/LoadMoreSubscribeDialog";
import { setProjectCredits } from "../../../slice/projectsDataSlice";
import { openBlogNotification } from "../../../slice/blogNotificationSlice";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import TopicSelectionGuide from "../../../components/guides/TopicSelectionGuide";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { auth } from "../../../auth/firebaseConfig";
import ReactGA from "react-ga4";

const TopicSelection = () => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const topicSelection = useSelector((state) => state.topicSelection);
  const projects = useSelector((state) => state.projects);
  // const authData = useSelector((state) => state.authStore);
  const [currentView, setCurrentView] = useState(0);
  const dispatch = useDispatch();
  const [openGuide, setOpenGuide] = useState(false);
  const [loaderRefresh, setLoaderRefresh] = useState(false);
  const [openLoadMoreSubscribeModal, setOpenLoadMoreSubscribeModal] =
    useState(false);
  const [messageLoadMoreSubscribeModal, setMessageLoadMoreSubscribeModal] =
    useState("Subscribe to write more blogs");
  //New Topic
  const [processing1, setProcessing1] = useState(false);
  const [error1, setError1] = useState(false);
  const [loadMore1, setLoadMore1] = useState(false);
  // Draft Topic
  const [draftTopicCount, setDraftTopicCount] = useState(null);
  const [processing2, setProcessing2] = useState(false);
  const [error2, setError2] = useState(false);
  const [loadMore2, setLoadMore2] = useState(false);
  // Publish Topic
  const [processing3, setProcessing3] = useState(false);
  const [error3, setError3] = useState(false);
  const [loadMore3, setLoadMore3] = useState(false);

  // add topic
  const [openAddTopicModal, setOpenAddTopicModal] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    ReactGA.event({
      category: "Page Load",
      action: "Topic - Load",
      location: window.location.pathname,
      label: "Topic Selection Load",
    });
  }, []);

  useEffect(() => {
    if (projects.currentProject !== undefined) {
      if (
        currentView === 0
        // TODO : change to read from redux
        // && topicSelection.newTopics === undefined
      ) {
        setProcessing1(true);
        fetchDraftTopicsCountApi(1);
        fetchSelectedTopicsApi(0, true, 10, 0);
      }
      if (
        currentView === 1
        //  && topicSelection.draftTopics === undefined
      ) {
        setProcessing2(true);
        fetchSelectedTopicsApi(1, true, 10, 0);
      }
      if (
        currentView === 2
        //  && topicSelection.publishedTopics === undefined
      ) {
        setProcessing3(true);
        fetchSelectedTopicsApi(2, true, 10, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView]);

  const fetchSelectedTopicsApi = async (publishState, replace, limit, page) => {
    try {
      const topicData = await fetchBackendApiWrapper(
        `/api/v1/topics?projectId=${projects.currentProject.id}&publishState=${publishState}&limit=${limit}&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (topicData.ok) {
        const topicJson = await topicData.json();
        setTopicStore(publishState, replace, topicJson);
        setError1(false);
        setError2(false);
        setError3(false);
      }
    } catch (err) {
      console.error("Error in fetching topics : " + JSON.stringify(err));
      if (publishState === 0) {
        setError1(true);
      }
      if (publishState === 1) {
        setError2(true);
      }
      if (publishState === 2) {
        setError3(true);
      }
    }
    setProcessing1(false);
    setProcessing2(false);
    setProcessing3(false);
  };

  const fetchDraftTopicsCountApi = async (publishState) => {
    try {
      const topicData = await fetchBackendApiWrapper(
        `/api/v1/topicCount?projectId=${projects.currentProject.id}&state=${publishState}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (topicData.ok) {
        const topicJson = await topicData.json();
        setDraftTopicCount(Number(topicJson.value));
      }
    } catch (err) {
      console.error(
        "Error in fetching draft topics count : " + JSON.stringify(err)
      );
    }
  };

  const handleLoadMore1 = async () => {
    setLoadMore1(true);
    await fetchSelectedTopicsApi(
      0,
      false,
      10,
      Math.floor(topicSelection.newTopics.length / 10)
    );
    setLoadMore1(false);
  };

  const handleLoadMore2 = async () => {
    setLoadMore2(true);
    await fetchSelectedTopicsApi(
      1,
      false,
      10,
      Math.floor(topicSelection.draftTopics.length / 10)
    );
    setLoadMore2(false);
  };

  const handleLoadMore3 = async () => {
    setLoadMore3(true);
    await fetchSelectedTopicsApi(
      2,
      false,
      10,
      Math.floor(topicSelection.publishedTopics.length / 10)
    );
    setLoadMore3(false);
  };

  const setTopicStore = (publishState, replace, topicData) => {
    if (replace) {
      if (publishState === 0 || publishState === 3) {
        dispatch(setStoreNewTopics(topicData));
      }
      if (publishState === 1) {
        dispatch(setStoreDraftTopics(topicData));
      }
      if (publishState === 2) {
        dispatch(setStorePublishedTopics(topicData));
      }
    }
    if (!replace) {
      if (publishState === 0 || publishState === 3) {
        dispatch(addStoreNewTopics(topicData));
      }
      if (publishState === 1) {
        dispatch(addStoreDraftTopics(topicData));
      }
      if (publishState === 2) {
        dispatch(addStorePublishedTopics(topicData));
      }
    }
  };

  const handleWriteBlog = async (kws, view) => {
    ReactGA.event({
      category: "Click",
      action: "Blog - Write",
      label: "User clicks on Write Topic",
    });
    let topicStatus = await fetchTopicApi(kws);
    if (topicStatus) {
      if (topicStatus.publishState === 3) {
        // show loader
      } else if (topicStatus.publishState === 0) {
        const secKwTmp = kws.secondaryKw.map((k) => k.keyword);
        checkCanWriteBlogApi(kws)
          .then(() => {
            generateBlogRequest(
              "Informational",
              "",
              "801,1500",
              "",
              kws.topics[kws.currentTopic],
              kws.primaryKw,
              secKwTmp,
              kws.topicId
            ).then(async () => {
              const delay = (delayInms) => {
                return new Promise((resolve) => setTimeout(resolve, delayInms));
              };
              await delay(2000);
              // update topic selection state
              const tmp = topicSelection.newTopics.map((t) => {
                if (t.topicId === kws.topicId) {
                  topicStatus.publishState = 3;
                  return topicStatus;
                }
                return t;
              });
              dispatch(setStoreNewTopics(tmp));
            });
          })
          .catch((err) => {
            console.error(
              "Error in writing new blog as limit reached : " +
                JSON.stringify(err)
            );
          });
      } else if (
        topicStatus.publishState === 1 ||
        topicStatus.publishState === 2
      ) {
        const searchParam = new URLSearchParams();
        searchParam.set("topicId", kws.topicId);
        const url = `/dashboard/topics/blog?${searchParam.toString()}`;
        navigate(url);
      }
    }
  };

  const fetchTopicApi = async (kws) => {
    try {
      const kwData = await fetchBackendApiWrapper(
        `/api/v1/topic?projectId=${projects.currentProject.id}&topicId=${kws.topicId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (kwData.status === 200) {
        const kwJson = await kwData.json();
        if (kwJson !== null && kwJson !== undefined) {
          return kwJson;
        }
        return null;
      } else if (kwData.status === 204) {
        return null;
      }
    } catch (err) {
      console.error("Error in fetching topic : " + JSON.stringify(err));
      return null;
    }
  };

  const generateBlogRequest = async (
    argTone,
    argPov,
    argWordCount,
    argAdditionalInsights,
    topic,
    targetKeyword,
    secondaryKeywords,
    topicId
  ) => {
    try {
      const authToken = await auth.currentUser.getIdToken(false);
      const payload = {
        topicId: topicId,
        topic: topic,
        targetKeyword: targetKeyword,
        keywords: secondaryKeywords,
        audience: "general public",
        tone: argTone,
        wordRange: argWordCount,
        companyDescription:
          projects.currentProject.projectDescription === null
            ? ""
            : projects.currentProject.projectDescription,
        additionalInsights: argAdditionalInsights,
        pov: argPov,
        projectId: projects.currentProject.id,
      };
      const res = await fetchBackendApiWrapper(
        "/api/v1/generateBlog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        authToken
      );
      if (res !== null && res !== undefined && res.ok) {
        const msg = {
          msg: `Blog generation started.\nWe will notify you when it's ready.\nPlease explore some other features till we generate blog for you.`,
          source: null,
          open: true,
        };
        dispatch(openBlogNotification(msg));
      } else {
        console.error("Error in generating blog");
        // dispatch(
        //   openNotification({
        //     message: "Error in generating blog",
        //     severity: "error",
        //   })
        // );
      }
    } catch (err) {
      console.error("Error in generating blog " + JSON.stringify(err));
      dispatch(
        openNotification({
          message: "Error in generating blog",
          severity: "error",
        })
      );
    }
  };

  const checkCanWriteBlogApi = async (kws) => {
    try {
      const kwData = await fetchBackendApiWrapper(
        `/api/v1/checkCanWriteBlog?projectId=${projects.currentProject.id}&state=1&topicId=${kws.topicId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (kwData.ok) {
        fetchSelectedTopicsApi(0, true, 10, 0);
        const tmp = { ...projects.projectCredits };
        tmp.blogsCredit = tmp.blogsCredit - 1;
        dispatch(setProjectCredits(tmp));
      } else if (kwData.status === 423) {
        console.error("Blogs limit reached. ");
        setMessageLoadMoreSubscribeModal(
          !projects.currentProject.isActiveSubscription
            ? "You have used your 2 free blogs. Please subscribe to write more."
            : "You have used your 30 blogs for this month. Please wait until the next month to renew your subscription."
        );
        setOpenLoadMoreSubscribeModal(true);
        throw new Error("Blog Limit Reached");
      }
    } catch (err) {
      console.error(
        "Error in fetching checkCanWriteBlog : " + JSON.stringify(err)
      );
      throw err;
    }
  };

  // Refresh list
  const handleRefreshKeywordList = async () => {
    if (loaderRefresh) return;
    ReactGA.event({
      category: "Click",
      action: "Topics - Refresh",
      label: "User clicks on Refresh Topics",
    });
    setLoaderRefresh(true);
    dispatch(setStoreNewTopics(undefined));
    dispatch(setStoreDraftTopics(undefined));
    dispatch(setStorePublishedTopics(undefined));

    fetchSelectedTopicsApi(currentView, true, 10, 0)
      .then(() => {
        console.log("Topics Refreshed");
        setLoaderRefresh(false);
      })
      .catch((err) => {
        console.error("Error in Topic Refresh");
        setLoaderRefresh(false);
      });
  };

  // START: Edit Topic Feature //
  const [selectedKwEdit, setSelectedKwEdit] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [topicEditLoader, setTopicEditLoader] = useState(false);

  const DeleteDialog = () => {
    const handleDeleteTopic = async (selectedKwEdit) => {
      if (topicEditLoader) return;
      ReactGA.event({
        category: "Click",
        action: "Topic Delete",
        label: "User clicks on Topic Delete",
      });
      setTopicEditLoader(true);
      try {
        const saveData = await fetchBackendApiWrapper(
          `/api/v1/removeKeyword?topicId=${selectedKwEdit.topicId}&projectId=${projects.currentProject.id}`,
          {
            method: "PUT",
          },
          await auth.currentUser.getIdToken(false)
        );
        if (saveData.ok) {
          fetchSelectedTopicsApi(0, true, 10, 0);
          dispatch(
            openNotification({
              message: "Topic Removed successfully",
              severity: "success",
            })
          );
        }
      } catch (err) {
        console.error("Error in removing topic: " + JSON.stringify(err));
        dispatch(
          openNotification({
            message: "Error in removing topic",
            severity: "error",
          })
        );
      }
      setTopicEditLoader(false);
      setOpenDeleteModal(false);
    };

    const handleDeleteModalClose = () => {
      if (topicEditLoader) return;
      setOpenDeleteModal(false);
    };

    return (
      <Dialog
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-start items-start w-full relative rounded-lg p-6 gap-6">
          <div className="flex justify-between items-center w-full relative border-b pb-4 border-gray-100">
            <div className="font-medium text-lg">Delete Topic</div>
            <div
              onClick={handleDeleteModalClose}
              className="cursor-pointer rounded-full p-2 bg-gray-100"
            >
              <ClearIcon />
            </div>
          </div>
          <div className="font-medium text-center w-full relative">
            This action cannot be undone, it will permanently delete the topic?
          </div>
          <div className="flex justify-end items-center w-full relative gap-4">
            <button
              onClick={handleDeleteModalClose}
              className="px-4 py-2 font-medium border border-black hover:bg-black hover:text-white  rounded-md text-sm"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDeleteTopic(selectedKwEdit)}
              className="px-4 py-2 font-medium bg-red-500 hover:bg-red-700 text-white text-sm rounded-md"
            >
              {topicEditLoader ? (
                <CircularProgress
                  size="1rem"
                  variant="indeterminate"
                  sx={{ color: "white" }}
                />
              ) : (
                "Delete Topic"
              )}
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  const EditTopicDialog = () => {
    const [newTopic, setNewTopic] = useState(
      selectedKwEdit === null
        ? ""
        : selectedKwEdit.topics[selectedKwEdit.currentTopic]
    );

    const handleEditModalClose = () => {
      if (topicEditLoader) return;
      setOpenEditModal(false);
    };

    const generateNewTopic = () => {
      let curInd =
        (selectedKwEdit.topics.indexOf(newTopic) + 1) %
        selectedKwEdit.topics.length;
      setNewTopic(selectedKwEdit.topics[curInd]);
    };

    const saveNewTopic = async () => {
      if (newTopic.length === 0) return;
      if (topicEditLoader) return;
      ReactGA.event({
        category: "Click",
        action: "Edit Topic",
        label: "User clicks on Edit Topic",
      });
      setTopicEditLoader(true);
      try {
        const payload = {
          projectId: projects.currentProject.id,
          topic: newTopic,
          topicId: selectedKwEdit.topicId,
        };
        const addTopicData = await fetchBackendApiWrapper(
          "/api/v1/editTopic",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          },
          await auth.currentUser.getIdToken(false)
        );
        if (addTopicData.ok) {
          await fetchSelectedTopicsApi(0, true, 10, 0);
          dispatch(
            openNotification({
              message: "Topic Edited successfully",
              severity: "success",
            })
          );
        }
      } catch (err) {
        console.error("Error in editing topic: " + JSON.stringify(err));
        dispatch(
          openNotification({
            message: "Error in Editing Topic",
            severity: "error",
          })
        );
      }
      setTopicEditLoader(false);
      setOpenEditModal(false);
    };

    return (
      <Dialog
        open={openEditModal}
        onClose={handleEditModalClose}
        fullWidth
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-start items-start w-full relative rounded-lg p-6 gap-6">
          <div className="flex justify-between items-center w-full relative border-b pb-4 border-gray-100">
            <div className="font-medium text-lg">Edit Topic</div>
            <div
              onClick={handleEditModalClose}
              className="cursor-pointer rounded-full p-2 bg-gray-100"
            >
              <ClearIcon />
            </div>
          </div>
          <div className="text-sm text-gray-600">
            Write your new topic or regenerate it automatically using refresh
            button
          </div>
          <div className="flex flex-row justify-between items-center w-full relative gap-2">
            <input
              value={newTopic}
              onChange={(event) => setNewTopic(event.target.value)}
              placeholder="Write your new title"
              className=" text-gray-900 text-sm rounded-lg border border-gray-300 block w-full p-2.5"
            />
            {selectedKwEdit &&
              selectedKwEdit.topics &&
              selectedKwEdit.topics.length > 1 && (
                <button
                  onClick={generateNewTopic}
                  className="bg-black text-white p-2 rounded-md"
                >
                  <CachedIcon />
                </button>
              )}
          </div>
          <div className="flex justify-end items-center w-full relative gap-4">
            <button
              onClick={handleEditModalClose}
              className="px-4 py-2 font-medium border border-black hover:bg-black hover:text-white  rounded-md text-sm"
            >
              Cancel
            </button>
            <button
              onClick={saveNewTopic}
              className="px-4 py-2 font-medium bg-red-500 hover:bg-red-700 text-white text-sm rounded-md"
            >
              {topicEditLoader ? (
                <CircularProgress
                  size="1rem"
                  variant="indeterminate"
                  sx={{ color: "white" }}
                />
              ) : (
                "Save Topic"
              )}
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  const TopicEditDropdownMenu = ({ kws }) => {
    const [openEditMenu, setOpenEditMenu] = useState(false);

    return (
      <div
        onClick={() => setOpenEditMenu(!openEditMenu)}
        className="inline-block gap-1 group"
      >
        <button className="p-1 rounded-md bg-slate-100 hover:bg-slate-200 text-black">
          <MoreVertOutlinedIcon />
        </button>
        {
          <div
            className={`flex-col justify-start items-start z-20 absolute bg-white shadow-2xl rounded-md gap-6 py-2 border border-gray-100 right-0 hidden ${
              openEditMenu ? "flex" : "hidden"
            } group-hover:flex`}
          >
            <div
              onClick={() => {
                setOpenEditModal(true);
                setSelectedKwEdit(kws);
              }}
              className="px-8 hover:text-red-400 cursor-pointer flex justify-center items-center gap-2"
            >
              <CreateOutlinedIcon sx={{ fontSize: "1rem" }} />
              Edit
            </div>
            <div
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectedKwEdit(kws);
              }}
              className="px-8 hover:text-red-400 cursor-pointer flex justify-center items-center gap-2"
            >
              <DeleteIcon sx={{ fontSize: "1rem" }} />
              Delete
            </div>
          </div>
        }
      </div>
    );
  };

  // END: Edit Topic Feature //

  return (
    <div
      className="flex justify-start items-start min-h-screen w-full"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <TopicSelectionGuide openGuide={openGuide} setOpenGuide={setOpenGuide} />
      <div className="py-8 px-4 flex flex-col justify-start items-center w-full sm:ml-64 gap-4">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-4">
            <div className="sm:hidden">
              <IconButton sx={{ color: "gray" }} onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className="text-lg font-semibold">Topic Selection</div>
          </div>
          <div className="flex justify-end items-center gap-4">
            <div
              onClick={() => setOpenGuide(true)}
              className="rounded-lg bg-white shadow-md p-2 text-gray-500 cursor-pointer"
            >
              <HelpOutlineRoundedIcon />
            </div>
            {projects.projectCredits &&
              !isNaN(projects.projectCredits.blogsCredit) && (
                <div className="flex justify-center items-center bg-white p-2 text-sm rounded-md shadow-lg gap-2">
                  <BoltIcon className="text-blue-500" />
                  <div className="text-sm text-gray-500">Blogs Remaining </div>
                  <span className="font-semibold text-blue-500">
                    {projects.projectCredits.blogsCredit}
                  </span>
                </div>
              )}
          </div>
        </div>
        <div className="flex justify-start items-center w-full text-xs text-gray-700">
          Topics for the selected keywords from Keyword Selection page are shown
          here.
        </div>
        <div className="p-6 flex flex-col rounded-lg shadow-lg bg-white relative w-full pb-20 overflow-x-auto">
          <div className="flex justify-between items-center w-full relative border-b border-gray-200">
            <div className="flex justify-start items-center gap-4">
              <div
                onClick={() => {
                  ReactGA.event({
                    category: "Click",
                    action: "New Topics Load",
                    label: "User clicks on load New Topics",
                  });
                  setCurrentView(0);
                }}
                className={`px-6 py-2 text-sm font-medium cursor-pointer ${
                  currentView === 0 ? "border-b-4 border-blue-400" : ""
                }`}
              >
                New Topics
              </div>
              <div
                onClick={() => {
                  ReactGA.event({
                    category: "Click",
                    action: "Inprogress Topics Load",
                    label: "User clicks on load Inprogress Topics",
                  });
                  setCurrentView(1);
                }}
                className={`flex justify-start items-center gap-2 px-6 py-2 text-sm font-medium cursor-pointer ${
                  currentView === 1 ? "border-b-4 border-blue-400" : ""
                }`}
              >
                <div>In-progress</div>
                {draftTopicCount !== null &&
                  draftTopicCount !== undefined &&
                  draftTopicCount > 0 && (
                    <div className="flex justify-center items-center rounded-full h-4 w-4 p-1 bg-red-500 text-white font-bold text-xs">
                      {draftTopicCount}
                    </div>
                  )}
              </div>
              {/* <div
                onClick={() => {
                  ReactGA.event({
                    category: "Click",
                    action: "Published Topics Load",
                    label: "User clicks on load Published Topics",
                  });
                  setCurrentView(2);
                }}
                className={`px-6 py-2 text-sm font-medium cursor-pointer ${
                  currentView === 2 ? "border-b-4 border-blue-400" : ""
                }`}
              >
                Published
              </div> */}
            </div>
            <div>
              <button
                onClick={handleRefreshKeywordList}
                className="rounded-md p-2 bg-black text-white text-sm font-medium"
              >
                {loaderRefresh ? (
                  <CircularProgress
                    size="1rem"
                    variant="indeterminate"
                    sx={{ color: "white" }}
                  />
                ) : (
                  <CachedIcon />
                )}
              </button>
            </div>
          </div>

          {/* New Topics View */}
          {currentView === 0 && (
            <div className="flex flex-col justify-start items-start relative w-full mt-10 gap-6">
              <div className="flex justify-between items-center w-full relative px-6">
                {!processing1 && !error1 && (
                  <table
                    className="table-auto relative w-full"
                    style={{ borderSpacing: "1.5rem 0.5rem" }}
                  >
                    <thead>
                      <tr>
                        <th className="flex justify-start items-center pl-4">
                          <button
                            onClick={() => setOpenAddTopicModal(true)}
                            className="rounded-md border border-blue-500 hover:border-2 px-4 py-1 text-blue-500 text-sm font-medium"
                          >
                            Add Topic <AddIcon sx={{ fontSize: "1.2rem" }} />
                          </button>
                        </th>
                        {/* <th className="pl-6 text-sm font-medium text-gray-800 pb-6">
                          <div className="text-sm font-medium pb-6 flex justify-center items-center gap-1">
                            Intent
                            <InfoOutlinedIcon
                              sx={{ fontSize: "1rem", cursor: "pointer" }}
                              onClick={() => setOpenGuide(true)}
                            />
                          </div>
                        </th>
                        <th className="pl-6 text-sm font-medium text-gray-800 pb-6">
                          <div className="text-sm font-medium pb-6 flex justify-center items-center gap-1">
                            Difficulty
                            <InfoOutlinedIcon
                              sx={{ fontSize: "1rem", cursor: "pointer" }}
                              onClick={() => setOpenGuide(true)}
                            />
                          </div>
                        </th>
                        <th className="pl-6 text-sm font-medium text-gray-800 pb-6">
                          <div className="text-sm font-medium pb-6 flex justify-center items-center gap-1">
                            Volume
                            <InfoOutlinedIcon
                              sx={{ fontSize: "1rem", cursor: "pointer" }}
                              onClick={() => setOpenGuide(true)}
                            />
                          </div>
                        </th> */}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {topicSelection.newTopics !== undefined &&
                        topicSelection.newTopics !== null &&
                        topicSelection.newTopics.length > 0 &&
                        topicSelection.newTopics.map((kws, ind) => {
                          return (
                            <tr key={ind} className="border-b border-gray-200">
                              <td className="p-4">
                                <div className="flex flex-col justify-start items-start relative w-full gap-4">
                                  <div className="text-base font-medium">
                                    {kws.topics[kws.currentTopic]}
                                  </div>
                                  <div className="flex justify-start items-start gap-4">
                                    {kws.primaryKw.length > 0 && (
                                      <div className="text-sm px-2 py-1 bg-thbBlue rounded-md text-white">
                                        {kws.primaryKw}
                                      </div>
                                    )}
                                    {kws.secondaryKw.map((sec, sind) => {
                                      return (
                                        <div
                                          key={sind}
                                          className="text-sm px-2 py-1 bg-sky-50 rounded-md text-blue-500"
                                        >
                                          {sec.keyword}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </td>
                              {/* <td>
                                <div className="pl-6 flex justify-start items-center gap-1">
                                  {kws.intent.length > 0 &&
                                    kws.intent.split(",").map((it, ind) => {
                                      return (
                                        <div
                                          key={ind}
                                          className="text-sm px-2 py-1 rounded-md bg-sky-50 text-center font-medium"
                                        >
                                          {it
                                            .trim()
                                            .substring(0, 1)
                                            .toUpperCase()}
                                        </div>
                                      );
                                    })}
                                </div>
                              </td>
                              <td>
                                <div className="pl-6  flex justify-center items-center">
                                  <div className="flex justify-start items-center">
                                    {formatDifficulty(kws.difficulty)}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="pl-6 text-sm p-1 text-center font-medium">
                                  {formatNumber(kws.volume)}
                                </div>
                              </td> */}
                              <td className="relative w-1/4">
                                {kws.publishState === 0 && (
                                  <div className="flex justify-end items-center gap-2">
                                    <button
                                      onClick={() => handleWriteBlog(kws, 0)}
                                      className="rounded-md border bg-thbBlue hover:bg-blue-600 px-3 py-2 text-white text-sm font-medium"
                                    >
                                      <CreateOutlinedIcon
                                        sx={{ fontSize: "1.2rem" }}
                                      />{" "}
                                      Write
                                    </button>
                                    <TopicEditDropdownMenu kws={kws} />
                                  </div>
                                )}
                                {kws.publishState === 3 && (
                                  <div className="flex justify-end items-center gap-2">
                                    <button
                                      onClick={() => handleWriteBlog(kws, 0)}
                                      className="rounded-md border bg-thbBlue hover:bg-blue-600 px-3 py-2 text-white text-sm font-medium"
                                    >
                                      <div className="flex justify-center gap-4 items-center">
                                        <CircularProgress
                                          size="1rem"
                                          variant="indeterminate"
                                          sx={{ color: "white" }}
                                        />
                                        <div className="text-sm">
                                          Writing ...{" "}
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                )}
                                <LoadMoreSubscribeDialog
                                  openLoadMoreSubscribeModal={
                                    openLoadMoreSubscribeModal
                                  }
                                  setOpenLoadMoreSubscribeModal={
                                    setOpenLoadMoreSubscribeModal
                                  }
                                  message={messageLoadMoreSubscribeModal}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      <DeleteDialog />
                      <EditTopicDialog />
                    </tbody>
                  </table>
                )}
              </div>
              <AddTopicModal
                openAddTopicModal={openAddTopicModal}
                setOpenAddTopicModal={setOpenAddTopicModal}
                projectId={projects.currentProject.id}
              />
              {!error1 &&
                !processing1 &&
                topicSelection.newTopics !== undefined &&
                topicSelection.newTopics !== null &&
                topicSelection.newTopics.length > 0 &&
                topicSelection.newTopics.length % 10 === 0 && (
                  <div className="flex justify-center w-full relative">
                    <button
                      onClick={handleLoadMore1}
                      className="rounded-md border border-blue-500 text-blue-500 text-sm px-4 py-2"
                    >
                      {loadMore1 ? (
                        <CircularProgress
                          size="1rem"
                          variant="indeterminate"
                          sx={{ color: "blue" }}
                        />
                      ) : (
                        "Load more topics"
                      )}
                    </button>
                  </div>
                )}
              {!processing1 &&
                !error1 &&
                topicSelection.newTopics !== undefined &&
                topicSelection.newTopics !== null &&
                topicSelection.newTopics.length === 0 && (
                  <div className="text-sm text-gray-500 text-center flex justify-center items-center relative w-full p-10">
                    No New Topics found. Please add keywords from keyword
                    selection page. After you have added keywords it will take
                    around 5min to show you the best topics for you.
                  </div>
                )}
              {processing1 && !error1 && (
                <div className="text-sm text-gray-300 text-center flex justify-center items-center relative w-full">
                  <LoadingPageSkeleton />
                </div>
              )}
              {error1 && (
                <div className="text-sm text-gray-500 text-center flex justify-center items-center relative w-full">
                  <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
                    <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
                      <div className="font-medium">
                        Something went wrong please try again
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* Draft Topics view */}
          {currentView === 1 && (
            <div className="flex flex-col justify-start items-start relative w-full mt-4 gap-6">
              <div className="flex justify-between items-center w-full relative px-6">
                {!processing2 &&
                  !error2 &&
                  topicSelection.draftTopics !== undefined &&
                  topicSelection.draftTopics !== null &&
                  topicSelection.draftTopics.length > 0 && (
                    <table
                      className="table-auto relative w-full"
                      style={{ borderSpacing: "1.5rem 0.5rem" }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          {/* <th className="pl-6 text-sm font-medium text-gray-800 pb-6">
                            <div className="text-sm font-medium pb-6 flex justify-center items-center gap-1">
                              Intent
                              <InfoOutlinedIcon
                                sx={{ fontSize: "1rem", cursor: "pointer" }}
                                onClick={() => setOpenGuide(true)}
                              />
                            </div>
                          </th>
                          <th className="pl-6 text-sm font-medium text-gray-800 pb-6">
                            <div className="text-sm font-medium pb-6 flex justify-center items-center gap-1">
                              Difficulty
                              <InfoOutlinedIcon
                                sx={{ fontSize: "1rem", cursor: "pointer" }}
                                onClick={() => setOpenGuide(true)}
                              />
                            </div>
                          </th>
                          <th className="pl-6 text-sm font-medium text-gray-800 pb-6">
                            <div className="text-sm font-medium pb-6 flex justify-center items-center gap-1">
                              Volume
                              <InfoOutlinedIcon
                                sx={{ fontSize: "1rem", cursor: "pointer" }}
                                onClick={() => setOpenGuide(true)}
                              />
                            </div>
                          </th> */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {topicSelection.draftTopics.map((kws, ind) => {
                          return (
                            <tr key={ind} className="border-b border-gray-200">
                              <td className="p-4">
                                <div className="flex flex-col justify-start items-start relative w-full gap-4">
                                  <div className="text-base font-medium">
                                    {kws.topics[kws.currentTopic]}
                                  </div>
                                  <div className="flex justify-start items-start gap-4">
                                    {kws.primaryKw.length > 0 && (
                                      <div className="text-sm px-2 py-1 bg-thbBlue rounded-md text-white">
                                        {kws.primaryKw}
                                      </div>
                                    )}
                                    {kws.secondaryKw.map((sec, sind) => {
                                      return (
                                        <div
                                          key={sind}
                                          className="text-sm px-2 py-1 bg-sky-50 rounded-md text-blue-500"
                                        >
                                          {sec.keyword}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </td>
                              {/* <td>
                                <div className="pl-6 flex justify-start items-center gap-1">
                                  {kws.intent.length > 0 &&
                                    kws.intent.split(",").map((it, ind) => {
                                      return (
                                        <div
                                          key={ind}
                                          className="text-sm px-2 py-1 rounded-md bg-sky-50 text-center font-medium"
                                        >
                                          {it
                                            .trim()
                                            .substring(0, 1)
                                            .toUpperCase()}
                                        </div>
                                      );
                                    })}
                                </div>
                              </td>
                              <td>
                                <div className="pl-6  flex justify-center items-center">
                                  <div className="flex justify-start items-center">
                                    {formatDifficulty(kws.difficulty)}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="pl-6 text-sm p-1 text-center font-medium">
                                  {formatNumber(kws.volume)}
                                </div>
                              </td> */}
                              <td className="relative w-1/4">
                                <div className="flex justify-end items-center gap-2">
                                  <button
                                    onClick={() => handleWriteBlog(kws, 1)}
                                    className="rounded-md border bg-orange-500 hover:bg-blue-600 px-3 py-2 text-white text-sm font-medium"
                                  >
                                    <CreateOutlinedIcon
                                      sx={{ fontSize: "1.2rem" }}
                                    />{" "}
                                    Edit
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
              </div>
              {!error2 &&
                !processing2 &&
                topicSelection.draftTopics !== undefined &&
                topicSelection.draftTopics !== null &&
                topicSelection.draftTopics.length > 0 &&
                topicSelection.draftTopics.length % 10 === 0 && (
                  <div className="flex justify-center w-full relative">
                    <button
                      onClick={handleLoadMore2}
                      className="rounded-md border border-blue-500 text-blue-500 text-sm px-4 py-2"
                    >
                      {loadMore2 ? (
                        <CircularProgress
                          size="1rem"
                          variant="indeterminate"
                          sx={{ color: "blue" }}
                        />
                      ) : (
                        "Load more topics"
                      )}
                    </button>
                  </div>
                )}
              {!processing2 &&
                !error2 &&
                topicSelection.draftTopics !== undefined &&
                topicSelection.draftTopics !== null &&
                topicSelection.draftTopics.length === 0 && (
                  <div className="text-sm text-gray-500 text-center flex justify-center items-center relative w-full p-10">
                    No Draft Topics found. Please add keywords from keyword
                    selection page. After you have added keywords it will take
                    around 5min to show you the best topics for you.
                  </div>
                )}
              {processing2 && !error2 && (
                <div className="text-sm text-gray-300 text-center flex justify-center items-center relative w-full">
                  <LoadingPageSkeleton />
                </div>
              )}
              {error2 && (
                <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
                  <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
                    <div className="font-medium">
                      Something went wrong please try again
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* Draft Topics view */}
          {currentView === 2 && (
            <div className="flex flex-col justify-start items-start relative w-full mt-4 gap-6">
              <div className="flex justify-between items-center w-full relative px-6">
                {!processing3 &&
                  !error3 &&
                  topicSelection.publishedTopics !== undefined &&
                  topicSelection.publishedTopics !== null &&
                  topicSelection.publishedTopics.length > 0 && (
                    <table
                      className="table-auto relative w-full"
                      style={{ borderSpacing: "1.5rem 0.5rem" }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th className="pl-6 text-sm font-medium text-gray-800 pb-6">
                            <div className="text-sm font-medium pb-6 flex justify-center items-center gap-1">
                              Intent
                              <InfoOutlinedIcon
                                sx={{ fontSize: "1rem", cursor: "pointer" }}
                                onClick={() => setOpenGuide(true)}
                              />
                            </div>
                          </th>
                          <th className="pl-6 text-sm font-medium text-gray-800 pb-6">
                            <div className="text-sm font-medium pb-6 flex justify-center items-center gap-1">
                              Difficulty
                              <InfoOutlinedIcon
                                sx={{ fontSize: "1rem", cursor: "pointer" }}
                                onClick={() => setOpenGuide(true)}
                              />
                            </div>
                          </th>
                          <th className="pl-6 text-sm font-medium text-gray-800 pb-6">
                            <div className="text-sm font-medium pb-6 flex justify-center items-center gap-1">
                              Volume
                              <InfoOutlinedIcon
                                sx={{ fontSize: "1rem", cursor: "pointer" }}
                                onClick={() => setOpenGuide(true)}
                              />
                            </div>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {topicSelection.publishedTopics.map((kws, ind) => {
                          return (
                            <tr key={ind} className="border-b border-gray-200">
                              <td className="p-4">
                                <div className="flex flex-col justify-start items-start relative w-full gap-4">
                                  <div className="text-base font-medium">
                                    {kws.topics[kws.currentTopic]}
                                  </div>
                                  <div className="flex justify-start items-start gap-4">
                                    {kws.primaryKw.length > 0 && (
                                      <div className="text-sm px-2 py-1 bg-thbBlue rounded-md text-white">
                                        {kws.primaryKw}
                                      </div>
                                    )}
                                    {kws.secondaryKw.map((sec, sind) => {
                                      return (
                                        <div
                                          key={sind}
                                          className="text-sm px-2 py-1 bg-sky-50 rounded-md text-blue-500"
                                        >
                                          {sec.keyword}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="pl-6 flex justify-start items-center gap-1">
                                  {kws.intent.length > 0 &&
                                    kws.intent.split(",").map((it, ind) => {
                                      return (
                                        <div
                                          key={ind}
                                          className="text-sm px-2 py-1 rounded-md bg-sky-50 text-center font-medium"
                                        >
                                          {it
                                            .trim()
                                            .substring(0, 1)
                                            .toUpperCase()}
                                        </div>
                                      );
                                    })}
                                </div>
                              </td>
                              <td>
                                <div className="pl-6  flex justify-center items-center">
                                  <div className="flex justify-start items-center">
                                    {formatDifficulty(kws.difficulty)}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="pl-6 text-sm p-1 text-center font-medium">
                                  {formatNumber(kws.volume)}
                                </div>
                              </td>
                              <td className="relative w-1/4">
                                <div className="flex justify-end items-center gap-2">
                                  <button
                                    onClick={() => handleWriteBlog(kws, 2)}
                                    className="rounded-md border bg-orange-500 hover:bg-blue-600 px-3 py-2 text-white text-sm font-medium"
                                  >
                                    <CreateOutlinedIcon
                                      sx={{ fontSize: "1.2rem" }}
                                    />{" "}
                                    View
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
              </div>
              {!error3 &&
                !processing3 &&
                topicSelection.publishedTopics !== undefined &&
                topicSelection.publishedTopics !== null &&
                topicSelection.publishedTopics.length > 0 &&
                topicSelection.publishedTopics.length % 10 === 0 && (
                  <div className="flex justify-center w-full relative">
                    <button
                      onClick={handleLoadMore3}
                      className="rounded-md border border-blue-500 text-blue-500 text-sm px-4 py-2"
                    >
                      {loadMore3 ? (
                        <CircularProgress
                          size="1rem"
                          variant="indeterminate"
                          sx={{ color: "blue" }}
                        />
                      ) : (
                        "Load more topics"
                      )}
                    </button>
                  </div>
                )}
              {!processing3 &&
                !error3 &&
                topicSelection.publishedTopics !== undefined &&
                topicSelection.publishedTopics !== null &&
                topicSelection.publishedTopics.length === 0 && (
                  <div className="text-sm text-gray-500 text-center flex justify-center items-center relative w-full p-10">
                    No Blogs have been Published
                  </div>
                )}
              {processing3 && !error3 && (
                <div className="text-sm text-gray-300 text-center flex justify-center items-center relative w-full">
                  <LoadingPageSkeleton />
                </div>
              )}
              {error3 && (
                <div className="text-sm text-gray-500 text-center flex justify-center items-center relative w-full">
                  <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
                    <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
                      <div className="font-medium">
                        Something went wrong please try again
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicSelection;
