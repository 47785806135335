import { CircularProgress, Dialog } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { fetchBackendApiWrapper } from "../utils/apiWrapper";
import { openNotification } from "../slice/notificationSlice";
import { setStoreNewTopics } from "../slice/topicSelectionSlice";
import { auth } from "../auth/firebaseConfig";
import ReactGA from "react-ga4";

const AddTopicModal = ({
  openAddTopicModal,
  setOpenAddTopicModal,
  projectId,
}) => {
  const [userTopic, setUserTopic] = useState("");
  const [userKeywords, setUserKeywords] = useState("");
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const handleModalClose = (event, reason) => {
    if (processing) return;
    setOpenAddTopicModal(false);
  };

  const handleAddTopic = async () => {
    if (userTopic.length === 0) return;
    if (processing) return;
    ReactGA.event({
      category: "Click",
      action: "Add Topic",
      label: "User clicks on Add Topic",
    });
    setProcessing(true);
    try {
      const payload = {
        projectId: projectId,
        topic: userTopic,
        keywords: userKeywords.split(","),
      };
      const addTopicData = await fetchBackendApiWrapper(
        "/api/v1/addUserTopic",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        await auth.currentUser.getIdToken(false)
      );
      if (addTopicData.ok) {
        await fetchSelectedTopicsApi(0, 10, 0);
        dispatch(
          openNotification({
            message: "Topic Added successfully",
            severity: "success",
          })
        );
      }
    } catch (err) {
      console.error("Error in adding topic: " + JSON.stringify(err));
      dispatch(
        openNotification({
          message: "Error in adding topic",
          severity: "error",
        })
      );
    }
    setProcessing(false);
    setOpenAddTopicModal(false);
  };

  const fetchSelectedTopicsApi = async (publishState, limit, page) => {
    try {
      const topicData = await fetchBackendApiWrapper(
        `/api/v1/topics?projectId=${projectId}&publishState=${publishState}&limit=${limit}&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (topicData.ok) {
        const topicJson = await topicData.json();
        dispatch(setStoreNewTopics(topicJson));
      }
    } catch (err) {
      console.error("Error in fetching topics : " + JSON.stringify(err));
    }
    setProcessing(false);
  };

  return (
    <Dialog
      open={openAddTopicModal}
      onClose={handleModalClose}
      fullWidth
      className="backdrop-blur-sm"
    >
      <div className="flex flex-col justify-start items-start relative w-full gap-4 p-8">
        <div className="flex justify-between items-center w-full relative">
          <div className="text-xl font-semibold">Add Topic</div>
          <div
            onClick={() => setOpenAddTopicModal(false)}
            className="cursor-pointer rounded-full p-2 bg-gray-100"
          >
            <ClearIcon />
          </div>
        </div>

        <div className="text-sm text-slate-500">
          Enter your Title and keywords
        </div>
        <div className="flex flex-col justify-start items-start w-full relative gap-2">
          <div className="font-semibold">Title</div>
          <input
            value={userTopic}
            onChange={(event) => setUserTopic(event.target.value)}
            placeholder="Write your title"
            className=" text-gray-900 text-sm rounded-lg border border-gray-300 block w-full p-2.5"
          />
        </div>
        <div className="flex flex-col justify-start items-start w-full relative gap-2">
          <div className="font-semibold">Keywords</div>
          <div className="text-xs text-gray-500">Note: First keyword will be primary keyword</div>
          <input
            value={userKeywords}
            onChange={(event) => setUserKeywords(event.target.value)}
            placeholder="Add your keywords comma seperated"
            className=" text-gray-900 text-sm rounded-lg border border-gray-300 block w-full p-2.5"
          />
        </div>
        <button
          onClick={handleAddTopic}
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2 text-sm relative w-full"
        >
          {processing ? (
            <CircularProgress
              size="2rem"
              variant="indeterminate"
              sx={{ color: "white" }}
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </Dialog>
  );
};

export default AddTopicModal;
