import { useEffect, useRef, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ReactQuill from "react-quill";
import { fetchBackendApiWrapper } from "../../../../../../utils/apiWrapper";
import { useDispatch, useSelector } from "react-redux";
import LoadingPageSkeleton from "../../../../../../components/LoadingPageSkeleton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ImageRegenerateComponent from "../../../../../../components/ImageRegenerateComponent";
import LoadingModal from "../../../../../../components/LoadingModal";
import { openNotification } from "../../../../../../slice/notificationSlice";
import { CircularProgress, Dialog } from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import PromotionCustomQuillToolbar, {
  formats,
  modules,
} from "../PromotionCustomQuillToolbar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { uploadBase64Image } from "../../../../../../utils/commonUtils";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import ClearIcon from "@mui/icons-material/Clear";
import IntegrationDialog from "../../../../../../components/IntegrationDialog";
import { auth } from "../../../../../../auth/firebaseConfig";

const LinkedInPromotion = ({ topicId }) => {
  const promotionType = 2;
  const dispatch = useDispatch();
  const [additionalInsights, setAdditionalInsights] = useState("");
  const [tone, setTone] = useState("Informational");
  const [referenceLinks, setReferenceLinks] = useState("");
  const [saveChange, setSaveChange] = useState(false);
  const quillLinkedIn = useRef(null);
  const projects = useSelector((state) => state.projects);
  const [promoData, setPromoData] = useState(null);
  const projectsRef = useRef(projects);
  const promoDataRef = useRef(promoData);
  const [error, setError] = useState(false);
  const [previewView, setPreviewView] = useState(false);
  const [heroImage, setHeroImage] = useState(null);
  const [promoContent, setPromoContent] = useState("");
  // Image modal ///
  const [imageQuill, setImageQuill] = useState(null);
  const [openImageRegenerateModal, setOpenImageRegenerateModal] =
    useState(false);
  // Loading Modal ///
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [loadingModalMessage, setLoadingModalMessage] = useState("Loading...");
  const [generateLoader, setGenerateLoader] = useState(false);
  // Create Post
  const [publishLoader, setPublishLoader] = useState(false);
  const [openSelectProfileModal, setOpenSelectProfileModal] = useState(false);
  const [publishProfiles, setPublishProfiles] = useState([]);
  // Integration modal
  const [openIntegrationDialog, setOpenIntegrationDialog] = useState(false);
  const [integrationDialogMessage, setIntegrationDialogMessage] = useState(
    "Please connect service"
  );

  // fetch content
  useEffect(() => {
    projectsRef.current = projects;
    promoDataRef.current = promoData;
  }, [projects, promoData]);

  useEffect(() => {
    initPage();
    const timer = setInterval(async () => {
      initPage();
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  //START: Export list //
  const [isCopyContentOpen, setIsCopyContentOpen] = useState(false);
  const copyDropdownRef = useRef(null);
  const [isExportContentOpen, setIsExportContentOpen] = useState(false);
  const exportDropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        copyDropdownRef.current &&
        !copyDropdownRef.current.contains(event.target)
      ) {
        setIsCopyContentOpen(false);
      }
      if (
        exportDropdownRef.current &&
        !exportDropdownRef.current.contains(event.target)
      ) {
        setIsExportContentOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  /////START : EXPORT/////

  const handleCopyHtml = () => {
    let data =
      heroImage && heroImage.url && heroImage.url !== ""
        ? `<img src=${heroImage.url} /><br /><br />`
        : ""; // Replace with your HTML string
    data += `${promoContent}`;
    navigator.clipboard.writeText(data);
    dispatch(
      openNotification({
        severity: "success",
        message: "Content copied to clipboard",
      })
    );
    setIsCopyContentOpen(false);
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(promoContent.replace(/<[^>]*>/g, ""));
    dispatch(
      openNotification({
        severity: "success",
        message: "Content copied to clipboard",
      })
    );
    setIsCopyContentOpen(false);
  };

  const downloadHTMLFile = () => {
    let data =
      heroImage && heroImage.url && heroImage.url !== ""
        ? `<img src=${heroImage.url} /><br /><br />`
        : ""; // Replace with your HTML string
    data += `<p>${promoContent}</p>`;

    // Create a Blob containing the data
    const blob = new Blob([data], { type: "text/html" });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = topicId + ".html"; // Specify the desired file name

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setIsExportContentOpen(false);
  };

  const downloadWordFile = async () => {
    try {
      setIsExportContentOpen(false);
      const downloadData = await fetchBackendApiWrapper(
        `/api/v1/downloadWord?projectId=${projects.currentProject.id}&topicId=${topicId}&promotionType=${promotionType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (downloadData.ok) {
        const jsonData = await downloadData.json();
        window.open(jsonData.value, "_blank");
      }
    } catch (err) {
      console.error("Error in downloading word file: " + JSON.stringify(err));
    }
  };

  //END: Export list //

  const initPage = async () => {
    // console.log("Checking linkedin promo");
    if (
      projectsRef.current &&
      projectsRef.current.currentProject &&
      (promoDataRef.current === null || promoDataRef.current.status === 3)
    ) {
      // console.log("Inside linkedin promo");
      fetchBlogPromotionApi()
        .then((promo) => {
          setPromoData(promo);
          if (promo) {
            setHeroImage(promo.heroImage);
            setPromoContent(promo.socialContent);
          }
          setError(false);
        })
        .catch((err) => {
          setError(true);
        });
    }
  };

  const fetchBlogPromotionApi = async () => {
    try {
      const blogPromotionData = await fetchBackendApiWrapper(
        `/api/v1/blog/promotion/promotionByType?projectId=${projectsRef.current.currentProject.id}&topicId=${topicId}&promotionType=${promotionType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (blogPromotionData.status === 200) {
        const blogPromotionJson = await blogPromotionData.json();
        if (blogPromotionJson) {
          return blogPromotionJson;
        }
        return null;
      } else if (blogPromotionData.status === 204) {
        return null;
      } else {
        throw Error();
      }
    } catch (err) {
      console.error(
        "Error in fetching blog promotion : " + JSON.stringify(err)
      );
      throw err;
    }
  };

  const setContentChange = (value) => {
    setPromoContent(value);
  };

  const handleSaveChanges = async () => {
    let tmp = { ...promoData };
    const heroTmp = heroImage.url.startsWith("data:image")
      ? await uploadBase64Image(heroImage.url, topicId)
      : heroImage.url;
    setHeroImage({ ...heroImage, url: heroTmp });

    tmp = {
      ...tmp,
      heroImage: { ...heroImage, url: heroTmp },
      socialContent: promoContent,
    };
    setPromoData(tmp);
    // call save api
    setLoadingModalMessage("Saving...");
    setOpenLoadingModal(true);
    updateBlogPromotionApi(tmp)
      .then((data) => {
        setPromoData(data);
        setOpenLoadingModal(false);
        dispatch(
          openNotification({ message: "Save successfull", severity: "success" })
        );
        setSaveChange(false);
      })
      .catch((err) => {
        console.error(
          "Error in saving blog promotion data: " + JSON.stringify(err)
        );
        dispatch(
          openNotification({
            message: "Error in saving data",
            severity: "error",
          })
        );
        setOpenLoadingModal(false);
      });
  };

  const updateBlogPromotionApi = async (data) => {
    try {
      const blogPromotionData = await fetchBackendApiWrapper(
        `/api/v1/blog/promotion/save`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
        await auth.currentUser.getIdToken(false)
      );
      if (blogPromotionData.ok) {
        const blogPromotionJson = await blogPromotionData.json();
        return blogPromotionJson;
      }
    } catch (err) {
      console.error("Error in saving blog promotion : " + JSON.stringify(err));
      throw err;
    }
  };

  const handleGeneratePost = () => {
    if (generateLoader) return;
    if (promoData && promoData.status === 3) return;
    setGenerateLoader(true);
    generateBlogPromotionApi()
      .then(async (_) => {
        fetchBlogPromotionApi().then((promo) => {
          setPromoData(promo);
          if (promo) {
            setHeroImage(promo.heroImage);
            setPromoContent(promo.socialContent);
          }
          setError(false);
          setGenerateLoader(false);
        });
      })
      .catch((err) => {
        setGenerateLoader(false);
        console.error(
          "Error in generating blog promotion data: " + JSON.stringify(err)
        );
        dispatch(
          openNotification({
            message: "Error in generating promotional content",
            severity: "error",
          })
        );
      });
  };

  const generateBlogPromotionApi = async () => {
    try {
      const payload = {
        projectId: projects.currentProject.id,
        topicId: topicId,
        promotionType: promotionType,
        tone: tone,
        additionalInsights: additionalInsights,
        referenceLinks: referenceLinks,
        heroImage: heroImage,
      };
      const blogPromotionData = await fetchBackendApiWrapper(
        `/api/v1/blog/promotion/generate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        await auth.currentUser.getIdToken(false)
      );
      if (blogPromotionData.status === 202) {
        return "";
      }
    } catch (err) {
      console.error(
        "Error in generating blog promotion : " + JSON.stringify(err)
      );
      throw err;
    }
  };

  const handlePublishPost = async () => {
    // find porfiles , dialog , select dialog create post
    if (publishLoader) return;
    setPublishLoader(true);
    const fetchLinkedinProfilesApi = async () => {
      try {
        const profileData = await fetchBackendApiWrapper(
          `/api/v1/integration/linkedin/profile?projectId=${projects.currentProject.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          },
          await auth.currentUser.getIdToken(false)
        );
        if (profileData.ok) {
          const profilesJson = await profileData.json();
          setPublishProfiles(profilesJson);
          setPublishLoader(false);
        }
      } catch (err) {
        console.error(
          "Error in fetching linkedin profiles for post: " + JSON.stringify(err)
        );
        dispatch(
          openNotification({
            message:
              "Something went wrong in publishing post. Please try again.",
            severity: "error",
          })
        );
        setPublishLoader(false);
      }
    };
    const fetchIntegrationsApi = async () => {
      try {
        const responseData = await fetchBackendApiWrapper(
          `/api/v1/integration?projectId=${projects.currentProject.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          },
          await auth.currentUser.getIdToken(false)
        );
        if (responseData.ok) {
          responseData
            .json()
            .then(async (integrationData) => {
              if (
                integrationData &&
                integrationData.linkedinAuthData &&
                integrationData.linkedinAuthData.active &&
                integrationData.linkedinAuthData.accessToken
              ) {
                await fetchLinkedinProfilesApi();
              } else {
                throw Error("No integration data");
              }
            })
            .catch((err) => {
              setIntegrationDialogMessage(
                "Please connect LinkedIn to start publishing"
              );
              setOpenIntegrationDialog(true);
              setPublishLoader(false);
            });
        }
      } catch (err) {
        console.error(
          "Error in fetching integration data: " + JSON.stringify(err)
        );
        setPublishLoader(false);
      }
    };
    await fetchIntegrationsApi();
  };

  useEffect(() => {
    if (publishProfiles && publishProfiles.length > 0) {
      setOpenSelectProfileModal(true);
    }
  }, [publishProfiles]);

  const SelectProfileForPostModal = () => {
    const [selectedProfile, setSelectedProfile] = useState("");
    const [profileSelectLoader, setProfileSelectLoader] = useState(false);

    const handleSelectProfile = async () => {
      if (profileSelectLoader && selectedProfile !== "") return;
      setProfileSelectLoader(true);
      console.log("Selected: " + JSON.stringify(selectedProfile));
      const publishLinkedinApi = async () => {
        const tmp = JSON.parse(selectedProfile);
        try {
          const payload = {
            id: tmp.id,
            type: tmp.type,
            projectId: projects.currentProject.id,
            topicId: topicId,
          };
          const publishData = await fetchBackendApiWrapper(
            `/api/v1/integration/linkedin/post?projectId=${projects.currentProject.id}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            },
            await auth.currentUser.getIdToken(false)
          );
          if (publishData.ok) {
            console.log("Post published success");
            dispatch(
              openNotification({
                message: "Post published successfully",
                severity: "success",
              })
            );
          }
        } catch (err) {
          console.error(
            "Error in publishing linkedin for post: " + JSON.stringify(err)
          );
          dispatch(
            openNotification({
              message:
                "Something went wrong in publishing post. Please try again.",
              severity: "error",
            })
          );
        }
      };
      await publishLinkedinApi();
      setProfileSelectLoader(false);
      setOpenSelectProfileModal(false);
    };

    return (
      <Dialog
        open={openSelectProfileModal}
        onClose={() => setOpenSelectProfileModal(false)}
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-start items-start relative w-full gap-4 p-8">
          <div className="flex justify-between items-center w-full relative">
            <div
              onClick={() => setOpenSelectProfileModal(false)}
              className="cursor-pointer rounded-full p-2 bg-gray-100"
            >
              <ClearIcon />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium">
              Select the profile or organisation where you want to publish post
            </div>
            <div>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
                value={selectedProfile}
                onChange={(event) => setSelectedProfile(event.target.value)}
              >
                <option value=""></option>
                {publishProfiles.map((c) => (
                  <option key={c.id} value={JSON.stringify(c)}>
                    {c.vanityName}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={handleSelectProfile}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2 text-sm relative w-full font-medium"
            >
              {profileSelectLoader ? (
                <CircularProgress
                  size="1rem"
                  variant="indeterminate"
                  sx={{ color: "white" }}
                />
              ) : (
                <div>Select and Publish</div>
              )}
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      {!previewView && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between items-start w-full relative gap-32 mb-20">
          <>
            <div className="col-span-2">
              {error && (
                <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white mt-8">
                  <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
                    <div className="font-medium">
                      Something went wrong please try again
                    </div>
                  </div>
                </div>
              )}
              {!error && promoData && promoData.status === 3 && (
                <div className="flex justify-center items-center w-full relative">
                  <LoadingPageSkeleton />
                </div>
              )}
              {!error && (promoData === null || promoData.status === 0) && (
                <div>
                  <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-8 bg-gray-200">
                    <div className="text-sm">Instructions</div>
                  </div>
                  <div className="border p-2 md:p-10 rounded-br-lg rounded-bl-lg border-gray-300 flex justify-center items-center w-full relative">
                    <div className="flex flex-col justify-start items-start w-full relative p-6">
                      <div>
                        <strong>Select Tone: </strong>Choose the tone that suits
                        your style the best.
                      </div>
                      <div>
                        <strong>Additional Information: </strong>Add any
                        additional information that you want the post to have.
                      </div>
                      <div>
                        <strong>Reference Links: </strong>Add any reference
                        links that you want the post to have.
                      </div>
                      <div>
                        <strong>Generate Post: </strong>Press this button to
                        start creating the blog.
                      </div>
                      <div>
                        <strong>Preview: </strong>Use this button to catch a
                        glimpse of your content.
                      </div>
                      <div className="mt-6">
                        <button
                          onClick={handleGeneratePost}
                          className="bg-thbBlue rounded-md px-8 py-2 text-white"
                        >
                          Generate Post
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {promoData && promoData.status === 1 && (
                <div>
                  {promoContent && promoContent !== "" && (
                    <div className="text-sm text-gray-800 my-4">
                      Character Count:{" "}
                      <span
                        className={`${
                          promoContent.length > 3000
                            ? "text-red-500"
                            : "text-black"
                        }`}
                      >
                        {promoContent.length} / 3000
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-8 bg-gray-200">
                    <div className="text-sm">LinkedIn Image</div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setImageQuill(null);
                          setOpenImageRegenerateModal(true);
                        }}
                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                      >
                        {heroImage.url && heroImage.url !== ""
                          ? "Update Image"
                          : "Add Image"}
                      </button>
                      <button
                        onClick={() => {
                          setHeroImage({ ...heroImage, url: "" });
                          setSaveChange(true);
                        }}
                        className="text-xs bg-black text-white rounded-lg p-1"
                      >
                        Remove Image
                      </button>
                    </div>
                  </div>
                  <div className="border p-10 rounded-br-lg rounded-bl-lg border-gray-300 flex justify-center items-center w-full relative">
                    {heroImage &&
                      heroImage !== "" &&
                      heroImage.url &&
                      heroImage.url !== "" && (
                        <img src={heroImage.url} alt="" className="max-h-64" />
                      )}
                  </div>
                </div>
              )}
              {promoData && promoData.status === 1 && (
                <div>
                  <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                    <div className="text-sm">LinkedIn Post</div>
                    <div>
                      {/* <button className="text-xs bg-thbBlue text-white rounded-lg p-1">
                      Regenerate
                    </button> */}
                    </div>
                  </div>
                  <PromotionCustomQuillToolbar toolbarId={"toolbarTldr"} />
                  <ReactQuill
                    ref={quillLinkedIn}
                    value={promoContent}
                    modules={modules("toolbarTldr")}
                    formats={formats}
                    onChange={(value) => {
                      setContentChange(value);
                      setSaveChange(true);
                    }}
                  />
                </div>
              )}
              <ImageRegenerateComponent
                openImageRegenerateModal={openImageRegenerateModal}
                setOpenImageRegenerateModal={setOpenImageRegenerateModal}
                heroImage={heroImage}
                setHeroImage={setHeroImage}
                setSaveChange={setSaveChange}
                topicId={topicId}
                setOpenLoadingModal={setOpenLoadingModal}
                quill={imageQuill}
                projectId={projects.currentProject.id}
                setLoadingModalMessage={setLoadingModalMessage}
              />
              <LoadingModal
                openLoadingModal={openLoadingModal}
                setOpenLoadingModal={setOpenLoadingModal}
                message={loadingModalMessage}
              />
              <IntegrationDialog
                message={integrationDialogMessage}
                openIntegrationDialog={openIntegrationDialog}
                setOpenIntegrationDialog={setOpenIntegrationDialog}
              />
            </div>
            <div className="col-span-1 justify-start items-start w-full">
              <div className="flex flex-col justify-start items-stretch mt-8 gap-2">
                {promoData && promoData.status === 1 && (
                  <div className="w-full relative mb-2">
                    <button
                      onClick={handlePublishPost}
                      className="w-full px-2 py-2 bg-gray-900 text-white rounded-md text-sm font-medium flex justify-center items-center gap-2"
                    >
                      {publishLoader ? (
                        <CircularProgress
                          size="1rem"
                          variant="indeterminate"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <>
                          <SendRoundedIcon
                            className="-rotate-45"
                            sx={{ fontSize: "1rem" }}
                          />
                          <div>Publish</div>
                        </>
                      )}
                    </button>
                  </div>
                )}
                <div className="flex justify-start items-center w-full relative gap-4">
                  {!error && (
                    <button
                      onClick={handleSaveChanges}
                      className={`text-sm rounded-lg ${
                        saveChange ? "bg-thbBlue" : "bg-slate-300"
                      } p-2 text-white font-semibold shadow-sm flex justify-center items-center gap-2`}
                    >
                      <SaveIcon />
                      <div>{saveChange ? "Save" : "Saved"}</div>
                    </button>
                  )}
                  {(!promoData || promoData.status !== 1) && (
                    <button
                      className={`text-sm rounded-lg bg-gray-300 p-2 text-white font-semibold shadow-sm flex justify-center items-center gap-2 pl-4 pr-4 cursor-not-allowed`}
                    >
                      <VisibilityOutlinedIcon />
                      <div>Preview</div>
                    </button>
                  )}
                  {promoData && promoData.status === 1 && (
                    <button
                      onClick={() => setPreviewView((prev) => !prev)}
                      className={`text-sm rounded-lg bg-orange-100 p-2 text-orange-500 font-semibold shadow-sm flex justify-center items-center gap-2 pl-4 pr-4`}
                    >
                      <VisibilityOutlinedIcon />
                      <div>Preview</div>
                    </button>
                  )}
                  {promoData && promoData.status === 1 && (
                    <div className="flex justify-end items-center gap-4">
                      {/* <div
                        ref={copyDropdownRef}
                        className="inline-block relative"
                      >
                        <button
                          onClick={() => setIsCopyContentOpen((prev) => !prev)}
                          className="rounded-lg p-2 text-xs bg-white-400 text-gray-500 shadow-lg border border-gray-500"
                        >
                          <ContentCopyIcon sx={{ fontSize: "1rem" }} />
                        </button>
                        {isCopyContentOpen && (
                          <div className="mt-2 right-0 left-auto absolute z-10 shadow-lg rounded-md flex flex-col justify-start items-start gap-2 w-40 border border-gray-500 bg-white">
                            <div
                              onClick={handleCopyHtml}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Copy <HTML>`}</div>
                            <div
                              onClick={handleCopyText}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Copy Text`}</div>
                          </div>
                        )}
                      </div> */}
                      <div
                        ref={exportDropdownRef}
                        className="inline-block relative"
                      >
                        <button
                          onClick={() =>
                            setIsExportContentOpen((prev) => !prev)
                          }
                          className="rounded-lg p-2 text-xs bg-white-400 text-gray-500 shadow-lg border border-gray-500"
                        >
                          <FileDownloadIcon sx={{ fontSize: "1rem" }} />
                        </button>
                        {isExportContentOpen && (
                          <div className="mt-2 right-0 left-auto absolute z-10 shadow-lg rounded-md flex flex-col justify-start items-start gap-2 w-40 border border-gray-500 bg-white">
                            <div
                              onClick={handleCopyHtml}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Copy <HTML>`}</div>
                            <div
                              onClick={handleCopyText}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Copy Text`}</div>
                            <div
                              onClick={downloadHTMLFile}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Export HTML`}</div>
                            <div
                              onClick={downloadWordFile}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Export Word`}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col justify-start items-stretch mt-16 gap-2">
                <div className="text-light text-sm">Tone</div>
                <select
                  value={tone}
                  onChange={(event) => setTone(event.target.value)}
                  className="p-2 rounded-md border border-gray-300 bg-white text-light text-sm"
                >
                  <option value="Friendly">Friendly</option>
                  <option value="Informational">Informational</option>
                  <option value="Transactional">Transactional</option>
                  <option value="Inspirational">Inspirational</option>
                  <option value="Professional">Professional</option>
                </select>
              </div>
              <div className="flex flex-col justify-start items-stretch gap-2 mt-6">
                <div className="text-light text-sm">
                  Add Insights (optional)
                </div>
                <textarea
                  rows={3}
                  value={additionalInsights}
                  onChange={(event) =>
                    setAdditionalInsights(event.target.value)
                  }
                  placeholder="Tell us specific things you want to showcase"
                  className="border rounded-lg border-gray-300 bg-white text-light text-sm p-2"
                />
              </div>
              <div className="flex flex-col justify-start items-stretch gap-2 mt-6">
                <div className="text-light text-sm">
                  Reference Links (optional)
                </div>
                <textarea
                  rows={4}
                  value={referenceLinks}
                  onChange={(event) => setReferenceLinks(event.target.value)}
                  placeholder="Add links to products, research articles, other blogs etc. to add more context to this blog. Separate multiple links with commas."
                  className="border rounded-lg border-gray-300 bg-white text-light text-sm p-2"
                />
              </div>
              {!error && (
                <button
                  onClick={handleGeneratePost}
                  className="p-2 rounded-lg bg-thbBlue text-white w-full mt-6"
                >
                  {promoData === null && !generateLoader
                    ? "Generate Post"
                    : "Rewrite Post"}
                  {promoData === null && generateLoader && (
                    <CircularProgress
                      size="1rem"
                      variant="indeterminate"
                      sx={{ color: "white" }}
                    />
                  )}
                </button>
              )}
            </div>
          </>
        </div>
      )}
      {previewView && (
        <div className="flex flex-col justify-start items-center w-full relative">
          <>
            <div className="mt-8 flex flex-col justify-start items-start w-1/2 relative p-8 rounded-lg shadow-2xl mb-20">
              <div className="px-4 flex justify-between items-center w-full relative">
                <div className="flex justify-start items-center gap-4 w-full relative">
                  <div className="bg-white w-6 h-6 flex justify-center items-center rounded-md">
                    <svg
                      className={`w-6 h-6 text-blue-500`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M17.0392 17.0433H14.0775V12.4025C14.0775 11.2958 14.055 9.87167 12.5342 9.87167C10.99 9.87167 10.7542 11.0758 10.7542 12.3208V17.0433H7.7925V7.5H10.6375V8.80083H10.6758C11.0733 8.05083 12.04 7.25917 13.4842 7.25917C16.485 7.25917 17.04 9.23417 17.04 11.805L17.0392 17.0433ZM4.4475 6.19417C3.49417 6.19417 2.72833 5.4225 2.72833 4.47333C2.72833 3.525 3.495 2.75417 4.4475 2.75417C5.3975 2.75417 6.1675 3.525 6.1675 4.47333C6.1675 5.4225 5.39667 6.19417 4.4475 6.19417ZM5.9325 17.0433H2.9625V7.5H5.9325V17.0433ZM18.5208 0H1.47583C0.66 0 0 0.645 0 1.44083V18.5592C0 19.3558 0.66 20 1.47583 20H18.5183C19.3333 20 20 19.3558 20 18.5592V1.44083C20 0.645 19.3333 0 18.5183 0H18.5208Z" />
                      <defs>
                        <clipPath id="clip0_396_3269">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="font-medium">LinkedIn</div>
                </div>
                <div className="flex justify-end gap-2 items-center">
                  <div
                    onClick={() => setPreviewView(false)}
                    className="px-2 py-1 rounded-lg cursor-pointer flex justify-center items-center gap-2 bg-black text-white font-medium"
                  >
                    <CreateOutlinedIcon sx={{ fontSize: "1rem" }} />
                    Edit
                  </div>
                  {/* <div ref={copyDropdownRef} className="inline-block relative">
                    <button
                      onClick={() => setIsCopyContentOpen((prev) => !prev)}
                      className="rounded-lg p-2 text-xs bg-white-400 text-gray-500 shadow-lg border border-gray-500"
                    >
                      <ContentCopyIcon sx={{ fontSize: "1rem" }} />
                    </button>
                    {isCopyContentOpen && (
                      <div className="mt-2 right-0 left-auto absolute z-10 shadow-lg rounded-md flex flex-col justify-start items-start gap-2 w-40 border border-gray-500 bg-white">
                        <div
                          onClick={handleCopyHtml}
                          className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                        >{`Copy <HTML>`}</div>
                        <div
                          onClick={handleCopyText}
                          className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                        >{`Copy Text`}</div>
                      </div>
                    )}
                  </div> */}
                  <div
                    ref={exportDropdownRef}
                    className="inline-block relative"
                  >
                    <button
                      onClick={() => setIsExportContentOpen((prev) => !prev)}
                      className="rounded-lg p-2 text-xs bg-white-400 text-gray-500 shadow-lg border border-gray-500"
                    >
                      <FileDownloadIcon sx={{ fontSize: "1rem" }} />
                    </button>
                    {isExportContentOpen && (
                      <div className="mt-2 right-0 left-auto absolute z-10 shadow-lg rounded-md flex flex-col justify-start items-start gap-2 w-40 border border-gray-500 bg-white">
                        <div
                          onClick={handleCopyHtml}
                          className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                        >{`Copy <HTML>`}</div>
                        <div
                          onClick={handleCopyText}
                          className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                        >{`Copy Text`}</div>
                        <div
                          onClick={downloadHTMLFile}
                          className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                        >{`Export HTML`}</div>
                        <div
                          onClick={downloadWordFile}
                          className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                        >{`Export Word`}</div>
                      </div>
                    )}
                  </div>
                  {promoData && promoData.status === 1 && (
                    <div className="w-full relative mb-2">
                      <button
                        onClick={handlePublishPost}
                        className="w-full px-2 py-1 bg-black text-white rounded-lg font-medium flex justify-center items-center gap-2"
                      >
                        {publishLoader ? (
                          <CircularProgress
                            size="1rem"
                            variant="indeterminate"
                            sx={{ color: "white" }}
                          />
                        ) : (
                          <>
                            <SendRoundedIcon
                              className="-rotate-45"
                              sx={{ fontSize: "1rem" }}
                            />
                            <div>Publish</div>
                          </>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <hr className="w-full relative bg-gray h-2 my-4" />
              <div className="px-4 w-full relative">
                <div className="flex justify-center items-center w-full relative">
                  {heroImage && heroImage.url && heroImage.url !== "" && (
                    <img
                      src={heroImage.url}
                      alt=""
                      height={800}
                      width={450}
                      className="rounded-md"
                    />
                  )}
                </div>
                <div className="text-justify text-lg p-10">
                  {promoContent.replace(/<[^>]*>/g, "")}
                </div>
              </div>
            </div>
          </>
        </div>
      )}
      <SelectProfileForPostModal />
    </>
  );
};

export default LinkedInPromotion;
