import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import VerifyEmail from "../../components/VerifyEmail";
import { fetchBackendApiWrapper } from "../../utils/apiWrapper";
import {
  setCurrentProject,
  setProjectCredits,
  setProjectsData,
} from "../../slice/projectsDataSlice";
import LoadingPageSkeleton from "../../components/LoadingPageSkeleton";
import { setUserDetail } from "../../slice/userDataSlice";
import { Dialog } from "@mui/material";
import {
  closeBlogNotification,
  openBlogNotification,
} from "../../slice/blogNotificationSlice";
import { openNotification } from "../../slice/notificationSlice";
import {
  addStoreDraftTopics,
  setStoreNewTopics,
} from "../../slice/topicSelectionSlice";
import { closeMessageDialog } from "../../slice/messageDialogSlice";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initLoading, setInitLoading] = useState(true);
  const [emailVerifyView, setEmailVerifyView] = useState(false);
  const authData = useSelector((state) => state.authStore);
  const [error, setError] = useState(false);
  const [searchParam] = useSearchParams();
  const blogNotification = useSelector((state) => state.blogNotification);
  const commonMessageDialog = useSelector((state) => state.commonMessageDialog);
  const topicSelection = useSelector((state) => state.topicSelection);
  const projects = useSelector((state) => state.projects);
  const topicsRef = useRef(topicSelection);
  const projectsRef = useRef(projects);
  const authDataRef = useRef(authData);

  useEffect(() => {
    initPage();
  }, [authData]);

  const initPage = async () => {
    try {
      if (authData.currentUser && authData.accessToken) {
        if (authData.currentUser.emailVerified) {
          const projectsRes = await fetchUserProjects();
          const userDetailRes = await fetchUserDetails();
          if (
            projectsRes !== null &&
            projectsRes !== undefined &&
            projectsRes.length > 0 &&
            userDetailRes
          ) {
            setInitLoading(false);
            setEmailVerifyView(false);
          }
        } else {
          setInitLoading(false);
          setEmailVerifyView(true);
        }
      } else if (authData.currentUser === null) {
        console.error("No logged in user found. Redirecting to login");
        setInitLoading(false);
        navigate("/login", { replace: "true" });
      }
    } catch (err) {
      console.error("Error in initPage : " + JSON.stringify(err));
    }
  };

  const fetchUserProjects = async () => {
    const authToken = authData.accessToken;
    const res = await fetchBackendApiWrapper(
      "/api/v1/project/userProjects",
      {
        method: "GET",
      },
      authToken
    );
    if (res !== null && res !== undefined && res.ok) {
      const projectsDataJson = await res.json();
      console.log("Projects Data: " + JSON.stringify(projectsDataJson));
      dispatch(setProjectsData(projectsDataJson));
      if (projectsDataJson.length > 0) {
        const localCurrentProjectId =
          localStorage.getItem("thbCurrentProject") === null
            ? null
            : JSON.parse(localStorage.getItem("thbCurrentProject"));
        let curProjectTmp =
          localCurrentProjectId === null
            ? projectsDataJson[0]
            : projectsDataJson.find((pj) => pj.id === localCurrentProjectId);
        curProjectTmp = curProjectTmp ? curProjectTmp : projectsDataJson[0];
        dispatch(setCurrentProject(curProjectTmp));
        localStorage.setItem("thbCurrentProject", curProjectTmp.id);
        await fetchProjectCredits(curProjectTmp.id);
      } else {
        dispatch(setCurrentProject(null));
      }
      return projectsDataJson;
    } else {
      console.error("Error in fetching project details: ");
      dispatch(setCurrentProject(null));
      dispatch(setProjectsData(null));
      setError(true);
      return null;
    }
  };

  const fetchUserDetails = async () => {
    const authToken = authData.accessToken;
    const res = await fetchBackendApiWrapper(
      "/api/v1/userDetails",
      {
        method: "GET",
      },
      authToken
    );
    if (res !== null && res !== undefined && res.ok) {
      const userDataJson = await res.json();
      // console.log("User Data: " + JSON.stringify(userDataJson));
      dispatch(setUserDetail(userDataJson));
      return userDataJson;
    } else {
      console.error("Error in fetching user details: ");
      setError(true);
      return null;
    }
  };

  const fetchProjectCredits = async (projectId) => {
    const authToken = authData.accessToken;
    const res = await fetchBackendApiWrapper(
      `/api/v1/getProjectCredits?projectId=${projectId}`,
      {
        method: "GET",
      },
      authToken
    );
    if (res !== null && res !== undefined && res.ok) {
      const projectCreditsJson = await res.json();
      console.log(
        "Project Credits Data: " + JSON.stringify(projectCreditsJson)
      );
      dispatch(setProjectCredits(projectCreditsJson));
      return projectCreditsJson;
    } else {
      console.error("Error in fetching project credit details: ");
      setError(true);
      return null;
    }
  };

  useEffect(() => {
    const timer = setInterval(async () => {
      checkStateOfWritingBlogs();
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  const checkStateOfWritingBlogs = async () => {
    console.log("Checking writing blog states ");
    if (
      projectsRef.current &&
      projectsRef.current.currentProject &&
      authDataRef.current.accessToken &&
      topicsRef.current &&
      topicsRef.current.newTopics &&
      topicsRef.current.newTopics.length > 0
    ) {
      const writingBlogs = topicsRef.current.newTopics.filter(
        (t) => t.publishState === 3
      );
      if (writingBlogs.length > 0) {
        console.log(
          "Found " + writingBlogs.length + " writing blog. Calling api"
        );
        writingBlogs.forEach((bg) => {
          fetchTopicApi(bg)
            .then((tp) => {
              if (tp === null) {
                return;
              }
              if (tp.publishState === 1) {
                const searchParam = new URLSearchParams();
                searchParam.set("topicId", tp.topicId);
                const url = `/dashboard/topics/blog?${searchParam.toString()}`;
                const msg = {
                  msg: "Your blog is written successfully.",
                  source: url,
                  open: true,
                };
                dispatch(openBlogNotification(msg));
                dispatch(
                  openNotification({
                    message:
                      "Your blog is generated successfully. You can view it in In-Progress tab",
                    severity: "success",
                  })
                );
                let tmp = topicsRef.current.newTopics.filter(
                  (x) => x.topicId !== tp.topicId
                );
                console.log(tmp);
                dispatch(setStoreNewTopics(tmp));
                dispatch(addStoreDraftTopics([tp]));
              }
              if (tp.publishState === 0) {
                let tmp = topicsRef.current.newTopics.map((x) => {
                  if (x.topicId === tp.topicId) {
                    return tp;
                  }
                  return x;
                });
                dispatch(setStoreNewTopics(tmp));

                // dispatch(
                //   openNotification({
                //     message: "Error in blog generation",
                //     severity: "error",
                //   })
                // );
              }
            })
            .catch((err) => {
              console.log(
                "Error in fetching topic state of topicId: " +
                  JSON.stringify(bg.topicId) +
                  " Error: " +
                  JSON.stringify(err)
              );
            });
        });
      }
    }
  };

  useEffect(() => {
    topicsRef.current = topicSelection;
    projectsRef.current = projects;
    authDataRef.current = authData;
  }, [topicSelection, projects, authData]);

  const fetchTopicApi = async (kws) => {
    try {
      const kwData = await fetchBackendApiWrapper(
        `/api/v1/topic?projectId=${projectsRef.current.currentProject.id}&topicId=${kws.topicId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        authDataRef.current.accessToken
      );
      if (kwData.status === 200) {
        const kwJson = await kwData.json();
        if (kwJson !== null && kwJson !== undefined) {
          return kwJson;
        }
        return null;
      } else if (kwData.status === 204) {
        return null;
      }
    } catch (err) {
      console.error("Error in fetching topic : " + JSON.stringify(err));
      return null;
    }
  };

  const BlogStatusDialog = () => {
    return (
      <Dialog
        open={blogNotification.open}
        onClose={() => dispatch(closeBlogNotification())}
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-start items-start w-full relative rounded-lg p-12 gap-6">
          <div className="font-medium text-center w-full relative">
            {blogNotification.msg}
          </div>
          <div className="flex justify-end items-center w-full relative gap-4">
            <button
              onClick={() => {
                if (blogNotification.source) {
                  navigate(blogNotification.source);
                }
                dispatch(closeBlogNotification());
              }}
              className="px-4 py-2 font-medium border bg-black text-white  rounded-md text-sm"
            >
              {blogNotification.source ? "Open Blog" : "OK"}
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  const OnboardingDoneDialog = () => {
    return (
      <Dialog
        open={commonMessageDialog.open}
        onClose={() => dispatch(closeMessageDialog())}
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-start items-start w-full relative rounded-lg p-12 gap-6">
          <div className="font-medium text-justify w-full relative">
            {commonMessageDialog.msg}
          </div>
          <div className="flex justify-center items-center w-full relative gap-4">
            <button
              onClick={() => {
                dispatch(closeMessageDialog());
              }}
              className="px-4 py-2 font-medium border bg-black text-white  rounded-md text-sm"
            >
              Got it
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      <div className="flex w-full relative flex-row justify-center items-center font-montserrat">
        {error && (
          <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
            <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
              <div className="font-medium">
                Something went wrong please try again
              </div>
            </div>
          </div>
        )}
        {!error && initLoading && (
          <div className="flex w-full justify-center mt-12 items-center">
            <LoadingPageSkeleton />
          </div>
        )}
        {!error && !initLoading && emailVerifyView && <VerifyEmail />}
      </div>
      {!error && !initLoading && !emailVerifyView && (
        <div className="font-montserrat">
          <Outlet />
          <BlogStatusDialog />
          <OnboardingDoneDialog />
        </div>
      )}
    </>
  );
};

export default Dashboard;
