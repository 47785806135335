import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { setAccessToken, setCurrentUser } from "../slice/authSlice";
import { auth } from "../auth/firebaseConfig";
import Dashboard from "../pages/dashboard/Dashboard";
import ReactGA from "react-ga4";

const DashboardInitialiserWrapper = () => {
  const dispatch = useDispatch();
  const GA_MEASUREMENT_ID = "G-N8HXHR05DT";
  ReactGA.initialize(GA_MEASUREMENT_ID);

  useEffect(() => {
    const firebaseAuthListner = onAuthStateChanged(auth, async (user) => {
      if (user) {
        dispatch(setCurrentUser(user));
        const token = await user.getIdToken(false);
        dispatch(setAccessToken(token));
      } else {
        dispatch(setCurrentUser(null));
      }
    });

    return () => {
      firebaseAuthListner();
    };
  }, [dispatch]);

  return <Dashboard />;
};

export default DashboardInitialiserWrapper;
