import { Drawer } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const TopicSelectionGuide = ({ openGuide, setOpenGuide }) => {
  return (
    <Drawer
      anchor="right"
      open={openGuide}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: { xs: 200, sm: 300, lg: 420 },
        },
      }}
      onClose={() => setOpenGuide(false)}
    >
      <div className="flex flex-col justify-start items-start relative">
        <div
          className="flex justify-between items-center w-full relative px-6 py-8 bg-blue-50"
          style={{
            backgroundImage: `url("/images/guideBg.svg")`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="text-blue-500 font-semibold text-xl">Guide</div>
          <div
            onClick={() => setOpenGuide(false)}
            className="cursor-pointer rounded-full p-2 bg-gray-100"
          >
            <ClearIcon />
          </div>
        </div>
        <div className="flex flex-col justify-start items-start p-6 gap-2">
          <div className="text-sm">
            This is the Topic Selection page, where you can view the topics for
            the keywords that are currently selected in Keywords Selection Page.
            From this page you can view topics, edit topics, add your own topic
            or can remove any topic. And the most important you can generate
            content on the topic that you want to write about.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">New Topics</div>
          <div className="text-sm">
            These are the topics for which you have not generated content for.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">In-progress</div>
          <div className="text-sm">
            These are the topics for which you have generated content for and is
            currently not published and are in draft phase.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Published</div>
          <div className="text-sm">
            These are the topics for which you have published the content.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Intent</div>
          <div className="text-sm">
            The purpose of a search in a search engine. Intents can be read by
            the search engine algorithms to show the proper results and SERP
            features.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Volume</div>
          <div className="text-sm">
            The average number of monthly searches for the analyzed keyword over
            the last 12 months.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Difficulty</div>
          <div className="text-sm">
            Keyword Difficulty shows you how hard it would be for a website to
            rank organically in the Google top 10 for the analyzed keyword. The
            higher the percentage, the harder it will be to achieve high
            rankings.
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default TopicSelectionGuide;
