import { CircularProgress, Dialog } from "@mui/material";

const LoadingModal = ({ openLoadingModal, setOpenLoadingModal, message }) => {
  return (
    <Dialog
      open={openLoadingModal}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setOpenLoadingModal(false);
      }}
      fullWidth
      maxWidth={"xl"}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      className="backdrop-blur-sm"
    >
      <div className="flex flex-col justify-center items-center gap-2">
        <CircularProgress
          size="2rem"
          variant="indeterminate"
          sx={{ color: "white" }}
        />
        <div className="text-white text-xl">{message}</div>
      </div>
    </Dialog>
  );
};

export default LoadingModal;
