import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import ErrorPage from "./pages/errors/ErrorPage";
import DashboardInitialiserWrapper from "./components/DashboardInitialisationWrapper";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "@emotion/react";
import Notification from "./components/Notification";
import { theme } from "./utils/theme";
import Home from "./pages/home/Home";
import LoginPage from "./pages/login/Login";
import SignupPage from "./pages/signup/Signup";
import Onboarding from "./pages/onboarding/Onboarding";
import GettingStarted from "./pages/dashboard/gettingStarted/GettingStarted";
import VerifyEmailPage from "./pages/verifyEmail/VerifyEmail";
import TopicSelection from "./pages/dashboard/topics/TopicSelection";
import TopicWriteBlogPage from "./pages/dashboard/topics/blog/TopicWriteBlog";
import KeywordSelection from "./pages/dashboard/keywordSelection/KeywordSelection";
import AccountSettings from "./pages/dashboard/settings/Account";
import BrandSettings from "./pages/dashboard/settings/Brands";
import SubscriptionSettings from "./pages/dashboard/settings/Subscription";
import PlanInfoSettings from "./pages/dashboard/settings/PlanInfo";
import AddBrandPage from "./pages/dashboard/addBrand/AddBrandPage";
import PurchaseComplete from "./pages/dashboard/purchaseComplete/PurchaseComplete";
import PromotionMain from "./pages/dashboard/topics/blog/promotion/PromtionMain";
import Integration from "./pages/dashboard/integration/Integration";
import Analytics from "./pages/dashboard/analytics/Analytics";
import SupportSettings from "./pages/dashboard/settings/Support";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/error",
      element: <ErrorPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/signup",
      element: <SignupPage />,
    },
    {
      path: "purchaseComplete",
      element: <PurchaseComplete />,
    },
    {
      path: "/dashboard",
      element: <DashboardInitialiserWrapper />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "onboarding",
          element: <Onboarding />,
        },
        {
          path: "addBrand",
          element: <AddBrandPage />,
        },
        // {
        //   path: "getting-started",
        //   element: <GettingStarted />,
        // },
        {
          path: "topic-selection",
          element: <TopicSelection />,
        },
        {
          path: "keyword-selection",
          element: <KeywordSelection />,
        },
        {
          path: "integration",
          element: <Integration />,
        },
        {
          path: "analytics",
          element: <Analytics />,
        },
        {
          path: "topics/blog",
          element: <TopicWriteBlogPage />,
        },
        {
          path: "topics/blog/promotion",
          element: <PromotionMain />,
        },
        {
          path: "settings/account",
          element: <AccountSettings />,
        },
        {
          path: "settings/brand",
          element: <BrandSettings />,
        },
        {
          path: "settings/subscription",
          element: <SubscriptionSettings />,
        },
        {
          path: "settings/plans",
          element: <PlanInfoSettings />,
        },
        {
          path: "settings/support",
          element: <SupportSettings />,
        },
      ],
    },
    {
      path: "verifyEmail",
      element: <VerifyEmailPage />,
    },
  ]);

  return (
    <div className="thb-app">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Notification />
          <RouterProvider router={router} />
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
