import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  console.error(error);

  return (
    <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
      <div className="mt-8">
        <a href="https://thehyperbrand.com/">
          <img
            src="/images/THB.svg"
            alt="TheHyperbrand Logo"
            width={140}
            height={32}
          />
        </a>
      </div>
      <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20 gap-10">
        <div className="font-medium">Something went wrong please try again</div>
        <button
          onClick={() =>
            navigate("/dashboard/keyword-selection", { replace: "true" })
          }
          className="bg-thbBlue px-6 py-2 font-semibold rounded-md text-white"
        >
          Go Home
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
