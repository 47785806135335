import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBackendApiWrapper } from "../utils/apiWrapper";
import { setCurrentProject, setProjectsData } from "../slice/projectsDataSlice";
import { openNotification } from "../slice/notificationSlice";
import { CircularProgress, Dialog } from "@mui/material";
import { auth } from "../auth/firebaseConfig";

const Pricing = ({ pricing, monthly, setMonthly }) => {
  // const authData = useSelector((state) => state.authStore);
  const projects = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const [openAlreadySubscribedDialog, setOpenAlreadySubscribedDialog] =
    useState(false);
  const [openCheckoutDialog, setOpenCheckoutDialog] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [curProject, setCurProject] = useState(null);
  const [subscribeLoader, setSubscribeLoader] = useState(false);

  const handleSubscribe = async () => {
    if (subscribeLoader) return;
    setSubscribeLoader(true);
    await fetchUserProjects();
    setSubscribeLoader(false);
  };

  const fetchUserProjects = async () => {
    try {
      const authToken = await auth.currentUser.getIdToken(false);
      const res = await fetchBackendApiWrapper(
        "/api/v1/project/userProjects",
        {
          method: "GET",
        },
        authToken
      );
      if (res && res.ok) {
        const projectsDataJson = await res.json();
        dispatch(setProjectsData(projectsDataJson));
        if (projectsDataJson.length > 0) {
          const curProjectTmp = projectsDataJson.find(
            (pj) => pj.id === projects.currentProject.id
          );
          dispatch(setCurrentProject(curProjectTmp));
          setCurProject(curProjectTmp);
          if (curProjectTmp.isActiveSubscription === true) {
            setOpenAlreadySubscribedDialog(true);
          } else {
            await fetchPaymentDetails();
          }
        }
      }
    } catch (err) {
      console.error("Error in fetching project details");
      dispatch(
        openNotification({
          message:
            "Error in purchasing plan. Please refresh the page and try again.",
          severity: "error",
        })
      );
    }
  };

  const fetchPaymentDetails = async () => {
    try {
      const res = await fetchBackendApiWrapper(
        `/api/v1/payment/project?projectId=${projects.currentProject.id}&paymentStatus=2`,
        {
          method: "GET",
        },
        await auth.currentUser.getIdToken(false)
      );
      if (res && res.ok) {
        const data = await res.json();
        console.log(
          "Successfully got payment details: " + JSON.stringify(data)
        );
        if (data.length === 0) {
          setOpenCheckoutDialog(true);
          setNewUser(true);
        } else if (data.length > 0) {
          setOpenCheckoutDialog(true);
          setNewUser(false);
        }
      }
    } catch (err) {
      console.error(
        "Error in fetching payment details: " + JSON.stringify(err)
      );
      dispatch(
        openNotification({
          message:
            "Error in purchasing plan. Please refresh the page and try again.",
          severity: "error",
        })
      );
    }
  };

  const AlreadySubscribedDialog = () => {
    return (
      <Dialog
        open={openAlreadySubscribedDialog}
        onClose={() => setOpenAlreadySubscribedDialog(false)}
        fullWidth
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-start items-center w-full relative p-16 gap-10">
          <div className="text-center">
            {`You already have an active subscription for the current project. 
              If you want to add another brand than goto brands -> Add Brand. 
              If you want to upgrade please contact support. 
              Otherwise, please wait till your subscription ends and than purchase subscription.`}
          </div>
          <div>
            <button
              onClick={() => setOpenAlreadySubscribedDialog(false)}
              className="bg-blue-500 text-white px-6 py-4 rounded-md font-semibold"
            >
              OK
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  const CheckoutDialog = ({ curProject }) => {
    const [purchaseLoader, setPurchaseLoader] = useState(false);

    const handleProceedPurchase = async () => {
      if (purchaseLoader) return;
      setPurchaseLoader(true);

      try {
        const payload = {
          projectId: curProject.id,
          isAnnual: !monthly,
          subscriptionPlanId: pricing.id,
          isAddProjectRequest: false,
        };
        const checkoutSessionRes = await fetchBackendApiWrapper(
          "/api/v1/payment/razorpayCheckout",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          },
          await auth.currentUser.getIdToken(false)
        );
        if (checkoutSessionRes && checkoutSessionRes.ok) {
          const sessionUrl = await checkoutSessionRes.json();
          console.log("Session URL: " + sessionUrl.value);
          window.open(sessionUrl.value, "_blank");
          setOpenCheckoutDialog(false);
        } else {
          console.error(
            "Error in creating checkout session: " +
              JSON.stringify(checkoutSessionRes.status)
          );
          dispatch(
            openNotification({
              message:
                "Error in purchasing plan. Please refresh the page and try again.",
              severity: "error",
            })
          );
        }
      } catch (err) {
        console.error(
          "Error in creating checkout session: " + JSON.stringify(err)
        );
        dispatch(
          openNotification({
            message:
              "Error in purchasing plan. Please refresh the page and try again.",
            severity: "error",
          })
        );
      }
      setPurchaseLoader(false);
    };

    return (
      <Dialog
        open={openCheckoutDialog}
        onClose={() => setOpenCheckoutDialog(false)}
        fullWidth
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-start items-start w-full relative p-12 gap-6">
          <div className="flex flex-col justify-start items-start gap-6">
            <div className="font-semibold">Review Details</div>
          </div>
          <div className="flex flex-col justify-start items-start gap-4">
            <div className="flex flex-col justify-start items-start gap-4">
              <div className="flex flex-col justify-start items-start gap-4">
                <div className="px-4 py-2 bg-gray-200 rounded-md mt-4">
                  Subscription Plan Details
                </div>
                <div className="text-sm font-medium">
                  {monthly
                    ? `${pricing.planName} Monthly Plan`
                    : `${pricing.planName} Annual Plan`}
                </div>
                <div className="text-sm font-medium">
                  {monthly
                    ? "Total Amount: ₹ " + pricing.monthlyPrice
                    : "Total Amount: ₹ " + pricing.annualPrice}
                </div>
              </div>

              <div className="mt-4 w-full relative">
                <button
                  onClick={handleProceedPurchase}
                  className="bg-blue-500 font-medium text-white p-2 rounded-md"
                >
                  {purchaseLoader ? (
                    <div className="flex justify-center items-center gap-2">
                      <CircularProgress
                        size="1.2rem"
                        variant="indeterminate"
                        sx={{ color: "white" }}
                      />
                      <div>Proceed to Buy Subscription</div>
                    </div>
                  ) : (
                    "Proceed to Buy Subscription"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      <div
        className={`w-full h-[640px] rounded-lg overflow-hidden bg-white flex flex-col justify-between items-start text-center ${
          pricing.recommended
            ? "border border-gray-200"
            : "border border-gray-200"
        }`}
      >
        {pricing.planName !== "Service" && (
          <div className="flex flex-col justify-start items-start w-full relative gap-4">
            {pricing.recommended && (
              <div className="flex text-xs py-2 justify-center items-center bg-[#0C8CE9] text-white w-full">
                Recommended
              </div>
            )}
            {!pricing.recommended && (
              <div className="flex text-xs py-1 justify-center items-center text-white w-full"></div>
            )}
            <div className="bg-red-500 text-white text-xs p-2 rounded-md shadow-md uppercase font-bold absolute right-2 -top-1">
              {pricing.monthlyDiscountPercentage}% Off
            </div>
            {!pricing.recommended && (
              <div className="flex text-xs py-1 justify-center items-center bg-white text-white"></div>
            )}
            <div className="px-6 py-4 w-full text-center">
              <div className="font-semibold mb-2">{pricing.planName}</div>
              <p className="text-black text-xs">{pricing.description}</p>
            </div>
            <div className="px-6 pt-4 pb-2 w-full text-center">
              {monthly && (
                <div className="w-full relative">
                  <div className="text-3xl font-semibold text-gray-300 gap-2">
                    <s className="decoration-2">
                      ₹{pricing.strikeOffMonthlyPrice}
                    </s>
                    <span className="text-3xl font-semibold text-black">
                      <span>{"   "}</span>₹{pricing.monthlyPrice}
                    </span>
                  </div>
                </div>
              )}
              <div className="mt-4 mb-6">
                <button
                  onClick={() => setMonthly(true)}
                  className={`mx-2 text-gray-800 font-bold py-2 px-4 rounded-full ${
                    monthly
                      ? "bg-gray-200 text-black"
                      : "bg-gray-200 text-black"
                  }`}
                >
                  Monthly
                </button>
              </div>
            </div>
            <div className="px-6 pt-4 pb-6 w-full text-center">
              <button
                onClick={handleSubscribe}
                className="bg-yellow-400 hover:bg-yellow-500 py-2 px-4 rounded-full w-full text-sm font-medium"
              >
                {subscribeLoader ? (
                  <div className="flex justify-center items-center gap-2">
                    <CircularProgress
                      size="1rem"
                      variant="indeterminate"
                      sx={{ color: "black" }}
                    />
                    <div>Subscribe</div>
                  </div>
                ) : (
                  "Subscribe"
                )}
              </button>
            </div>
            <AlreadySubscribedDialog />
            <CheckoutDialog curProject={curProject} />
            <ul className="px-6 text-left">
              {pricing &&
                pricing.features &&
                pricing.features.length > 0 &&
                pricing.features.map((pl, ind) => {
                  return (
                    <li key={ind} className="py-1">
                      <div className="flex justify-start items-start gap-2">
                        <CheckCircleOutlineIcon sx={{ fontSize: "1rem" }} />
                        <div className="text-sm">{pl}</div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
        {pricing.planName === "Service" && (
          <div className="flex flex-col justify-start items-start w-full relative gap-4">
            {!pricing.recommended && (
              <div className="flex text-xs py-1 justify-center items-center bg-white text-white"></div>
            )}
            <div className="flex text-xs py-1 justify-center items-center text-white w-full"></div>
            <div className="px-6 py-4 w-full text-center">
              <div className="font-semibold mb-2">{pricing.planName}</div>
              <p className="text-black text-xs">{pricing.description}</p>
            </div>
            <div className="px-6 pt-4 pb-2 w-full text-center">
              {monthly && (
                <div className="w-full relative">
                  <div className="text-3xl font-semibold text-gray-300 gap-2">
                    <span className="text-3xl font-semibold text-gray-400">
                      Custom
                    </span>
                  </div>
                  <div className="mt-4 mb-6">
                    <div className="h-10"></div>
                  </div>
                </div>
              )}
            </div>
            <div className="px-6 pb-6 w-full text-center">
              <button
                onClick={() =>
                  window.open("https://wa.me/919818090406", "_blank")
                }
                className="bg-yellow-400 hover:bg-yellow-500 py-2 px-4 rounded-full w-full font-medium text-sm"
              >
                Subscribe
              </button>
            </div>
            <ul className="px-6 text-left">
              {pricing &&
                pricing.features &&
                pricing.features.length > 0 &&
                pricing.features.map((pl, ind) => {
                  return (
                    <li key={ind} className="py-1">
                      <div className="flex justify-start items-start gap-2">
                        <CheckCircleOutlineIcon sx={{ fontSize: "1rem" }} />
                        <div className="text-sm">{pl}</div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Pricing;
