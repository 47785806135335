import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetail: undefined,
};

export const userDetailSlice = createSlice({
  name: "userData",
  initialState: initialState,
  reducers: {
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
  },
});

export const { setUserDetail } = userDetailSlice.actions;

export default userDetailSlice.reducer;
