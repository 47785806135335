/* eslint-disable react/display-name */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress, Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import "./topicWriteBlog.css";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker, useNavigate, useSearchParams } from "react-router-dom";
import {
  calculateReadingTime,
  getHTMLBlog,
  getTextLength,
  htmlToPlainTextWithLineBreaks,
  removeAllImageTags,
  replaceBase64WithUploadedUrl,
  uploadBase64Image,
} from "../../../../utils/commonUtils";
import { openNotification } from "../../../../slice/notificationSlice";
import { fetchBackendApiWrapper } from "../../../../utils/apiWrapper";
import CustomQuillToolbar, {
  formats,
  modules,
} from "../../../../components/CustomQuillToolbar";
import ReactQuill from "react-quill";
import ImageRegenerateComponent from "../../../../components/ImageRegenerateComponent";
import LoadingModal from "../../../../components/LoadingModal";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import RegenerationSubscribeDialog from "../../../../components/subscribeDialogs/RegenerationSubscribeDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import WriteBlogGuide from "../../../../components/guides/WriteBlogGuide";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { auth } from "../../../../auth/firebaseConfig";
import ReactGA from "react-ga4";

const TopicWriteBlogPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const projects = useSelector((state) => state.projects);
  // const authData = useSelector((state) => state.authStore);
  const topicSelection = useSelector((state) => state.topicSelection);
  const [openGuide, setOpenGuide] = useState(false);
  const [primaryKeyword, setPrimaryKeyword] = useState("");
  const [secondaryKeywords, setSecondaryKeywords] = useState([]);
  const [topic, setTopic] = useState("");
  const [currentView, setCurrentView] = useState(0);
  const [wordCountRange, setWordCountRange] = useState("801,1500");
  const [pov, setPov] = useState("");
  const [additionalInsights, setAdditionalInsights] = useState("");
  const [tone, setTone] = useState("Informational");

  const quillTldr = useRef(null);
  const quillIntro = useRef(null);
  const quillConclusion = useRef(null);
  const quillFaq = useRef(null);
  const quillBody1 = useRef(null);
  const quillBody2 = useRef(null);
  const quillBody3 = useRef(null);
  const quillBody4 = useRef(null);
  const quillBody5 = useRef(null);
  const quillBody6 = useRef(null);
  const [bodySection1, setBodySection1] = useState("");
  const [bodySection2, setBodySection2] = useState("");
  const [bodySection3, setBodySection3] = useState("");
  const [bodySection4, setBodySection4] = useState("");
  const [bodySection5, setBodySection5] = useState("");
  const [bodySection6, setBodySection6] = useState("");
  const [intro, setIntro] = useState("");
  const [tldr, setTldr] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [faq, setFaq] = useState("");
  const [heroImage, setHeroImage] = useState("");
  const [blogWordCount, setBlogWordCount] = useState(0);
  const [originalBlog, setOriginalBlog] = useState("");
  //section loaders
  const [bodySection1Loader, setBodySection1Loader] = useState(false);
  const [bodySection2Loader, setBodySection2Loader] = useState(false);
  const [bodySection3Loader, setBodySection3Loader] = useState(false);
  const [bodySection4Loader, setBodySection4Loader] = useState(false);
  const [bodySection5Loader, setBodySection5Loader] = useState(false);
  const [bodySection6Loader, setBodySection6Loader] = useState(false);
  const [introLoader, setIntroLoader] = useState(false);
  const [tldrLoader, setTldrLoader] = useState(false);
  const [conclusionLoader, setConclusionLoader] = useState(false);
  const [faqLoader, setFaqLoader] = useState(false);

  const [topicKwData, setTopicKwData] = useState(null);
  const [error, setError] = useState(false);
  const [htmlBlog, setHtmlBlog] = useState("");
  const [saveChange, setSaveChange] = useState(false);

  // Regenerate Image Modal ///
  const [imageQuill, setImageQuill] = useState(null);
  const [openImageRegenerateModal, setOpenImageRegenerateModal] =
    useState(false);
  // Loading Modal ///
  const [openLoadingModal, setOpenLoadingModal] = useState(true);
  const [loadingModalMessage, setLoadingModalMessage] = useState("Loading...");

  // Subscribe Modal
  const [openRegenerationSubscribeModal, setOpenRegenerationSubscribeModal] =
    useState(false);
  const [
    messageRegenerationSubscribeModal,
    setMessageRegenerationSubscribeModal,
  ] = useState("Subscribe to regenerate");

  // Save modal
  const [openSaveContentModal, setOpenSaveContentModal] = useState(false);
  const [isExportAction, setIsExportAction] = useState(false);
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && saveChange
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      setOpenSaveContentModal(true);
    }
  }, [blocker]);

  //START: Export list //
  const [isCopyContentOpen, setIsCopyContentOpen] = useState(false);
  const copyDropdownRef = useRef(null);
  const [isExportContentOpen, setIsExportContentOpen] = useState(false);
  const exportDropdownRef = useRef(null);
  useEffect(() => {
    ReactGA.event({
      category: "Page Load",
      action: "Blogs - Load",
      location: window.location.pathname,
      label: "Blogs - Load",
    });
    const handleClickOutside = (event) => {
      if (
        copyDropdownRef.current &&
        !copyDropdownRef.current.contains(event.target)
      ) {
        setIsCopyContentOpen(false);
      }
      if (
        exportDropdownRef.current &&
        !exportDropdownRef.current.contains(event.target)
      ) {
        setIsExportContentOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  //END: Export list //

  const fetchTopicKeywordsApi = async () => {
    try {
      const kwData = await fetchBackendApiWrapper(
        `/api/v1/topic?projectId=${
          projects.currentProject.id
        }&topicId=${searchParam.get("topicId")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (kwData.status === 200) {
        let kwJson = await kwData.json();
        if (kwJson !== null && kwJson !== undefined) {
          const secKwTmp = kwJson.secondaryKw.map((k) => k.keyword);
          kwJson = { ...kwJson, secondaryKw: secKwTmp };
          setTopicKwData(kwJson);
          setPrimaryKeyword(kwJson.primaryKw);
          console.log(secKwTmp);
          setSecondaryKeywords(secKwTmp);
          setTopic(kwJson.topics[kwJson.currentTopic]);
          processInitPage(kwJson);
        } else {
          navigate("/dashboard/topic-selection", { replace: "true" });
          dispatch(
            openNotification({ message: "Blog not found", severity: "error" })
          );
        }
      } else if (kwData.status === 204) {
        console.error("Topic not found: " + searchParam.get("topicId"));
        navigate("/dashboard/topic-selection", { replace: "true" });
        dispatch(
          openNotification({ message: "Blog not found", severity: "error" })
        );
      } else {
        throw Error();
      }
    } catch (err) {
      console.error(
        "Error in fetching keywords topic : " + JSON.stringify(err)
      );
      navigate("/dashboard/topic-selection", { replace: "true" });
      dispatch(
        openNotification({ message: "Blog not found", severity: "error" })
      );
    }
  };

  const fetchBlogApi = async () => {
    try {
      const kwData = await fetchBackendApiWrapper(
        `/api/v1/blog?projectId=${
          projects.currentProject.id
        }&topicId=${searchParam.get("topicId")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (kwData.status === 200) {
        const kwJson = await kwData.json();
        if (kwJson !== null && kwJson !== undefined) {
          return kwJson;
        }
        return null;
      } else if (kwData.status === 204) {
        return null;
      }
    } catch (err) {
      console.error("Error in fetching blog : " + JSON.stringify(err));
      return null;
    }
  };

  const saveBlogApi = async () => {
    try {
      const savedBlogRes = await fetchBackendApiWrapper(
        "/api/v1/saveBlog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(originalBlog),
        },
        await auth.currentUser.getIdToken(false)
      );
      if (savedBlogRes.ok) {
        console.log("Blog Saved successfully");
        setSaveChange(false);
      }
    } catch (err) {
      console.error("Error in saving blog: " + JSON.stringify(err));
      dispatch(
        openNotification({ message: "Error in saving Blog", severity: "error" })
      );
    }
  };

  useEffect(() => {
    initPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  const initPage = async () => {
    if (projects.currentProject !== undefined) {
      if (
        topicSelection.newTopics === undefined ||
        topicSelection.draftTopics === undefined ||
        topicSelection.publishedTopics === undefined
      ) {
        await fetchTopicKeywordsApi();
      } else {
        const tmp = [];
        tmp.push(
          ...[
            ...topicSelection.newTopics,
            ...topicSelection.draftTopics,
            ...topicSelection.publishedTopics,
          ]
        );

        let kws = tmp.find((kw) => kw.topicId === searchParam.get("topicId"));

        if (kws !== null && kws !== undefined) {
          const secKwTmp = kws.secondaryKw.map((k) => k.keyword);
          kws = { ...kws, secondaryKw: secKwTmp };
          setTopicKwData(kws);
          setPrimaryKeyword(kws.primaryKw);
          console.log(secKwTmp);
          setSecondaryKeywords(secKwTmp);
          setTopic(kws.topics[kws.currentTopic]);
          processInitPage(kws);
        } else {
          navigate("/dashboard/topic-selection", { replace: "true" });
        }
      }
    }
  };

  const processInitPage = async (projectKeywords) => {
    const tmp = await fetchBlogApi();
    if (tmp === null && projects.currentProject !== undefined) {
      generateBlogRequest(
        "Informational",
        "",
        "801,1500",
        "",
        projectKeywords.topics[projectKeywords.currentTopic],
        projectKeywords.primaryKw,
        projectKeywords.secondaryKw,
        projectKeywords.topicId
      );
    } else if (tmp !== null) {
      setOriginalBlog(tmp);
      setOpenLoadingModal(false);
    }
  };

  useEffect(() => {
    if (
      originalBlog !== null &&
      originalBlog !== undefined &&
      originalBlog !== ""
    ) {
      setPov(originalBlog.pov);
      setTone(originalBlog.tone);
      setAdditionalInsights(originalBlog.additionalInsights);
      setWordCountRange(originalBlog.wordRange);

      setBlogWordCount(originalBlog.wordCount);
      setHeroImage(
        originalBlog.heroImage !== null && originalBlog.heroImage !== undefined
          ? originalBlog.heroImage
          : null
      );
      setIntro(originalBlog.introduction);
      setTldr(originalBlog.tldr);
      setConclusion(originalBlog.conclusion);
      setFaq(originalBlog.faq);
      setBodySection1(
        originalBlog.body.length > 0 ? originalBlog.body[0].section : null
      );
      setBodySection2(
        originalBlog.body.length > 1 ? originalBlog.body[1].section : null
      );
      setBodySection3(
        originalBlog.body.length > 2 ? originalBlog.body[2].section : null
      );
      setBodySection4(
        originalBlog.body.length > 3 ? originalBlog.body[3].section : null
      );
      setBodySection5(
        originalBlog.body.length > 4 ? originalBlog.body[4].section : null
      );
      setBodySection6(
        originalBlog.body.length > 5 ? originalBlog.body[5].section : null
      );
      setHtmlBlog(getHTMLBlog(originalBlog));

      //save blog
      saveBlogApi();
    }
  }, [originalBlog]);

  const handleSaveChanges = async () => {
    if (!saveChange) return;
    setLoadingModalMessage("Saving...");
    setOpenLoadingModal(true);
    const old = [...originalBlog.body];
    if (old.length > 0) {
      old[0] = {
        heading: "",
        section: await replaceBase64WithUploadedUrl(
          bodySection1,
          originalBlog.id,
          await auth.currentUser.getIdToken(false)
        ),
      };
    }
    if (old.length > 1)
      old[1] = {
        heading: "",
        section: await replaceBase64WithUploadedUrl(
          bodySection2,
          originalBlog.id,
          await auth.currentUser.getIdToken(false)
        ),
      };
    if (old.length > 2)
      old[2] = {
        heading: "",
        section: await replaceBase64WithUploadedUrl(
          bodySection3,
          originalBlog.id,
          await auth.currentUser.getIdToken(false)
        ),
      };
    if (old.length > 3)
      old[3] = {
        heading: "",
        section: await replaceBase64WithUploadedUrl(
          bodySection4,
          originalBlog.id,
          await auth.currentUser.getIdToken(false)
        ),
      };
    if (old.length > 4)
      old[4] = {
        heading: "",
        section: await replaceBase64WithUploadedUrl(
          bodySection5,
          originalBlog.id,
          await auth.currentUser.getIdToken(false)
        ),
      };
    if (old.length > 5)
      old[5] = {
        heading: "",
        section: await replaceBase64WithUploadedUrl(
          bodySection6,
          originalBlog.id,
          await auth.currentUser.getIdToken(false)
        ),
      };
    // in each section search base64 data image -> upload and replace
    const tmpTldr = await replaceBase64WithUploadedUrl(
      tldr,
      originalBlog.id,
      await auth.currentUser.getIdToken(false)
    );
    const tmpIntro = await replaceBase64WithUploadedUrl(
      intro,
      originalBlog.id,
      await auth.currentUser.getIdToken(false)
    );
    const tmpFaq = await replaceBase64WithUploadedUrl(
      faq,
      originalBlog.id,
      await auth.currentUser.getIdToken(false)
    );
    const tmpConclusion = await replaceBase64WithUploadedUrl(
      conclusion,
      originalBlog.id,
      await auth.currentUser.getIdToken(false)
    );
    const tmpHeroImage =
      heroImage !== null &&
      heroImage !== undefined &&
      heroImage.url !== null &&
      heroImage.url !== undefined &&
      heroImage.url.startsWith("data:image")
        ? await uploadBase64Image(
            heroImage.url,
            originalBlog.id
          )
        : heroImage.url;

    setOriginalBlog({
      ...originalBlog,
      heroImage: { ...heroImage, url: tmpHeroImage },
      tldr: tmpTldr,
      introduction: tmpIntro,
      conclusion: tmpConclusion,
      faq: tmpFaq,
      body: old,
      wordCount: blogWordCount,
    });
    setOpenLoadingModal(false);
    // dispatch(
    //   openNotification({ message: "Sussessfully Saved", severity: "success" })
    // );
  };

  const generateBlogRequest = async (
    argTone,
    argPov,
    argWordCount,
    argAdditionalInsights,
    topic,
    targetKeyword,
    secondaryKeywords,
    topicId
  ) => {
    try {
      const authToken = await auth.currentUser.getIdToken(false);
      const payload = {
        topicId: topicId,
        topic: topic,
        targetKeyword: targetKeyword,
        keywords: secondaryKeywords,
        audience: "general public",
        tone: argTone,
        wordRange: argWordCount,
        companyDescription:
          projects.currentProject.projectDescription === null
            ? ""
            : projects.currentProject.projectDescription,
        additionalInsights: argAdditionalInsights,
        pov: argPov,
        projectId: projects.currentProject.id,
      };
      const res = await fetchBackendApiWrapper(
        "/api/v1/generateBlog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        authToken
      );
      if (res !== null && res !== undefined && res.ok) {
        const blogRes = await res.json();
        // console.log("Blog Response Data: " + blogRes);
        setOriginalBlog(blogRes);
        setOpenLoadingModal(false);
        dispatch(
          openNotification({ message: "Blog generated", severity: "success" })
        );
      } else {
        console.error("Error in generating blog");
        setOpenLoadingModal(false);
        dispatch(
          openNotification({
            message: "Error in generating section",
            severity: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error in generating blog " + JSON.stringify(err));
      setOpenLoadingModal(false);
      dispatch(
        openNotification({
          message: "Error in generating section",
          severity: "error",
        })
      );
    }
    setOpenLoadingModal(false);
  };

  const handleBlogRegenerate = async () => {
    setLoadingModalMessage("Regenerating Blog. Please wait...");
    setOpenLoadingModal(true);
    setOriginalBlog({
      ...originalBlog,
      pov: pov,
      wordRange: wordCountRange,
      additionalInsights: additionalInsights,
      tone: tone,
    });
    let tmp = { ...originalBlog };
    tmp = {
      ...tmp,
      pov: pov,
      wordRange: wordCountRange,
      additionalInsights: additionalInsights,
      tone: tone,
    };
    await regenerateBlogRequestApi(tmp);
    setOpenLoadingModal(false);
  };

  const regenerateBlogRequestApi = async (blogRequest) => {
    try {
      const authToken = await auth.currentUser.getIdToken(false);
      const res = await fetchBackendApiWrapper(
        "/api/v1/regenerateBlog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(blogRequest),
        },
        authToken
      );
      if (res !== null && res !== undefined && res.ok) {
        const blogRes = await res.json();
        setOriginalBlog(blogRes);
        setOpenLoadingModal(false);
        dispatch(
          openNotification({ message: "Blog regenerated", severity: "success" })
        );
      } else if (res.status === 423) {
        const errorData = await res.json();
        if (errorData.message.includes("FREE USER")) {
          setMessageRegenerationSubscribeModal(
            "You have used up all your Free regeneration credits. Subscribe to regenerate the blog"
          );
        } else {
          setMessageRegenerationSubscribeModal(
            "You have undergone multiple regenerations, prompting the imposition of a cooldown period. You will be able to initiate the regeneration process again after a lapse of 24 hours."
          );
        }
        setOpenRegenerationSubscribeModal(true);

        console.error("Error in regenerating blog");
        setOpenLoadingModal(false);
      } else {
        dispatch(
          openNotification({
            message: "Error in regenerating blog",
            severity: "error",
          })
        );
      }
    } catch (err) {
      console.error("Error in regenerating blog " + JSON.stringify(err));
      setOpenLoadingModal(false);
      dispatch(
        openNotification({
          message: "Error in regenerating blog",
          severity: "error",
        })
      );
    }
    setOpenLoadingModal(false);
  };

  // Regenerate Modal ////

  const [openRegenerateModal, setOpenRegenerateModal] = useState(false);
  const [regenerateSelectedSection, setRegenerateSelectedSection] =
    useState("");

  const regenerateSection = (sectionData) => {
    setOpenRegenerateModal(true);
    setRegenerateSelectedSection(sectionData);
  };

  const RegenerateModal = () => {
    const [instruction, setInstruction] = useState("");

    const updateSectionLoaderState = () => {
      if (regenerateSelectedSection.section === "tldr")
        setTldrLoader((prev) => !prev);
      if (regenerateSelectedSection.section === "introduction")
        setIntroLoader((prev) => !prev);
      if (regenerateSelectedSection.section === "conclusion")
        setConclusionLoader((prev) => !prev);
      if (regenerateSelectedSection.section === "faq")
        setFaqLoader((prev) => !prev);
      if (regenerateSelectedSection.section === "bodySection1")
        setBodySection1Loader((prev) => !prev);
      if (regenerateSelectedSection.section === "bodySection2")
        setBodySection2Loader((prev) => !prev);
      if (regenerateSelectedSection.section === "bodySection3")
        setBodySection3Loader((prev) => !prev);
      if (regenerateSelectedSection.section === "bodySection4")
        setBodySection4Loader((prev) => !prev);
      if (regenerateSelectedSection.section === "bodySection5")
        setBodySection5Loader((prev) => !prev);
      if (regenerateSelectedSection.section === "bodySection6")
        setBodySection6Loader((prev) => !prev);
    };

    const handleRegenerateSectionAction = async () => {
      try {
        setOpenRegenerateModal(false);
        updateSectionLoaderState();
        const sectionData = regenerateSelectedSection.data;
        const head = sectionData.substring(
          sectionData.indexOf("<h2>") + 4,
          sectionData.indexOf("</h2>")
        );
        const { updatedSection, imageData } = removeAllImageTags(sectionData);
        let currentWordCount = -getTextLength(updatedSection);
        const payload = {
          topic: topic,
          heading: head,
          section: updatedSection,
          targetKeyword: primaryKeyword,
          tone: tone,
          additionalInsights: instruction,
          projectId: projects.currentProject.id,
        };
        setLoadingModalMessage("Regenerating section. Please wait...");
        const res = await fetchBackendApiWrapper(
          "/api/v1/regenerateSection",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          },
          await auth.currentUser.getIdToken(false)
        );
        if (res !== null && res !== undefined && res.ok) {
          let blogRes = await res.json();
          // console.log(
          //   "Blog Regeneration Response Data: " + JSON.stringify(blogRes)
          // );
          currentWordCount += getTextLength(blogRes.section);
          setBlogWordCount(originalBlog.wordCount + currentWordCount);
          if (imageData.length !== 0) {
            const h2Index = blogRes.section.indexOf("</h2>") + 5;
            const sectionWithImages =
              blogRes.section.slice(0, h2Index) +
              imageData.join("<br>") +
              blogRes.section.slice(h2Index);
            blogRes = { ...blogRes, section: sectionWithImages };
          }
          updateBlog(blogRes);
          dispatch(
            openNotification({
              message: "Section Regenerated",
              severity: "success",
            })
          );
        } else if (res.status === 423) {
          const errorData = await res.json();
          if (errorData.message.includes("FREE USER")) {
            setMessageRegenerationSubscribeModal(
              "You have used up all your Free regeneration credits. Subscribe to regenerate the blog"
            );
          } else {
            setMessageRegenerationSubscribeModal(
              "You have undergone multiple regenerations, prompting the imposition of a cooldown period. You will be able to initiate the regeneration process again after a lapse of 24 hours."
            );
          }
          setOpenRegenerationSubscribeModal(true);

          console.error("Error in regenerating section");
        } else {
          dispatch(
            openNotification({
              message: "Error in regenerating section",
              severity: "error",
            })
          );
        }
      } catch (err) {
        console.error("Error in regenerating section " + JSON.stringify(err));
        dispatch(
          openNotification({
            message: "Error in Regenerating section",
            severity: "error",
          })
        );
      }
      updateSectionLoaderState();
    };

    const updateBlog = (data) => {
      if (regenerateSelectedSection.section === "tldr") {
        setTldr(data.section);
        setSaveChange(true);
      } else if (regenerateSelectedSection.section === "introduction") {
        setIntro(data.section);
        setSaveChange(true);
      } else if (regenerateSelectedSection.section === "conclusion") {
        setConclusion(data.section);
        setSaveChange(true);
      } else if (regenerateSelectedSection.section === "faq") {
        setFaq(data.section);
        setSaveChange(true);
      } else if (regenerateSelectedSection.section === "bodySection1") {
        setBodySection1(data.section);
        setSaveChange(true);
      } else if (regenerateSelectedSection.section === "bodySection2") {
        setBodySection2(data.section);
        setSaveChange(true);
      } else if (regenerateSelectedSection.section === "bodySection3") {
        setBodySection3(data.section);
        setSaveChange(true);
      } else if (regenerateSelectedSection.section === "bodySection4") {
        setBodySection4(data.section);
        setSaveChange(true);
      } else if (regenerateSelectedSection.section === "bodySection5") {
        setBodySection5(data.section);
        setSaveChange(true);
      } else if (regenerateSelectedSection.section === "bodySection6") {
        setBodySection6(data.section);
        setSaveChange(true);
      }
    };

    return (
      <Dialog
        open={openRegenerateModal}
        onClose={() => setOpenRegenerateModal(false)}
        className="backdrop-blur-sm"
      >
        <div className="p-4 flex justify-between items-center relative w-full gap-72">
          <div className="text-lg font-medium">Re-generate Section</div>
          <div
            onClick={() => setOpenRegenerateModal(false)}
            className="cursor-pointer rounded-full p-2 bg-gray-100"
          >
            <ClearIcon />
          </div>
        </div>
        <hr className="bg-gray-200 w-full" />
        <div className="flex flex-col justify-start items-start w-full relative gap-4 p-6">
          <div className="text-sm ">Instruction</div>
          <textarea
            rows="3"
            value={instruction}
            onChange={(event) => setInstruction(event.target.value)}
            placeholder="Tell us what additional information that you want"
            className="w-full relative border rounded-lg border-gray-300 p-4 text-sm"
          />
        </div>
        <div className="flex justify-end items-center relative w-full p-4">
          <button
            onClick={handleRegenerateSectionAction}
            className="p-2 rounded-lg bg-thbBlue text-white text-sm"
          >
            Re-generate Section
          </button>
        </div>
      </Dialog>
    );
  };

  /// Save Modal

  const SaveContentModal = ({
    openSaveContentModal,
    setOpenSaveContentModal,
    isExportAction,
    setIsExportAction,
  }) => {
    return (
      <Dialog
        open={openSaveContentModal}
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick") return;
          setOpenSaveContentModal(false);
          setIsExportAction(false);
        }}
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-center items-center p-8 gap-4">
          <div className="text-sm font-medium text-center">
            You have unsaved changes. Want to Save them?
          </div>
          <div className="flex justify-center items-center gap-4">
            <button
              onClick={() => {
                handleSaveChanges().then(() => {
                  if (!isExportAction) blocker.proceed();
                  setSaveChange(false);
                  setOpenSaveContentModal(false);
                });
              }}
              className="bg-green-600 text-white rounded-md font-medium text-sm px-4 py-2"
            >
              Save
            </button>
            <button
              onClick={() => {
                setOpenSaveContentModal(false);
                setSaveChange(false);
                if (!isExportAction) blocker.proceed();
              }}
              className="bg-gray-200 border-gray-700 rounded-md font-medium text-sm p-2"
            >
              No
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  /////START : EXPORT/////

  const handleCopyHtml = () => {
    if (saveChange) {
      setIsExportAction(true);
      setOpenSaveContentModal(true);
      return;
    }
    navigator.clipboard.writeText(htmlBlog);
    dispatch(
      openNotification({
        severity: "success",
        message: "Blog copied to clipboard",
      })
    );
    setIsCopyContentOpen(false);
  };

  const handleCopyText = () => {
    if (saveChange) {
      setIsExportAction(true);
      setOpenSaveContentModal(true);
      return;
    }
    navigator.clipboard.writeText(htmlToPlainTextWithLineBreaks(htmlBlog));
    dispatch(
      openNotification({
        severity: "success",
        message: "Blog copied to clipboard",
      })
    );
    setIsCopyContentOpen(false);
  };

  const downloadHTMLFile = () => {
    if (saveChange) {
      setIsExportAction(true);
      setOpenSaveContentModal(true);
      return;
    }
    let data = htmlBlog; // Replace with your HTML string
    data += `<meta name="title" content="${topic}">`;
    data += originalBlog.metaDescription;
    data += `<meta name="keywords" content="${primaryKeyword}, ${secondaryKeywords.join(
      ","
    )}">`;
    // Create a Blob containing the data
    const blob = new Blob([data], { type: "text/html" });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = topic + ".html"; // Specify the desired file name

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setIsExportContentOpen(false);
  };

  const downloadWordFile = async () => {
    try {
      if (saveChange) {
        setIsExportAction(true);
        setOpenSaveContentModal(true);
        return;
      }
      setIsExportContentOpen(false);
      const downloadData = await fetchBackendApiWrapper(
        `/api/v1/downloadWord?projectId=${
          projects.currentProject.id
        }&topicId=${searchParam.get("topicId")}&promotionType=${-1}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (downloadData.ok) {
        const jsonData = await downloadData.json();
        window.open(jsonData.value, "_blank");
      }
    } catch (err) {
      console.error("Error in downloading word file: " + JSON.stringify(err));
    }
  };
  /////END : EXPORT/////

  return (
    <>
      {error && (
        <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
          <div className="font-medium">
            Something went wrong please try again
          </div>
        </div>
      )}
      {!error && originalBlog !== null && originalBlog !== undefined && (
        <div>
          <div className="flex relative flex-col justify-start item-start w-full p-4 bg-gray-100">
            <div className="flex justify-between items-center w-full relative">
              <div className="flex relative justify-start item-start gap-2 pl-12 mb-4 cursor-pointer">
                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon color="black" />
                </div>
                <div>Blog</div>
              </div>
              <div className="mb-2">
                <div
                  onClick={() => setOpenGuide(true)}
                  className="rounded-lg bg-white shadow-md p-2 text-gray-500 cursor-pointer"
                >
                  <HelpOutlineRoundedIcon />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-full relative shadow-lg bg-white rounded-lg p-6 md:pl-12">
              <div className="flex justify-start items-center w-full relative gap-8">
                <div
                  onClick={() => setCurrentView(0)}
                  className={`block cursor-pointer p-2 ${
                    currentView === 0
                      ? "border-b-4 border-thbBlue font-semibold"
                      : "font-normal"
                  }`}
                >
                  Blog
                </div>
                {/* <div
                  onClick={() => setCurrentView(1)}
                  className={`block p-2 cursor-pointer ${
                    currentView === 1
                      ? "border-b-4 border-thbBlue font-semibold"
                      : "font-normal"
                  }`}
                >
                  Preview
                </div> */}
                <div
                  onClick={() => {
                    const params = new URLSearchParams();
                    params.set("topicId", searchParam.get("topicId"));
                    params.set("promotionType", 0);
                    const url = `/dashboard/topics/blog/promotion?${params.toString()}`;
                    navigate(url, { replace: true });
                  }}
                  className={`block p-2 cursor-pointer ${
                    currentView === 2
                      ? "border-b-4 border-thbBlue font-semibold"
                      : "font-normal"
                  }`}
                >
                  Promotions
                </div>
              </div>
              <hr className="w-full relative bg-gray h-2" />
              <WriteBlogGuide
                openGuide={openGuide}
                setOpenGuide={setOpenGuide}
              />
              {currentView === 0 && (
                <>
                  <div className="flex justify-start items-start w-full relative mt-12 overflow-x-auto md:overflow-visible">
                    <div className="flex flex-col md:flex-row justify-between items-start w-full relative">
                      <div className="flex flex-col justify-start items-start">
                        <div className="text-2xl font-bold">{topic}</div>
                        <div className="mt-2 flex justify-start items-start gap-4">
                          {primaryKeyword.length > 0 && (
                            <div className="text-sm px-2 py-1 bg-thbBlue rounded-md text-white">
                              {primaryKeyword}
                            </div>
                          )}
                          {secondaryKeywords.map((sec) => {
                            return (
                              <div
                                key={sec}
                                className="text-sm px-2 py-1 bg-sky-50 rounded-md text-thbBlue"
                              >
                                {sec}
                              </div>
                            );
                          })}
                        </div>
                        {originalBlog !== null &&
                          originalBlog !== undefined && (
                            <div className="flex justify-start items-center gap-8 text-sm text-gray-500 pt-4">
                              <div>{`Word Count : ${blogWordCount}`}</div>
                              <div>{`Reading Time : ${calculateReadingTime(
                                blogWordCount
                              )} mins`}</div>
                            </div>
                          )}
                      </div>
                      <div className="flex justify-start items-center gap-4 pt-4 pr-8">
                        <button
                          onClick={handleSaveChanges}
                          className={`text-sm rounded-lg ${
                            saveChange ? "bg-thbBlue" : "bg-slate-300"
                          } p-2 text-white font-semibold shadow-sm flex justify-center items-center gap-2`}
                        >
                          <SaveIcon />
                          <div>{saveChange ? "Save Changes" : "Saved"}</div>
                        </button>
                        <button
                          onClick={() => {
                            if (saveChange) {
                              setIsExportAction(true);
                              setOpenSaveContentModal(true);
                              return;
                            }
                            setCurrentView(1);
                          }}
                          className={`text-sm rounded-lg bg-orange-100 p-2 text-orange-500 font-semibold shadow-sm flex justify-center items-center gap-2 pl-4 pr-4`}
                        >
                          <VisibilityOutlinedIcon />
                          <div>Preview</div>
                        </button>
                        {/* <div
                          ref={copyDropdownRef}
                          className="inline-block relative"
                        >
                          <button
                            onClick={() =>
                              setIsCopyContentOpen((prev) => !prev)
                            }
                            className="rounded-lg p-2 text-xs bg-white-400 text-gray-500 shadow-lg border border-gray-500"
                          >
                            <ContentCopyIcon sx={{ fontSize: "1.2rem" }} />
                          </button>
                          {isCopyContentOpen && (
                            <div className="mt-2 bg-white right-0 left-auto absolute z-10 shadow-lg rounded-md flex flex-col justify-start items-start gap-2 w-40 border border-gray-500">
                              <div
                                onClick={handleCopyHtml}
                                className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                              >{`Copy <HTML>`}</div>
                              <div
                                onClick={handleCopyText}
                                className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                              >{`Copy Text`}</div>
                            </div>
                          )}
                        </div> */}
                        <div
                          ref={exportDropdownRef}
                          className="inline-block relative"
                        >
                          <button
                            onClick={() =>
                              setIsExportContentOpen((prev) => !prev)
                            }
                            className="rounded-lg p-2 text-xs bg-white-400 text-gray-500 shadow-lg border border-gray-500"
                          >
                            <FileDownloadIcon sx={{ fontSize: "1.2rem" }} />
                          </button>
                          {isExportContentOpen && (
                            <div className="mt-2 bg-white right-0 left-auto absolute z-10 shadow-lg rounded-md flex flex-col justify-start items-start gap-2 w-40 border border-gray-500">
                              <div
                                onClick={handleCopyHtml}
                                className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                              >{`Copy <HTML>`}</div>
                              <div
                                onClick={handleCopyText}
                                className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                              >{`Copy Text`}</div>
                              <div
                                onClick={downloadHTMLFile}
                                className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                              >{`Export HTML`}</div>
                              <div
                                onClick={downloadWordFile}
                                className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                              >{`Export Word`}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 w-full gap-20 mt-8">
                    <div className="col-span-3 md:col-span-2 justify-start items-start">
                      <hr className="mt-1 w-full relative bg-gray h-2" />
                      <div className="flex flex-col justify-start items-start w-full relative">
                        <div>
                          {heroImage !== null &&
                            heroImage !== undefined &&
                            heroImage.length !== 0 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Hero Image</div>
                                  <div>
                                    <button
                                      onClick={() => {
                                        setImageQuill(null);
                                        setOpenImageRegenerateModal(true);
                                      }}
                                      className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                    >
                                      Update Image
                                    </button>
                                  </div>
                                </div>
                                <div className="border p-10 rounded-br-lg rounded-bl-lg border-gray-300 flex justify-center items-center w-full relative">
                                  <img
                                    src={heroImage.url}
                                    alt={heroImage.altText}
                                    className="max-h-64"
                                  />
                                </div>
                              </div>
                            )}
                          {tldr !== null &&
                            tldr !== undefined &&
                            tldr.length > 0 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Summary</div>
                                  {!tldrLoader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillTldr);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "tldr",
                                            data: tldr,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!tldrLoader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarTldr"}
                                    />
                                    <ReactQuill
                                      ref={quillTldr}
                                      value={tldr}
                                      modules={modules("toolbarTldr")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setTldr(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {tldrLoader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {intro !== null &&
                            intro !== undefined &&
                            intro.length > 0 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Introduction</div>
                                  {!introLoader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillIntro);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "introduction",
                                            data: intro,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!introLoader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarIntro"}
                                    />
                                    <ReactQuill
                                      ref={quillIntro}
                                      value={intro}
                                      modules={modules("toolbarIntro")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setIntro(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {introLoader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {bodySection1 !== null &&
                            bodySection1 !== undefined &&
                            bodySection1.length > 0 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Section 1</div>
                                  {!bodySection1Loader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillBody1);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "bodySection1",
                                            data: bodySection1,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!bodySection1Loader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarBodySection1"}
                                    />
                                    <ReactQuill
                                      ref={quillBody1}
                                      value={bodySection1}
                                      modules={modules("toolbarBodySection1")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setBodySection1(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {bodySection1Loader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {bodySection2 !== null &&
                            bodySection2 !== undefined &&
                            bodySection2.length > 1 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Section 2</div>
                                  {!bodySection2Loader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillBody2);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "bodySection2",
                                            data: bodySection2,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!bodySection2Loader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarBodySection2"}
                                    />
                                    <ReactQuill
                                      ref={quillBody2}
                                      value={bodySection2}
                                      modules={modules("toolbarBodySection2")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setBodySection2(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {bodySection2Loader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {bodySection3 !== null &&
                            bodySection3 !== undefined &&
                            bodySection3.length > 2 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Section 3</div>
                                  {!bodySection3Loader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillBody3);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "bodySection3",
                                            data: bodySection3,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!bodySection3Loader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarBodySection3"}
                                    />
                                    <ReactQuill
                                      ref={quillBody3}
                                      value={bodySection3}
                                      modules={modules("toolbarBodySection3")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setBodySection3(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {bodySection3Loader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {bodySection4 !== null &&
                            bodySection4 !== undefined &&
                            bodySection4.length > 3 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Section 4</div>
                                  {!bodySection4Loader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillBody4);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "bodySection4",
                                            data: bodySection4,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!bodySection4Loader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarBodySection4"}
                                    />
                                    <ReactQuill
                                      ref={quillBody4}
                                      value={bodySection4}
                                      modules={modules("toolbarBodySection4")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setBodySection4(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {bodySection4Loader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {bodySection5 !== null &&
                            bodySection5 !== undefined &&
                            bodySection5.length > 4 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Section 5</div>
                                  {!bodySection5Loader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillBody5);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "bodySection5",
                                            data: bodySection5,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!bodySection5Loader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarBodySection5"}
                                    />
                                    <ReactQuill
                                      ref={quillBody5}
                                      value={bodySection5}
                                      modules={modules("toolbarBodySection5")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setBodySection5(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {bodySection5Loader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {bodySection6 !== null &&
                            bodySection6 !== undefined &&
                            bodySection6.length > 5 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Section 6</div>
                                  {!bodySection6Loader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillBody6);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "bodySection6",
                                            data: bodySection6,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!bodySection6Loader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarBodySection6"}
                                    />
                                    <ReactQuill
                                      ref={quillBody6}
                                      value={bodySection6}
                                      modules={modules("toolbarBodySection6")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setBodySection6(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {bodySection6Loader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {conclusion !== null &&
                            conclusion !== undefined &&
                            conclusion.length > 0 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">Conclusion</div>
                                  {!conclusionLoader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillConclusion);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "conclusion",
                                            data: conclusion,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!conclusionLoader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarConclusion"}
                                    />
                                    <ReactQuill
                                      ref={quillConclusion}
                                      value={conclusion}
                                      modules={modules("toolbarConclusion")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setConclusion(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {conclusionLoader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {faq !== null &&
                            faq !== undefined &&
                            faq.length > 0 && (
                              <div>
                                <div className="flex justify-between items-center relative w-full border rounded-tr-lg rounded-tl-lg border-gray-300 p-2 mt-16 bg-gray-200">
                                  <div className="text-sm">{`FAQ's`}</div>
                                  {!faqLoader && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setImageQuill(quillFaq);
                                          setOpenImageRegenerateModal(true);
                                        }}
                                        className="text-xs bg-gray-500 text-white rounded-lg p-1 mr-2"
                                      >
                                        Add Image
                                      </button>
                                      <button
                                        onClick={() =>
                                          regenerateSection({
                                            section: "faq",
                                            data: faq,
                                          })
                                        }
                                        className="text-xs bg-thbBlue text-white rounded-lg p-1"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {!faqLoader && (
                                  <>
                                    <CustomQuillToolbar
                                      toolbarId={"toolbarFaq"}
                                    />
                                    <ReactQuill
                                      ref={quillFaq}
                                      value={faq}
                                      modules={modules("toolbarFaq")}
                                      formats={formats}
                                      onChange={(value) => {
                                        setFaq(value);
                                        setSaveChange(true);
                                      }}
                                    />
                                  </>
                                )}
                                {faqLoader && (
                                  <div className="p-6 flex justify-center items-center w-full border border-gray-300">
                                    <CircularProgress
                                      size="2rem"
                                      variant="indeterminate"
                                      sx={{ color: "gray" }}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 md:col-span-1 justify-start items-start w-full">
                      <div className="flex flex-col justify-start items-stretch mt-16 gap-2">
                        <div className="text-sm flex justify-start items-center gap-1">
                          Tone
                          <InfoOutlinedIcon
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                            onClick={() => setOpenGuide(true)}
                          />
                        </div>
                        <select
                          value={tone}
                          onChange={(event) => setTone(event.target.value)}
                          className="p-2 rounded-md border border-gray-300 bg-white text-light text-sm"
                        >
                          <option value="Friendly">Friendly</option>
                          <option value="Informational">Informational</option>
                          <option value="Transactional">Transactional</option>
                          <option value="Inspirational">Inspirational</option>
                          <option value="Professional">Professional</option>
                        </select>
                      </div>
                      <div className="flex flex-col justify-start items-stretch gap-2 mt-6">
                        <div className="text-sm flex justify-start items-center gap-1">
                          Add Insights (optional)
                          <InfoOutlinedIcon
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                            onClick={() => setOpenGuide(true)}
                          />
                        </div>
                        <textarea
                          rows={3}
                          value={additionalInsights}
                          onChange={(event) =>
                            setAdditionalInsights(event.target.value)
                          }
                          placeholder="Tell us specific things you want to showcase"
                          className="border rounded-lg border-gray-300 bg-white text-light text-sm p-2"
                        />
                      </div>
                      <div className="flex flex-col justify-start items-stretch gap-2 mt-6">
                        <div className="text-sm flex justify-start items-center gap-1">
                          Word Count Range
                          <InfoOutlinedIcon
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                            onClick={() => setOpenGuide(true)}
                          />
                        </div>
                        <select
                          value={wordCountRange}
                          onChange={(event) =>
                            setWordCountRange(event.target.value)
                          }
                          className="p-2 rounded-md border border-gray-300 bg-white text-light text-sm"
                        >
                          <option value="500,800">
                            Small (500-800 words, 3 headings)
                          </option>
                          <option value="801,1500">
                            Medium (800-1500 words, 4 headings)
                          </option>
                          <option value="1501,2500">
                            Large (1500-3000 words, 8 headings)
                          </option>
                          {/* <option value="2501,4000">
                            Very Large (2500-4000 words, 12 headings)
                          </option> */}
                        </select>
                      </div>
                      <div className="flex flex-col justify-start items-stretch gap-2 mt-6">
                        <div className="text-sm flex justify-start items-center gap-1">
                          Point of View
                          <InfoOutlinedIcon
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                            onClick={() => setOpenGuide(true)}
                          />
                        </div>
                        <select
                          value={pov}
                          onChange={(event) => setPov(event.target.value)}
                          className="p-2 rounded-md border border-gray-300 bg-white text-light text-sm"
                        >
                          <option value="">None</option>
                          <option value="First Person Sigular">
                            First Person Sigular (I, me, my, mine)
                          </option>
                          <option value="First Person Plural">
                            First Person Plural (we, us, our, ours)
                          </option>
                          <option value="Second Person">
                            Second Person (you, your, yours)
                          </option>
                          <option value="Third Person">
                            Third Person (he, she, it, they)
                          </option>
                        </select>
                      </div>
                      <button
                        onClick={handleBlogRegenerate}
                        className="p-2 rounded-lg bg-thbBlue text-white w-full mt-6"
                      >
                        Rewrite Blog
                      </button>
                      <div className="flex flex-col justify-start items-stretch gap-2 mt-12">
                        <div className="text-sm flex justify-start items-center gap-1">
                          Slug
                          <InfoOutlinedIcon
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                            onClick={() => setOpenGuide(true)}
                          />
                        </div>
                        <div className="p-2 rounded-md border bg-gray-50 text-light text-sm">
                          {originalBlog.slug}
                        </div>
                      </div>
                      <div className="flex flex-col justify-start items-stretch gap-2 mt-6">
                        <div className="text-sm flex justify-start items-center gap-1">
                          Meta Description
                          <InfoOutlinedIcon
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                            onClick={() => setOpenGuide(true)}
                          />
                        </div>
                        <textarea
                          rows={3}
                          disabled
                          className="p-2 rounded-md border bg-gray-50 text-light text-sm"
                          defaultValue={originalBlog.metaDescription}
                        />
                      </div>
                      <div className="flex flex-col justify-start items-stretch gap-2 mt-6">
                        <div className="text-sm flex justify-start items-center gap-1">
                          Score
                          <InfoOutlinedIcon
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                            onClick={() => setOpenGuide(true)}
                          />
                        </div>
                        <div className="flex flex-col justify-start items-start gap-2 p-4 rounded-md border bg-gray-50 text-light text-sm">
                          <div className="flex justify-between items-center w-full">
                            <div className="font-medium">Plagiarism</div>
                            <div className="font-medium text-green-600">
                              {!originalBlog.plagiarismScore
                                ? "4%"
                                : `${originalBlog.plagiarismScore}%`}
                            </div>
                          </div>
                          <div className="flex justify-between items-center w-full">
                            <div className="font-medium">SEO Score</div>
                            <div className="font-medium text-green-600">
                              {/* {originalBlog.seoScore} */}
                              Good
                            </div>
                          </div>
                          <div className="font-medium">SEO Tip:</div>
                          <div>
                            <div className="text-sm text-orange-600 ">
                              {" "}
                              - Add internal links to this article
                            </div>
                            <div className="text-sm text-orange-600 ">
                              {" "}
                              - Add external links to this article
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <RegenerationSubscribeDialog
                    openRegenerationSubscribeModal={
                      openRegenerationSubscribeModal
                    }
                    setOpenRegenerationSubscribeModal={
                      setOpenRegenerationSubscribeModal
                    }
                    message={messageRegenerationSubscribeModal}
                  />
                </>
              )}
              {currentView === 1 && (
                <div className="flex justify-center items-start w-full relative">
                  <div className="flex flex-col justify-start items-center w-full relative">
                    <div className="flex flex-row justify-end items-start gap-8 w-full relative">
                      <div
                        onClick={() => setCurrentView(0)}
                        className="px-4 py-2 rounded-lg cursor-pointer flex justify-center items-center gap-2 bg-orange-200 text-orange-500 font-semibold"
                      >
                        <CreateOutlinedIcon sx={{ fontSize: "1rem" }} />
                        Edit
                      </div>
                      {/* <div
                        ref={copyDropdownRef}
                        className="inline-block relative"
                      >
                        <button
                          onClick={() => setIsCopyContentOpen((prev) => !prev)}
                          className="rounded-lg p-2 text-xs bg-white-400 text-gray-500 shadow-lg border border-gray-500"
                        >
                          <ContentCopyIcon sx={{ fontSize: "1.2rem" }} />
                        </button>
                        {isCopyContentOpen && (
                          <div className="mt-2 bg-white right-0 left-auto absolute z-10 shadow-lg rounded-md flex flex-col justify-start items-start gap-2 w-40 border border-gray-500">
                            <div
                              onClick={handleCopyHtml}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Copy <HTML>`}</div>
                            <div
                              onClick={handleCopyText}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Copy Text`}</div>
                          </div>
                        )}
                      </div> */}
                      <div
                        ref={exportDropdownRef}
                        className="inline-block relative"
                      >
                        <button
                          onClick={() =>
                            setIsExportContentOpen((prev) => !prev)
                          }
                          className="rounded-lg p-2 text-xs bg-white-400 text-gray-500 shadow-lg border border-gray-500"
                        >
                          <FileDownloadIcon sx={{ fontSize: "1.2rem" }} />
                        </button>
                        {isExportContentOpen && (
                          <div className="mt-2 bg-white right-0 left-auto absolute z-10 shadow-lg rounded-md flex flex-col justify-start items-start gap-2 w-40 border border-gray-500">
                            <div
                              onClick={handleCopyHtml}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Copy <HTML>`}</div>
                            <div
                              onClick={handleCopyText}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Copy Text`}</div>
                            <div
                              onClick={downloadHTMLFile}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Export HTML`}</div>
                            <div
                              onClick={downloadWordFile}
                              className="px-2 py-2 text-sm font-medium hover:bg-gray-100 w-full relative cursor-pointer"
                            >{`Export Word`}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      id="previewBlog"
                      className="flex flex-col justify-between items-start w-full relative md:p-8 gap-40"
                    >
                      <div className="flex justify-center items-start w-full center">
                        <div
                          className="relative w-full md:w-3/5 border rounded-lg border-slate-200 p-6"
                          dangerouslySetInnerHTML={{
                            __html: htmlBlog,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <RegenerateModal />
          <ImageRegenerateComponent
            openImageRegenerateModal={openImageRegenerateModal}
            setOpenImageRegenerateModal={setOpenImageRegenerateModal}
            heroImage={heroImage}
            setHeroImage={setHeroImage}
            setSaveChange={setSaveChange}
            topicId={originalBlog.id}
            setOpenLoadingModal={setOpenLoadingModal}
            quill={imageQuill}
            projectId={projects.currentProject.id}
            setLoadingModalMessage={setLoadingModalMessage}
          />
          <LoadingModal
            openLoadingModal={openLoadingModal}
            setOpenLoadingModal={setOpenLoadingModal}
            message={loadingModalMessage}
          />
          <SaveContentModal
            openSaveContentModal={openSaveContentModal}
            setOpenSaveContentModal={setOpenSaveContentModal}
            isExportAction={isExportAction}
            setIsExportAction={setIsExportAction}
          />
        </div>
      )}
    </>
  );
};

export default TopicWriteBlogPage;
