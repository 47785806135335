// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKuo7eMx9du9wuIX1NaUjpz0EwyTvqMIk",
  authDomain: "hyperbrand-48378.firebaseapp.com",
  projectId: "hyperbrand-48378",
  storageBucket: "hyperbrand-48378.appspot.com",
  messagingSenderId: "484268093628",
  appId: "1:484268093628:web:85facac6040f8201f0a0b9",
  measurementId: "G-P2QXYX7765",
};

// Initialize Firebase
// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig);
console.log("Firebase configured : " + Date());
export const auth = getAuth(app);
