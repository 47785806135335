import { Drawer } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const WriteBlogGuide = ({ openGuide, setOpenGuide }) => {
  return (
    <Drawer
      anchor="right"
      open={openGuide}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: { xs: 200, sm: 300, lg: 420 },
        },
      }}
      onClose={() => setOpenGuide(false)}
    >
      <div className="flex flex-col justify-start items-start relative">
        <div
          className="flex justify-between items-center w-full relative px-6 py-8 bg-blue-50"
          style={{
            backgroundImage: `url("/images/guideBg.svg")`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="text-blue-500 font-semibold text-xl">Guide</div>
          <div
            onClick={() => setOpenGuide(false)}
            className="cursor-pointer rounded-full p-2 bg-gray-100"
          >
            <ClearIcon />
          </div>
        </div>
        <div className="flex flex-col justify-start items-start p-6 gap-2">
          <div className="text-sm">
            This is the Blog page, where you can view the SEO optimised blog
            generated for your topic selected. Here you can also edit manually
            or regenerate certain sections or images using AI. Moreover, you
            also have the features to preview the blog, make promotional
            contents for it and to download or copy it.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Tone</div>
          <div className="text-sm">
            Tone in which the content is written in.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Add Insights</div>
          <div className="text-sm">
            Here you can add any information that you want the content to have
            to give a stylr of yours. It's always better and preferred to add
            any facts or something that you only know to include in your content
            to make it interesting and readable. This is an optional field.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Word Count Range</div>
          <div className="text-sm">
            Select the type of blog you want to generate in length i.e. small,
            medium or large.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Point of View</div>
          <div className="text-sm">
            Point of View in which the content is written in.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Slug</div>
          <div className="text-sm">
            A slug is the part of a URL that identifies a particular page on a
            website in an easy-to-read form. In other words, it&apos;s the part
            of the URL that explains the page&apos;s content. For this article,
            for example, the URL is https://thehyperbrand.com/slug, and the slug
            simply is &apos;slug&apos;.
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Meta Description</div>
          <div className="text-sm">
            A meta description tag is an HTML element that provides a short
            summary of a webpage. It can appear as a snippet below the
            page&apos;s URL and title in Google&apos;s search engine results
            pages (SERPs).
          </div>
        </div>
        <div className="flex flex-col justify-start items-start px-6 py-4 gap-2">
          <div className="text-sm font-medium">Score</div>
          <div className="text-sm">
            Detail about how good your content is and how you can improve it
            more.
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default WriteBlogGuide;
