import { useEffect, useRef, useState } from "react";
import SideBar from "../../../components/SideBar";
import { CircularProgress, Dialog, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { fetchBackendApiWrapper } from "../../../utils/apiWrapper";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "../../../slice/notificationSlice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from "@mui/icons-material/Clear";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import {
  addKeywords,
  setStoreProjectId,
  setStoreKeywords,
  setStoreSelectedKeywords,
} from "../../../slice/keywordSelectionSlice";
import LoadingPageSkeleton from "../../../components/LoadingPageSkeleton";
import { formatDifficulty, formatNumber } from "../../../utils/commonUtils";
import LoadMoreSubscribeDialog from "../../../components/subscribeDialogs/LoadMoreSubscribeDialog";
import { useNavigate } from "react-router-dom";
import { openMessageDialog } from "../../../slice/messageDialogSlice";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import KeywordSelectionGuide from "../../../components/guides/KeywordSelectionGuide";
import ReactGA from "react-ga4";
import { auth } from "../../../auth/firebaseConfig";
import AddKeywordModal from "../../../components/AddKeywordModal";

const KeywordSelection = () => {
  const navigate = useNavigate();
  const keywordSelectionLimit = 5;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [keywordFile, setKeywordFile] = useState(null);
  // const authData = useSelector((state) => state.authStore);
  const projectData = useSelector((state) => state.projects);
  const keywordSelection = useSelector((state) => state.keywordSelection);
  const dispatch = useDispatch();
  const [activateSave, setActivateSave] = useState(false);
  const [loaderUploadKeyword, setLoaderUploadKeyword] = useState(false);
  const fileInputRef = useRef(null);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState(
    keywordSelection.selectedKeywords === undefined
      ? []
      : keywordSelection.selectedKeywords
  );
  const [loaderInit, setLoaderInit] = useState(false);
  const [errorInit, setErrorInit] = useState(false);
  const [loaderMore, setLoaderMore] = useState(false);
  const [loaderUpdateKeyword, setLoaderUpdateKeyword] = useState(false);
  const [loaderRefresh, setLoaderRefresh] = useState(false);
  const [keywordGenInprogess, setKeywordGenInprogess] = useState(false);
  const [openGuide, setOpenGuide] = useState(false);
  const [openLoadMoreSubscribeModal, setOpenLoadMoreSubscribeModal] =
    useState(false);
  const [messageLoadMoreSubscribeModal, setMessageLoadMoreSubscribeModal] =
    useState("Subscribe to load more keywords");
  const [openAddKeywordModal, setOpenAddKeywordModal] = useState(false);

  useEffect(() => {
    ReactGA.event({
      category: "Page Load",
      action: "Keywords - Load",
      location: window.location.pathname,
      label: "Keywords - Load",
    });
    if (
      //TODO: Change for reading kws from redux:  keywordSelection.keywords === undefined &&
      projectData.currentProject !== undefined
    ) {
      setLoaderInit(true);
      fetchSelectedKeywordsApi(10, 0, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSelectedKeywordsApi = async (limit, page, replace) => {
    setErrorInit(false);
    try {
      const kwData = await fetchBackendApiWrapper(
        `/api/v2/projectKeywords?projectId=${projectData.currentProject.id}&limit=${limit}&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        await auth.currentUser.getIdToken(false)
      );
      if (kwData.status === 200) {
        const kwJson = await kwData.json();
        if (replace) {
          dispatch(setStoreKeywords(kwJson.keywords));
        } else {
          dispatch(addKeywords(kwJson.keywords));
        }
        setKeywordGenInprogess(false);
        dispatch(setStoreProjectId(projectData.currentProject.id));
        dispatch(setStoreSelectedKeywords(kwJson.selectedKeywords));
        setSelectedKeywords(kwJson.selectedKeywords);
      } else if (kwData.status === 423) {
        const res = await kwData.json();
        console.error("Keywords fetch limit reached. " + res.message);
        if (res.message.includes("Keywords limit")) {
          setMessageLoadMoreSubscribeModal(
            !projectData.currentProject.isActiveSubscription
              ? "Please subscribe to see more Keywords."
              : "You can only see 30 unique keywords per month. Please wait until the next month to see more Keywords."
          );
        } else if (res.message.includes("Topic limit")) {
          setMessageLoadMoreSubscribeModal(
            !projectData.currentProject.isActiveSubscription
              ? "Please subscribe to see more Topics."
              : "You can only see 60 unique topics per month. Please wait until the next month to see more Topics."
          );
        }
        setOpenLoadMoreSubscribeModal(true);
      } else if (kwData.status === 204) {
        setKeywordGenInprogess(true);
      } else {
        throw Error();
      }
    } catch (err) {
      console.error("Error in fetching keywords : " + JSON.stringify(err));
      // setErrorInit(true);
    }
    setLoaderInit(false);
    setLoaderMore(false);
    setLoaderRefresh(false);
  };

  const handleRefreshKeywordList = async () => {
    ReactGA.event({
      category: "Click",
      action: "Keywords - Refresh",
      label: "User clicks on refresh",
    });
    if (loaderRefresh) return;
    setActivateSave(false);
    setLoaderRefresh(true);
    fetchSelectedKeywordsApi(10, 0, true);
  };

  const handleUpdateSelectedKeywords = async () => {
    if (loaderRefresh) return;
    setLoaderUpdateKeyword(true);
    try {
      const payload = {
        projectId: projectData.currentProject.id,
        selectedKeywords: selectedKeywords,
      };
      const uploadFileRes = await fetchBackendApiWrapper(
        "/api/v2/activateKeyword",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        await auth.currentUser.getIdToken(false)
      );
      if (uploadFileRes.ok) {
        dispatch(setStoreSelectedKeywords(selectedKeywords));
        dispatch(
          openNotification({
            message: "Keywords updated successfully",
            severity: "success",
          })
        );
        const msg = {
          msg: `Keywords updated successfully. Your new topics will be generated in 1-2 min. You can see them by refreshing the topics on Blogs page after 1-2 min.`,
          source: null,
          open: true,
        };
        dispatch(openMessageDialog(msg));
        console.log("Updated kws");
      }
    } catch (err) {
      console.error("Error in updating keywords : " + JSON.stringify(err));
      dispatch(
        openNotification({
          message: "Error in updating Keywords",
          severity: "error",
        })
      );
    }
    setLoaderUpdateKeyword(false);
    setActivateSave(false);
  };

  // START: Remove Keyword Feature //
  const [removeKeyword, setRemoveKeyword] = useState("");
  const [loaderRemove, setLoaderRemove] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const DeleteDialog = () => {
    const handleDeleteTopic = async () => {
      if (loaderRemove) return;
      setLoaderRemove(true);
      try {
        const saveData = await fetchBackendApiWrapper(
          `/api/v2/removeKeyword?keywordId=${removeKeyword}&projectId=${projectData.currentProject.id}`,
          {
            method: "PUT",
          },
          await auth.currentUser.getIdToken(false)
        );
        if (saveData.ok) {
          fetchSelectedKeywordsApi(keywordSelection.keywords.length, 0, true);
          dispatch(
            openNotification({
              message: "Keyword Removed successfully",
              severity: "success",
            })
          );
        }
      } catch (err) {
        console.error("Error in removing keyword: " + JSON.stringify(err));
        dispatch(
          openNotification({
            message: "Error in removing keyword",
            severity: "error",
          })
        );
      }
      setLoaderRemove(false);
      setOpenDeleteModal(false);
    };

    const handleDeleteModalClose = () => {
      if (loaderRemove) return;
      setOpenDeleteModal(false);
    };

    return (
      <Dialog
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        className="backdrop-blur-sm"
      >
        <div className="flex flex-col justify-start items-start w-full relative rounded-lg p-6 gap-6">
          <div className="flex justify-between items-center w-full relative border-b pb-4 border-gray-100">
            <div className="font-medium text-lg">Remove Keyword</div>
            <div
              onClick={handleDeleteModalClose}
              className="cursor-pointer rounded-full p-2 bg-gray-100"
            >
              <ClearIcon />
            </div>
          </div>
          <div className="font-medium text-center w-full relative">
            This action cannot be undone, it will permanently remove the
            keyword?
          </div>
          <div className="flex justify-end items-center w-full relative gap-4">
            <button
              onClick={handleDeleteModalClose}
              className="px-4 py-2 font-medium border border-black hover:bg-black hover:text-white  rounded-md text-sm"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDeleteTopic()}
              className="px-4 py-2 font-medium bg-red-500 hover:bg-red-700 text-white text-sm rounded-md"
            >
              Remove Keyword
            </button>
          </div>
        </div>
      </Dialog>
    );
  };
  // END: Remove Keyword Feature //

  const handleLoadMoreKeyword = () => {
    if (loaderMore) return;
    ReactGA.event({
      category: "Click",
      action: "Keywords - Load More",
      label: "User clicks on Load more keywords",
    });
    setLoaderMore(true);
    fetchSelectedKeywordsApi(10, keywordSelection.keywords.length / 10, false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  ///START::  File Upload ///
  const clearFileInput = () => {
    fileInputRef.current.value = "";
  };
  const handleSelectFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      setKeywordFile(event.target.files[0]);
    }
  };
  const UploadDialog = () => {
    return (
      <Dialog
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
        fullWidth
        className="backdrop-blur-sm"
      >
        <div className="p-10 rounded-lg flex flex-col justify-start items-center gap-6">
          <div className="font-light text-center">
            Keywords are uploaded successfully. Topics for keywords will be
            updated in few minutes, which you can view in Topic Selection page.
          </div>
          <button
            onClick={() => setOpenUploadDialog(false)}
            className="bg-blue-500 text-white p-2 rounded-md text-sm"
          >
            OK
          </button>
        </div>
      </Dialog>
    );
  };
  /// END:: File Upload ///

  const handleToggleKeyword = (event) => {
    if (!event.target.checked) {
      const tmp = selectedKeywords.filter((k) => k !== event.target.value);
      setSelectedKeywords(tmp);
      setActivateSave(true);
    } else if (selectedKeywords.length >= keywordSelectionLimit) {
      dispatch(
        openNotification({
          message: `You can only select a maximum of ${keywordSelectionLimit} keywords at a time. Please remove some other keyword first.`,
          severity: "error",
        })
      );
    } else {
      setSelectedKeywords([...selectedKeywords, event.target.value]);
      setActivateSave(true);
    }
  };

  const TableRow = ({ row, ind }) => {
    const [open, setOpen] = useState(false);
    const primary = row.keywords[0];
    const secondary = row.keywords.slice(1);
    return (
      <>
        <tr className="border-t border-gray-200">
          <td className="text-sm font-medium pl-4">{ind + 1}</td>
          <td className="py-4">
            <div className="inline-block px-2 py-2 bg-blue-50 rounded-xl text-black text-sm">
              {primary}
            </div>
          </td>
          <td>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer text-sm"
                checked={selectedKeywords.includes(row.keywordId)}
                onChange={handleToggleKeyword}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </td>
          {/* <td>
            <div className="flex justify-start items-center gap-1">
              {row.intent.length > 0 &&
                row.intent.split(",").map((it, ind) => {
                  return (
                    <div
                      key={ind}
                      className="text-sm px-2 py-1 rounded-md bg-sky-50 text-center font-medium"
                    >
                      {it.trim().substring(0, 1).toUpperCase()}
                    </div>
                  );
                })}
            </div>
          </td> */}
          {/* <td className="text-sm font-medium">
            {formatNumber(row.totalVolume)}
          </td>
          <td>
            <div className="flex justify-start items-center text-sm font-medium">
              {formatDifficulty(row.difficulty)}
            </div>
          </td> */}
          <td>
            <div className="flex justify-end items-center gap-2 pr-4">
              <div
                onClick={() => setOpen(!open)}
                className="text-sm text-gray-500 cursor-pointer"
              >
                Secondary Keywords{" "}
                {open ? (
                  <KeyboardArrowUpOutlinedIcon sx={{ color: "gray" }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ color: "gray" }} />
                )}
              </div>
              <DeleteTwoToneIcon
                onClick={() => {
                  setRemoveKeyword(row.keywordId);
                  setOpenDeleteModal(true);
                }}
                sx={{ color: "red", cursor: "pointer", fontSize: "1.6rem" }}
              />
            </div>
          </td>
        </tr>
        {open && (
          <>
            <tr>
              <td></td>
              <td className="py-1">
                <div className="flex justify-start items-center gap-2">
                  <div className="inline-block px-2 py-2 text-sm border bg-blue-300 rounded-xl text-black">
                    {primary}
                  </div>
                  {secondary.map((skw, sind) => {
                    return (
                      <div
                        key={sind}
                        className="inline-block px-2 py-2 text-sm border border-blue-300 rounded-xl text-black"
                      >
                        {skw}
                      </div>
                    );
                  })}
                </div>
              </td>
              {/* <td></td> */}
              {/* <td>
                <div className="flex justify-start items-center gap-1">
                  {row.intent.split(",").map((it, ind) => {
                    return (
                      <div
                        key={ind}
                        className="text-sm px-2 py-1 rounded-md bg-sky-50 text-center font-medium"
                      >
                        {it.trim().substring(0, 1).toUpperCase()}
                      </div>
                    );
                  })}
                </div>
              </td> */}
              {/* <td className="text-sm font-medium">
                {formatNumber(row.volume)}
              </td>
              <td>
                <div className="flex justify-start items-center text-sm font-medium">
                  {formatDifficulty(row.difficulty)}
                </div>
              </td>
              <td></td> */}
            </tr>
          </>
        )}
      </>
    );
  };

  const handleUploadKeywordFile = async () => {
    if (loaderUploadKeyword) return;
    console.log(keywordFile);
    console.log(projectData);
    setLoaderUploadKeyword(true);
    try {
      const formData = new FormData();
      formData.append("file", keywordFile);
      const uploadFileRes = await fetchBackendApiWrapper(
        `/api/v1/uploadKeyword?projectId=${projectData.currentProject.id}`,
        {
          method: "POST",
          headers: {},
          body: formData,
        },
        await auth.currentUser.getIdToken(false)
      );
      if (uploadFileRes.ok) {
        setOpenUploadDialog(true);
        clearFileInput();
      }
    } catch (err) {
      console.error("Error in uploading keywords : " + JSON.stringify(err));
    }
    setLoaderUploadKeyword(false);
  };

  return (
    <div
      className="flex justify-start items-start min-h-screen w-full"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <KeywordSelectionGuide
        openGuide={openGuide}
        setOpenGuide={setOpenGuide}
      />
      <div className="py-8 px-4 flex flex-col justify-start items-center w-full sm:ml-64 gap-6">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-4 w-full">
            <div className="sm:hidden">
              <IconButton sx={{ color: "gray" }} onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className="flex flex-col justify-start items-start">
              <div className="text-lg font-semibold">Keywords Selection</div>
              {keywordSelection.keywords &&
                keywordSelection.keywords.length > 0 && (
                  <>
                    <div className="text-xs text-gray-500">
                      Over 4000+ keywords were analyzed before showing you the
                      most optimized list for your business reach
                    </div>
                    {/* <div className="text-xs text-gray-500 mt-4">Tip: If you don&apos;t see updated content, kindly refresh this page</div> */}
                  </>
                )}
            </div>
          </div>
          <div className="flex justify-end w-1/4">
            <div
              onClick={() => setOpenGuide(true)}
              className="rounded-lg bg-white shadow-md p-2 text-gray-500 cursor-pointer"
            >
              <HelpOutlineRoundedIcon />
            </div>
          </div>
          <div className="flex justify-end items-center gap-4">
            {
              // <div>
              //   <input
              //     type={"file"}
              //     accept={".csv"}
              //     onChange={handleSelectFile}
              //     ref={fileInputRef}
              //     className=" cursor-pointer file:bg-black file:text-white file:p-2 file:rounded-md file:border-0 text-sm"
              //   />
              //   {keywordFile && (
              //     <button
              //       onClick={handleUploadKeywordFile}
              //       className="font-semibold text-sm bg-blue-500 text-white rounded-md p-2"
              //     >
              //       {loaderUploadKeyword ? (
              //         <CircularProgress
              //           size="1rem"
              //           variant="indeterminate"
              //           sx={{ color: "white" }}
              //         />
              //       ) : (
              //         "Submit"
              //       )}
              //     </button>
              //   )}
              // </div>
            }
          </div>
          <UploadDialog />
        </div>
        <div className="flex flex-col rounded-lg bg-white p-6 justify-start items-start w-full relative shadow-lg gap-16">
          <AddKeywordModal
            openAddKeywordModal={openAddKeywordModal}
            setOpenAddKeywordModal={setOpenAddKeywordModal}
            projectId={projectData.currentProject.id}
          />
          {errorInit && (
            <div className="flex flex-col justify-start items-center relative w-full gap-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
              <div className="flex relative flex-col justify-start items-center py-8 shadow-lg rounded-lg bg-white px-10 md:px-20">
                <div className="font-medium">
                  Something went wrong please try again
                </div>
              </div>
            </div>
          )}
          {loaderInit && !errorInit && (
            <div className="pt-6 flex justify-center items-center">
              <LoadingPageSkeleton />
            </div>
          )}
          {!loaderInit &&
            !errorInit &&
            keywordSelection.keywords &&
            keywordSelection.keywords.length > 0 && (
              <div className="flex flex-col justify-start items-start relative w-full mt-4">
                <div className="flex justify-between items-start w-full relative">
                  <div className="flex flex-col justify-start items-start gap-2">
                    <div className="text-sm text-gray-700 font-medium">
                      Get the best keyword recommendations for your website to
                      rank on top
                    </div>
                    <div className="text-xs text-gray-700">
                      Tip: Please fill all the details in Business Setup
                      properly to see the better keywords. If you see less
                      keywords then add new keywords from Business Setup page.
                    </div>
                    {/* <input
                      type="text"
                      placeholder="Search keyword"
                      className="p-2 rounded-md text-sm border"
                    /> */}
                  </div>
                  <div className="flex justify-end items-start gap-4">
                    <button
                      onClick={handleRefreshKeywordList}
                      className="rounded-md p-2 bg-black text-white text-sm font-medium"
                    >
                      {loaderRefresh ? (
                        <CircularProgress
                          size="1rem"
                          variant="indeterminate"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <CachedIcon />
                      )}
                    </button>
                    <button
                      onClick={handleUpdateSelectedKeywords}
                      className={`rounded-md px-4 py-2 ${
                        activateSave ? "bg-thbBlue" : "bg-gray-300"
                      } text-white font-semibold`}
                    >
                      {loaderUpdateKeyword ? (
                        <CircularProgress
                          size="1rem"
                          variant="indeterminate"
                          sx={{ color: "white" }}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => setOpenAddKeywordModal(true)}
                  className="rounded-md p-2 mt-4 bg-black text-white text-sm font-medium"
                >
                  Add Keyword
                </button>
                <div className="overflow-x-auto w-full h-[60vh]">
                  <table className="mt-10 table-auto relative w-full">
                    <thead>
                      <tr>
                        <th>
                          <div className="text-sm font-medium pb-6 pl-4 flex justify-start items-center">
                            No.
                          </div>
                        </th>
                        <th>
                          <div className="text-sm font-medium pb-6 flex justify-start items-center gap-1">
                            Keyword
                            <InfoOutlinedIcon
                              sx={{ fontSize: "1rem", cursor: "pointer" }}
                              onClick={() => setOpenGuide(true)}
                            />
                          </div>
                        </th>
                        <th>
                          <div className="text-sm font-medium pb-6 flex justify-start items-center gap-1">
                            Status
                            <InfoOutlinedIcon
                              sx={{ fontSize: "1rem", cursor: "pointer" }}
                              onClick={() => setOpenGuide(true)}
                            />
                          </div>
                        </th>
                        {/* <th>
                          <div className="text-sm font-medium pb-6 flex justify-start items-center gap-1">
                            <div>Intent</div>
                            <InfoOutlinedIcon
                              sx={{ fontSize: "1rem", cursor: "pointer" }}
                              onClick={() => setOpenGuide(true)}
                            />
                          </div>
                        </th> */}
                        {/* <th>
                          <div className="text-sm font-medium pb-6 flex justify-start items-center gap-1">
                            <div>Volume</div>
                            <InfoOutlinedIcon
                              sx={{ fontSize: "1rem", cursor: "pointer" }}
                              onClick={() => setOpenGuide(true)}
                            />
                          </div>
                        </th> */}
                        {/* <th>
                          <div className="text-sm font-medium pb-6 flex justify-start items-center gap-1">
                            <div>Difficult</div>
                            <InfoOutlinedIcon
                              sx={{ fontSize: "1rem", cursor: "pointer" }}
                              onClick={() => setOpenGuide(true)}
                            />
                          </div>
                        </th> */}
                        <th className="text-sm font-medium pb-6"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {keywordSelection.keywords.map((row, ind) => (
                        <TableRow key={ind} row={row} ind={ind} />
                      ))}
                    </tbody>
                  </table>
                </div>
                <LoadMoreSubscribeDialog
                  openLoadMoreSubscribeModal={openLoadMoreSubscribeModal}
                  setOpenLoadMoreSubscribeModal={setOpenLoadMoreSubscribeModal}
                  message={messageLoadMoreSubscribeModal}
                />
                <DeleteDialog />
                {keywordSelection.keywords.length % 10 === 0 && (
                  <div className="flex justify-center w-full relative">
                    <button
                      onClick={handleLoadMoreKeyword}
                      className="rounded-md border border-blue-500 text-blue-500 text-sm px-4 py-2"
                    >
                      {loaderMore ? (
                        <CircularProgress
                          size="1rem"
                          variant="indeterminate"
                          sx={{ color: "blue" }}
                        />
                      ) : (
                        "Load more keywords"
                      )}
                    </button>
                  </div>
                )}
              </div>
            )}
          {!loaderInit &&
            !errorInit &&
            keywordSelection.keywords &&
            keywordSelection.keywords.length === 0 && (
              <div className="flex flex-col justify-center items-center gap-10 w-full relative">
                <div className="pt-6 text-slate-500 text-sm flex justify-center items-center">
                  You have not added any keywords. Please add keywords.
                </div>
                <button
                  onClick={() => setOpenAddKeywordModal(true)}
                  className="rounded-md p-2 bg-black text-white text-sm font-medium"
                >
                  Add Keyword
                </button>
                <button
                  onClick={handleRefreshKeywordList}
                  className="rounded-md p-2 bg-black text-white text-sm font-medium"
                >
                  {loaderRefresh ? (
                    <CircularProgress
                      size="1rem"
                      variant="indeterminate"
                      sx={{ color: "white" }}
                    />
                  ) : (
                    <CachedIcon />
                  )}
                </button>
              </div>
            )}
          {!loaderInit && !errorInit && keywordGenInprogess && (
            <div className="flex flex-col justify-center items-center gap-10 w-full relative">
              <div className="pt-6 text-slate-500 text-sm flex justify-center items-center">
                Please wait while keywords are being generated for you.
              </div>
              <div className="pt-6 text-slate-500 text-sm flex justify-center items-center">
                Meanwhile, you can add details business at Business Detail page
                to get the best keywords and topics for you.
              </div>
              <button
                onClick={() => navigate("/dashboard/getting-started")}
                className="rounded-md p-2 bg-black text-white text-sm font-medium"
              >
                Complete business details
              </button>
              <button
                onClick={handleRefreshKeywordList}
                className="rounded-md p-2 bg-black text-white text-sm font-medium"
              >
                {loaderRefresh ? (
                  <CircularProgress
                    size="1rem"
                    variant="indeterminate"
                    sx={{ color: "white" }}
                  />
                ) : (
                  <CachedIcon />
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeywordSelection;
