/* eslint-disable react-hooks/exhaustive-deps */
import {
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { fetchBackendApiWrapper } from "../../utils/apiWrapper";
import { openNotification } from "../../slice/notificationSlice";
import { auth } from "../../auth/firebaseConfig";
import LoadingPageSkeleton from "../../components/LoadingPageSkeleton";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [forgotEmailId, setForgotEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [loginView, setLoginView] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    checkUserVerified();
  }, []);

  const checkUserVerified = () => {
    if (auth.currentUser) {
      navigate("/dashboard/keyword-selection", { replace: "true" });
    }
  };

  const showLoginSuccessSnackbar = () => {
    dispatch(
      openNotification({
        message: "Successfully Logged In",
        severity: "success",
      })
    );
  };

  const handleGoogleLogin = async () => {
    if (auth.currentUser) {
      handleUserAlreadyLoggedIn();
    } else {
      console.log("No signed in user found. So going for signin.");
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // TODO: Remove
          // console.log("Login success : " + JSON.stringify(result));
          try {
            const payload = {
              emailId: user.email,
              name: user.displayName,
              photoUrl: user.photoURL,
            };
            const saveData = await fetchBackendApiWrapper(
              "/api/v1/login",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              },
              null
            );
            if (saveData.ok) {
              saveData.json().then((res) => {
                console.log("Signin Success. : " + res.emailId);
                navigate("/dashboard/keyword-selection", {
                  replace: "true",
                  state: { email: res.email },
                });
                showLoginSuccessSnackbar();
              });
            }
          } catch (err) {
            console.error("Error in saving user : " + JSON.stringify(err));
            await signOut(auth);
            throw err;
          }
        })
        .catch((error) => {
          let message = "Login Failed. Please try again.";
          if (error !== null && error !== undefined && error.code) {
            if (
              error.code === "auth/account-exists-with-different-credential"
            ) {
              message =
                "Login Failed. Account exists with different credentials";
            }
          }
          console.error("Login error : " + JSON.stringify(error));
          dispatch(
            openNotification({
              message: message,
              severity: "error",
            })
          );
        });
    }

    function handleUserAlreadyLoggedIn() {
      console.log("Already Signed in user found. So going for Login.");
      if (auth.currentUser) {
        console.log("Login Success.");
        navigate("/dashboard/keyword-selection", { replace: "true" });
        showLoginSuccessSnackbar();
      }
    }
  };

  const handleEmailLogin = async () => {
    if (processing) return;
    setProcessing(true);
    await signOut(auth);
    signInWithEmailAndPassword(auth, emailId, password)
      .then(async (credential) => {
        // const user = credential.user;
        // TODO: Remove
        // console.log("Login success : " + JSON.stringify(credential));
        navigate("/dashboard/keyword-selection", { replace: "true" });
        showLoginSuccessSnackbar();
      })
      .catch((error) => {
        const errorCode = error.code;
        console.error("Login error : " + JSON.stringify(error));
        let message = "Unable to Login. Please try again.";
        if (errorCode === "auth/invalid-email") {
          message = "Please enter a valid email";
        }
        if (errorCode === "auth/invalid-login-credentials") {
          message = "Email or Password Incorrect";
        }
        dispatch(
          openNotification({
            message: message,
            severity: "error",
          })
        );
      });
    setProcessing(false);
  };

  const checkEmailAlreadyRegisteredApi = async () => {
    try {
      const checkData = await fetchBackendApiWrapper(
        "/api/v1/emailRegistered?emailId=" + forgotEmailId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        null
      );
      if (checkData.ok) {
        const checkJson = await checkData.json();
        return JSON.parse(checkJson.value);
      }
    } catch (err) {
      console.error(
        "Error in checkEmailAlreadyRegisteredApi : " + JSON.stringify(err)
      );
    }
    return false;
  };

  const handleForgotPassword = () => {
    if (processing || !forgotEmailId || forgotEmailId.length === 0) return;
    setProcessing(true);
    checkEmailAlreadyRegisteredApi()
      .then((res) => {
        if (!res) {
          dispatch(
            openNotification({
              message: "Email is not registered",
              severity: "error",
            })
          );
          return;
        } else {
          sendPasswordResetEmail(auth, forgotEmailId)
            .then(() => {
              console.log("Password reset email sent");
              dispatch(
                openNotification({
                  message: "Password Reset mail sent",
                  severity: "success",
                })
              );
              return;
            })
            .catch((error) => {
              console.error(
                "Error in sending password reset mail " + JSON.stringify(error)
              );
              const errorCode = error.code;
              console.error("Login error : " + JSON.stringify(error));
              let message =
                "Unable to send password reset mail. Please try again.";
              if (errorCode === "auth/invalid-email") {
                message = "Please enter a valid email";
              }
              dispatch(
                openNotification({
                  message: message,
                  severity: "error",
                })
              );
            });
        }
      })
      .then(() => setProcessing(false));
  };

  return (
    <main className="bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100 to-80% to-white">
      {loading && (
        <div className="flex w-full justify-center mt-12 items-center">
          <LoadingPageSkeleton />
        </div>
      )}
      {!loading && (
        <div className="flex w-full relative flex-row justify-center items-center">
          {loginView && (
            <div className="flex flex-col justify-center items-center w-full relative gap-10">
              <div className="mt-8">
                <a href="https://thehyperbrand.com/">
                  <img
                    src="/images/THB.svg"
                    alt="TheHyperbrand Logo"
                    width={140}
                    height={32}
                  />
                </a>
              </div>
              <div className="flex relative flex-col justify-start items-center py-6 shadow-lg rounded-lg bg-white px-10 md:px-20">
                <h1 className="font-montserrat text-xl text-gray-700 font-semibold">
                  Login
                </h1>
                <h2 className="mt-4 font-montserrat text-lg text-gray-400 font-light text-center">
                  To create amazing content!
                </h2>
                <div className="mt-8"></div>
                <button
                  onClick={handleGoogleLogin}
                  className="flex gap-4 justify-center items-center py-3 px-2 md:px-8 rounded-lg border-2 border-slate-200 hover:border-slate-300 hover:shadow-xl"
                >
                  <img
                    src="/images/google.webp"
                    height={24}
                    width={24}
                    alt="Sign in with google"
                  />
                  <div className="font-montserrat">Continue With Google</div>
                </button>
                <div className="my-8 relative flex justify-around items-center gap-4 w-full">
                  <hr className="bg-gray-200 w-full" />
                  <div className="text-slate-300">OR</div>
                  <hr className="bg-gray-200 w-full" />
                </div>
                <div className="mb-4 relative w-full">
                  <input
                    type="text"
                    placeholder="Enter your email address"
                    className="font-montserrat bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={emailId}
                    onChange={(event) => setEmailId(event.target.value)}
                  />
                </div>
                <div className="my-2 relative w-full">
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="font-montserrat bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <div className="my-4 relative w-full">
                  <button
                    onClick={handleEmailLogin}
                    className="font-montserrat bg-thbBlue relative justify-center items-center py-2 px-4 font-medium flex w-full rounded-lg text-white hover:bg-blue-600"
                  >
                    {processing ? (
                      <CircularProgress
                        size="1rem"
                        variant="indeterminate"
                        sx={{ color: "white" }}
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
                <div className="flex relative justify-center items-center gap-2">
                  <div className="font-montserrat text-black text-sm md:text-base">
                    Don&apos;t have an account?
                  </div>
                  <Link to={"/signup"}>
                    <div className="font-montserrat text-thbBlue font-semibold cursor-pointer">
                      Register
                    </div>
                  </Link>
                </div>
                <div className="my-2 flex relative justify-center items-center gap-2">
                  <div
                    onClick={() => setLoginView(false)}
                    className="font-montserrat text-thbBlue font-semibold cursor-pointer"
                  >
                    Forgot Password?
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loginView && (
            <div className="flex relative flex-col justify-start items-center w-11/12">
              <div className="mt-8">
                <a href="https://thehyperbrand.com/">
                  <img
                    src="/images/THB.svg"
                    alt="TheHyperbrand Logo"
                    width={140}
                    height={32}
                  />
                </a>
              </div>
              <h1 className="mt-8 font-montserrat text-2xl text-gray-700 font-semibold text-center">
                Reset Your Password
              </h1>
              <h2 className="mt-4 font-montserrat md:text-xl text-gray-400 font-light text-center">
                Forgot your password? no problem reset it in two steps.
              </h2>
              <div className="mt-8"></div>
              <div className="my-4 relative w-10/12 md:w-1/2 lg:w-1/4">
                <input
                  type="text"
                  placeholder="Enter your email address"
                  className="font-montserrat bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={forgotEmailId}
                  onChange={(event) => setForgotEmailId(event.target.value)}
                />
              </div>
              <div className="my-4 relative w-10/12 md:w-1/2 lg:w-1/4">
                <button
                  onClick={handleForgotPassword}
                  className="font-montserrat bg-thbBlue relative justify-center items-center py-2 flex w-full rounded-lg text-white hover:bg-blue-600"
                >
                  {processing ? (
                    <CircularProgress
                      size="1rem"
                      variant="indeterminate"
                      sx={{ color: "white" }}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
              <div className="flex relative w-10/12 md:w-1/2 lg:w-1/4 justify-center items-center gap-2">
                <div className="font-montserrat text-black">
                  Already have a account?
                </div>
                <div
                  onClick={() => setLoginView(true)}
                  className="font-montserrat text-thbBlue font-semibold cursor-pointer"
                >
                  Login
                </div>
              </div>
              <div className="my-4 flex relative w-10/12 md:w-1/2 lg:w-1/4 justify-center items-center gap-2">
                <div className="font-montserrat text-black">
                  Don&apos;t have an account?
                </div>
                <Link to={"/signup"}>
                  <div className="font-montserrat text-thbBlue font-semibold cursor-pointer">
                    Register
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default LoginPage;
